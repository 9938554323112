import React from 'react';
import Head from 'next/head';
import Script from 'next/script';
import * as gtag from './gtag';
import mixpanel from 'mixpanel-browser';
import { trackFBEvent } from './fbq';
import { ACTIONS } from './actions';
import { getIpAddress } from '../shared/utility';
import { logBrazeEvent } from './braze/braze-service';
import { BrowserLogger } from './browser-logger-v2';
//import { calculateSRIHash } from '../shared/utility';

let userInfo = null;

export { ACTIONS };

/* export const datadogScript = () => (
    <>
        <script type="text/javascript" src="/resources/datadog-rum-us.js" />
        <script
            type="text/javascript"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
                __html: `((window)=>{
                    var loadingDataDog = setInterval(() => {
                      window.DD_RUM && window.DD_RUM.init({ clientToken: "${process.env.DATADOG_CLIENT_TOKEN}", applicationId: "${process.env.DATADOG_APPLICATION_ID}" })
                      if (window.DD_RUM) clearInterval(loadingDataDog);
                    }, 600);
                })(window);`,
            }}
        />
    </>
)*/

export const gtmScript = (gtmId) => (
    <>
        {/* <Script src={`https://www.googletagmanager.com/gtag/js?id=${gtmId}`} />
        <script type="text/javascript" dangerouslySetInnerHTML={{
            __html: `function gtag(){dataLayer.push(arguments)}window.dataLayer=window.dataLayer||[],gtag("js",new Date),gtag("config","${gtmId}",{page_path:window.location.pathname});`,
        }}
        />
        <script type="text/javascript" dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${gtmId}');`
        }} /> */}
        <noscript>
            <iframe
                src={`https://www.googletagmanager.com/ns.html?id=${gtmId}`}
                height="0"
                width="0"
                style={{ display: "none", visibility: "hidden" }}
            ></iframe>
        </noscript>
    </>
)

// export const gtagScript = async (gtmId) => {
//     const gtagLink = `https://www.googletagmanager.com/gtag/js?id=${gtmId}`;
//     //const sriHash = await calculateSRIHash(gtagLink);
//     const gtagScript = document.createElement('script');
//     gtagScript.src = gtagLink;
//     gtagScript.async = true;
//     //gtagScript.integrity = sriHash;

//     //gtagScript.crossOrigin = "anonymous";
//     document.head.appendChild(gtagScript);

//     const inlineGtagScript = document.createElement('script');
//     inlineGtagScript.innerHTML = `function gtag(){dataLayer.push(arguments)}window.dataLayer=window.dataLayer||[],gtag("js",new Date),gtag("config","${gtmId}",{page_path:window.location.pathname});`;
//     document.head.appendChild(inlineGtagScript);

//     const gtmScript = document.createElement('script');
//     gtmScript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${gtmId}');`;
//     document.head.appendChild(gtmScript);
// }

export const mixpanelScript = (id) => {
    if (typeof window !== 'undefined') {
        const mixpanelOptions = {};
        if (process.env.RB_MIXPANEL_PROXY_SERVICE_DNS) mixpanelOptions.api_host = `https://${process.env.RB_MIXPANEL_PROXY_SERVICE_DNS}`;
        mixpanel.init(id, mixpanelOptions);
    }
}

export const gAdsConversion = (gadsId) => (
    <>
        <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${gadsId}`}
        // crossOrigin='anonymous'
        // integrity='sha384-IVeQ7am2iKdgLJilNFWlOqMGypOw22RJyz19W3Go3I9t1hxi0jR9qf3sbXiAD+KT'
        />
        <script dangerouslySetInnerHTML={{
            __html: `function gtag(){dataLayer.push(arguments)}window.dataLayer=window.dataLayer||[],gtag("js",new Date),gtag("config","${gadsId}",{page_path:window.location.pathname})`,
        }} />
    </>
)

export const GAdsScript = () => (<Head>{gAdsConversion('AW-944151606')}</Head>);

export const crispScript = () => (
    <Script
        id='rb-crisp-script'
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
            __html: `(a=>{a.$crisp=[],a.CRISP_WEBSITE_ID="${process.env.CRISP_WEBSITE_ID}",(s=(d=document).createElement("script")).src="https://client.crisp.chat/l.js",s.async=1,d.getElementsByTagName("head")[0].appendChild(s)})(window)`,
        }}
    />
)

export const trackConversionSignupGads = (eventId) =>
    new Promise(resolve => {
        if (window && window.gtag) {
            window.gtag('event', 'conversion', {
                'x-fb-event_id': eventId,
                'send_to': 'AW-944151606/TJMbCMzGiZIBELa4msID',
                'event_callback': resolve
            });
        } else resolve();
    })

export const trackConversionTransactionCreated = async () => {
    if (typeof window !== 'undefined' && window.hj && typeof window.hj === 'function') window.hj('vpv', '/send-money/transaction-created');
}

const tryFn = (fn) => {
    // Prevent tracking from SSR
    if (typeof window === 'undefined') return;

    try {
        fn()
    } catch (error) {
        BrowserLogger.error(error, {
            action_from: 'Analytics',
            function_name: 'tryFn',
        });
    }
}

export const Analytics = {
    identify: (payload) => {
        tryFn(() => {
            if (!userInfo) {
                let { cus_unique_id } = payload;
                if (cus_unique_id) {
                    mixpanel.identify(cus_unique_id);
                    userInfo = { cus_unique_id }
                }
            }
        })
    },
    reset: () => {
        tryFn(() => {
            mixpanel.reset()
        })
    },
    track: (action, payload, meta = {}, addPrefix = false) => {
        // Prevent tracking from SSR
        if (typeof window === 'undefined' || !action) return;

        const isProd = process.env.REACT_APP_ENV === 'prod';

        if (!isProd) {
            const _action = meta.accountType === 'business' ? 'BA_' + action : action;
            console.log('Analytics.track', _action, payload);
        }

        tryFn(() => {
            const eventId = crypto.randomUUID();
            const _action = addPrefix ? 'BA_' + action : action;
            action !== ACTIONS.PAGE_VIEW && logBrazeEvent({ eventName: _action, eventProperties: payload });
            trackFBConversionTrackRequest(action, payload, eventId);

            const _skippedActions = [
                ACTIONS.LOGIN,
            ];

            if (action && action !== ACTIONS.PAGE_VIEW && !_skippedActions.includes(action)) {
                // console.log(`MIXPANEL: ${action}`, payload);
                const _action = meta.accountType === 'business' ? 'BA_' + action : action;
                mixpanel.track(_action, payload);
            }
            // trackFBEvent(action, payload, meta, eventId);

            switch (action) {
                case ACTIONS.PAGE_VIEW:
                    {
                        let { url } = payload;
                        gtag.pageview(url);
                    }
                    break;
                case ACTIONS.LOGIN:
                    if (payload) {
                        let {
                            cus_unique_id,
                            cus_firstname,
                            cus_lastname,
                            cus_phone1,
                            cus_email,
                            cus_dob,
                            cus_preferred_payment_bank,
                        } = payload;

                        mixpanel.identify(cus_unique_id);

                        mixpanel.people.set({
                            "$email": cus_email,
                            "$name": `${cus_firstname} ${cus_lastname}`,
                            cus_unique_id,
                            cus_firstname,
                            cus_lastname,
                            cus_phone1,
                            cus_email,
                            cus_dob,
                            cus_preferred_payment_bank,
                        })
                        mixpanel.track(ACTIONS.LOGIN, payload);
                    }
                    break;
                case ACTIONS.SIGNUP_COMPLETED:
                    if (payload.personalInformation) {
                        let { cus_email, cus_unique_id } = payload.personalInformation;
                        mixpanel.alias(cus_unique_id);

                        mixpanel.people.set({
                            cus_unique_id,
                            "$email": cus_email,
                        });
                    }
                    break;
                case ACTIONS.SIGNUP_PHONE_NUMBER_SUBMITTED:
                    if (payload.personalInformation) {
                        let { cus_phone1 } = payload.personalInformation;

                        mixpanel.people.set({
                            cus_phone1
                        })
                    }
                    trackConversionSignupGads(eventId);
                    break;
                case ACTIONS.SEND_MONEY_TRANSFER_OVERVIEW_CONFIRMED:
                    try {
                        typeof window !== 'undefined' && window?.hj && typeof window.hj === 'function' && window.hj('vpv', '/send-money/transaction-created');
                    } catch (e) { console.log(e) }
                    break;
                default:
            }
        })
    }
}

const fbConversionEventMapping = {
    [ACTIONS.LOGIN]: 'login',
    [ACTIONS.SIGNUP_COMPLETED]: 'signup_completed',
    [ACTIONS.SEND_MONEY_TRANSACTION_COMPLETED]: 'send_money_transaction_created',
    [ACTIONS.CURRENCY_EXCHANGE_TRANSFER_OVERVIEW_CONFIRMED]: 'currency_exchange_transfer_created',
    [ACTIONS.MOBILE_TOP_UP_TRANSACTION_COMPLETED]: 'mobile_top_up_transaction_completed',
    [ACTIONS.GIFT_CARD_TRANSACTION_COMPLETED]: 'gift_card_transaction_completed',
    [ACTIONS.BILL_PAYMENT_TRANSACTION_COMPLETED]: 'bill_payment_transaction_completed',
    [ACTIONS.ESIM_PAYMENT_TRANSACTION_COMPLETED]: 'esim_payment_transaction_completed',
};

export const trackFBConversionTrackRequest = async (action, payload, eventId = null) => {
    try {
        const accessToken = process.env.FB_CONVERSION_ACCESS_TOKEN;
        const pixelId = process.env.FB_ACCOUNT_ID;
        const FB_CONVERSION_API_EVENTS = [
            ACTIONS.SIGNUP_COMPLETED,
            ACTIONS.SEND_MONEY_TRANSACTION_COMPLETED,
            ACTIONS.CURRENCY_EXCHANGE_TRANSFER_OVERVIEW_CONFIRMED,
            ACTIONS.LOGIN,
            ACTIONS.MOBILE_TOP_UP_TRANSACTION_COMPLETED,
            ACTIONS.GIFT_CARD_TRANSACTION_COMPLETED,
            ACTIONS.BILL_PAYMENT_TRANSACTION_COMPLETED,
            ACTIONS.ESIM_PAYMENT_TRANSACTION_COMPLETED,
        ];
        const version = 'v19.0';
        // const isProd = process.env.REACT_APP_ENV === 'prod';
        const isStagingProd = ['prod', 'staging'].includes(process.env.REACT_APP_ENV);

        if (isStagingProd && accessToken && pixelId && window) {
            if (FB_CONVERSION_API_EVENTS.includes(action)) {
                const url = `https://graph.facebook.com/${version}/${pixelId}/events?access_token=${accessToken}`;
                const ip_address = await getIpAddress();
                // const crypto = require('crypto');
                // const email = payload && payload?.personalInformation && payload?.personalInformation?.cus_email || '';
                // const hash = crypto.createHash('sha256');
                const CryptoJS = require('crypto-js');
                const email = payload?.personalInformation?.cus_email || payload?.email || '';
                const hashedEmail = email?.length > 0 ? CryptoJS.SHA256(email).toString(CryptoJS.enc.Hex) : null;
                const _action = fbConversionEventMapping[action] || action;

                const _dataArgs = {
                    "event_id": eventId,
                    "event_name": _action,
                    "event_time": Math.floor(new Date() / 1000),
                    "action_source": "website",
                    "event_source_url": window.location.href,
                    "user_data": {
                        "client_user_agent": navigator.userAgent,
                        "client_ip_address": ip_address || '0.0.0.0',
                        "em": hashedEmail,
                    },
                };

                gtag.event({
                    action: _action,
                    payload: {
                        'x-fb-event_id': eventId,
                        action_source: "website",
                        event_source_url: window.location.href,
                        user_data: {
                            client_user_agent: navigator.userAgent,
                            client_ip_address: ip_address || '0.0.0.0',
                            em: hashedEmail,
                        }
                    }
                });

                await fetch(url, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        "data": [
                            _dataArgs
                        ],
                    })
                })
                    .then(response => response.json())
                    .then(data => console.log(data))
                    .catch(error => console.error('Error:', error));
            }
        }
    } catch (err) {
        BrowserLogger(err, { 
            action_from: 'Analytics', 
            function_name: 'trackFBConversionTrackRequest', 
            action 
        });
    }
}