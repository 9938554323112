import { Grid } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const useStyles = makeStyles((theme) => ({
    grid: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      width: '100%',
      flexWrap: 'nowrap',
      overflowX: 'auto',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        display: 'none'
      },
      '-ms-overflow-style': 'none',
      [theme.breakpoints.down('sm')]: {
        overflowX: 'hidden',
      },
    },
    image: {
      maxWidth: '100%',
      height: 'auto',
      padding: '0 30px',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '160px',
        height: 'auto',
        padding: '0 10px 0px',
      }
    },
}));

const HorizontalImageScroll = ({ list }) => {
    const classes = useStyles();
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    let splitList = [];
    list.map((item, index) => {
        (index % 3 === 0) ? splitList.push([item]) : splitList[splitList.length - 1].push(item);
    });    

    if (!isMobile && list && list.length > 0)
      return (
        <Grid
        display="flex"
        direction="row"
        alignItems="center"
        container
        justifyContent="center"
        spacing={1}
        className={classes.grid}
        >
        {list.map((item) => (
            <img
                src={item.image}
                alt={item.alt || 'image'}
                className={classes.image}
            />
        ))}
        </Grid>
      );
    if (isMobile && list && list.length > 0 && splitList && splitList.length > 0)
      return (
        <Grid className={classes.grid}>
          {splitList.map((item) => (
            <Grid
              display="flex"
              direction="row"
              alignItems="center"
              container
              justifyContent="center"
              spacing={1}
              className={classes.grid}
            >
              {item && item.map((img) => (
                <img
                  src={img.image}
                  alt={img.alt || 'imageAlt'}
                  className={classes.image}
                />
              ))}
            </Grid>
          ))}
        </Grid>
      );
};

HorizontalImageScroll.propTypes = {
    list: PropTypes.array
};

export default HorizontalImageScroll;
