import React, { useEffect, useState } from 'react';
import CloseIcon from '../../icons/CloseIcon';
import classes from './Dialog.module.scss';
import DialogStatusIcon from '../../icons/DialogStatusIcon'
import Button from '../button/Button';
import Typography from '../typography/Typography';
import theme from '../../theme/Theme';
import Box from '../box/Box';
import Spacing from '../../styles/spacing/Spacing';
import { useMediaQuery } from '@mui/material';

enum Variant {
  success = 'success',
  negative = 'negative',
  warning = 'warning',
  info = 'info',
  text = 'text'
}

type DialogProps = {
  id: string;
  children?: string | React.ReactNode;
  header?: string | React.ReactNode;
  text?: string | React.ReactNode;
  text2?: string | React.ReactNode;
  variant?: Variant | string;
  primaryAction?: () => void;
  secondaryAction?: () => void;
  primaryActionText?: string;
  primaryActionVariant?: string;
  primaryActionIcon?: any;
  secondaryActionText?: string;
  secondaryActionVariant?: string;
  secondaryActionIcon?: any;
  open: boolean;
  hideClose?: boolean;
  customStyleIcon?: string;
  iconStyle?: object;
  dialogClass?: string;
  headerClass?: string;
  fullScreen?: boolean;
  textClass?: string;
  onClose?: () => void;
  dialogStyle?: object;
  closeIconVariant?: 'primary' | 'secondary';
  customIcon?: any;
  customIconAnimated?: any
}

const Dialog: React.FC<DialogProps> = ({
  open,
  dialogClass,
  textClass,
  headerClass,
  variant = 'text',
  customStyleIcon,
  customIcon,
  header,
  children,
  text,
  text2,
  iconStyle,
  primaryAction,
  secondaryAction,
  primaryActionText,
  secondaryActionText,
  fullScreen,
  hideClose,
  primaryActionVariant,
  secondaryActionVariant,
  primaryActionIcon,
  secondaryActionIcon,
  onClose,
  dialogStyle,
  closeIconVariant = 'primary',
  customIconAnimated,
  ...rest
}) => {

  if (!open) return null;

  if (!open) return;
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.lg}px)`)
  return (
    <div className={`${classes[`rb-dialog-container`]}`}>
      <div className={`
         ${classes[`rb-modal`]}
         ${fullScreen ? classes[`rb-modal-fullScreen`] : null}
         ${dialogClass ? dialogClass : ''}
         `}
        style={dialogStyle}
        {...rest}
      >
        <div>
          {!hideClose && <span onClick={onClose ? onClose : primaryAction} className={`${classes[`rb-closeIcon`]}`} id='close-dialog'><CloseIcon color={closeIconVariant === 'secondary' ? '#FFFFFF' : '#1160F7'} /></span>}
          <div id='status-icon' className={`${classes[`rb-modal-content-wrapper`]}`}>
            {(!customStyleIcon && variant === 'info') || variant === 'text' ? null : <div className={`${classes[`rb-status-icon`]}`} {...iconStyle}>
              <DialogStatusIcon customStyleIcon={customStyleIcon} variant={variant} />
            </div>}
            {customIcon && (
              <img src={customIcon} className={`${classes[`rb-modal-custom-icon`]}`} />
            )}
            {header && <Box display='flex' justifyContent='center' alignItems='center'><Typography id='header' align='center' className={`${classes[`rb-modal-header`]} ${headerClass ? headerClass : ''}`} variant={isMobile ? 'h3' : 'h2'} color={theme.palette.primary.navy} weight='semibold'>{header}</Typography></Box>}
            {text && <>
              <Spacing variant='betweenCards' />
              <Typography id='text' className={`${classes[`rb-modal-text`]} ${textClass ? textClass : ''}`} variant='body1'>
                <div dangerouslySetInnerHTML={{
                  __html: text
                }} /></Typography>
            </>
            }
            {text2 && <>
              <Spacing variant='betweenCards' />
              <Typography id='text2' className={`${classes[`rb-modal-text`]} ${textClass ? textClass : ''}`} variant='body1'>
                <div dangerouslySetInnerHTML={{
                  __html: text2
                }} /></Typography>
            </>
            }
            {children ? children : null}
            {(primaryAction || secondaryAction) && <>
              {(primaryAction || secondaryAction) && <div className={`${classes[`rb-dialog-button-container`]}`}>
                {(primaryAction && primaryActionText) && (
                  <Button
                    variant={primaryActionVariant}
                    onClick={primaryAction}
                    id="dialog-button-primaryAction"
                    startIcon={primaryActionIcon}
                    fullWidth>
                    {primaryActionText}
                  </Button>
                )}
                {(secondaryAction && secondaryActionText) && (
                  <Button
                    variant={secondaryActionVariant}
                    onClick={secondaryAction}
                    id="dialog-button-secondaryAction"
                    startIcon={secondaryActionIcon}
                    fullWidth>
                    {secondaryActionText}
                  </Button>
                )}
              </div>}
            </>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dialog