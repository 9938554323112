import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import classnames from 'classnames';
import Markdown from 'markdown-to-jsx';
import { useRouter } from 'next/router';
import React from 'react';
import { parseArrFromString } from '../../../shared/utility';
import CustomButton from '../components/CustomButtonsWebsite/CustomButton';
import CustomUnderlineBigHeading from '../components/CustomUnderlinesWebsite/CustomUnderlineBigHeading';
import { bigTitle, body1, container, mediumTitle } from '../globalStyles';

const styles = theme => ({
    container,
    bigTitle,
    mediumTitle,
    body1,
    root: {
        display: 'flex',
        textAlign: 'center',
        alignItems: 'flex-start',
        backgroundColor: '#ffffff !important'
    },
    Title: {
        marginTop: '60px',
        fontWeight: '700',
        color: '#37517A',
        fontSize: '50px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '30px'
        },
    },
    Text: {
        alignText: 'center',
        fontSize: '19px',
        fontWeight: '400',
        [theme.breakpoints.up('sm')]: {
            width: '80%',
            margin: 'auto',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    smallTitle: {
        marginTop: '60px',
        fontWeight: '500',
        color: '#37517A',
        fontSize: '30px',
    },
    pictures: {
        align: 'left',
    },
    textInfo: {
        marginTop: '10px',
        marginBottom: '40px',
        textAlign: 'center',
        fontSize: '35px',
        fontWeight: '400',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '20px',
        },
    },
    Picture: {
        width: '100%',
        height: undefined,
        resizeMode: 'contain',
        //marginTop: '-100px',
        marginBottom: '60px',
        background: 'transparent',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        '@media (max-width: 959px)': {
            marginTop: '0px'
        }
    },
    
    pictureContainer: {
        marginTop: '20px',
        textAlign: 'center',
        justify: 'center',
    },
    Button: {
        align: 'center',
        paddingTop: '10px',
        marginTop: '10px',
        marginLeft: 'auto',
        lineHeight: '1'
    } 
});

const useStyles = makeStyles(styles);

const CurvedImage = (props) => {
    const {
        title,
        text,
        buttonText,
        buttonHref,
        buttonColor,
        image,
        imageProps,
        underline,
        promotion,
        smallTitle
    } = props;
    const classes = useStyles();
    const router = useRouter();
    const imageStyles = parseArrFromString(imageProps)

    const goToUrl = () => {
        let query = null;
        if (promotion) {
            query = addReferralLink()
        }
        router.push({pathname: buttonHref, query});
    }
    
    const addReferralLink = () => {
        let _args = {}
        _args = { promo: promotion };
        return _args;
    }

    return (
        <section className={classes.root}>
            <Grid container className={classes.container}>
                <Grid item xs={12} className={classes.pictures}>
                    <Typography component="div" variant="h2" marked="center" className={classnames(classes.mediumTitle,(smallTitle)?classes.smallTitle: classes.Title)}>
                        {title}
                    </Typography>
                    {underline && <CustomUnderlineBigHeading color="#1160F7" />}
                    <Typography component="div" marked="center" className={classes.body1 + " " + classes.Text} >
                        <Markdown children={text} />
                    </Typography>
                </Grid>

                {buttonText && 
                <Grid item xs={12} className={classes.Button}>
                    <CustomButton className={classes.Button} align="center" type={buttonColor || "blue"} onClick={goToUrl}>
                        {buttonText}
                    </CustomButton>
                </Grid>
                }
                
                <Grid item xs={12} className={classes.pictureContainer}>
                    <img
                        className={classes.Picture}
                        style={imageStyles[0]}
                        src={image}
                        alt={image || 'Curved Image'}
                    />
                </ Grid>
            </Grid>
        </ section>
    );
}

export default CurvedImage;