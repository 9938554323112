import client from "./client";
import { graphqlError } from "../../../../shared/utility";
import { store } from '../../../../redux/store';
import * as Sentry from '@sentry/nextjs';
import { BrowserLogger } from "../../../browser-logger-v2";

export const apiMainMutation = (
    mutation,
    variables
) => {
    return new Promise(resolve => {
        let objToBeReturned = { success: true, errorMessage: null, data: null };
        client
            .mutate({
                mutation,
                variables
            })
            .then(({ data }) => {
                objToBeReturned.data = data;
                resolve(objToBeReturned);
            })
            .catch(err => {
                const { customer } = store && store.getState() || {};
                BrowserLogger.error(err, { action_from: 'apiMainMutation', cus_unique_id: customer?.cus_unique_id });
                Sentry.captureException(err, (scope) => {
                    scope.clear();
                    scope.setContext("additionalData", {
                      cus_unique_id: customer?.cus_unique_id,
                      mutation: mutation && mutation?.definitions && mutation?.definitions?.length > 0 && mutation?.definitions[0]['name'] && mutation?.definitions[0]['name']?.value,
                    });
                    return scope;
                });
                objToBeReturned = { ...objToBeReturned, success: false, errorMessage: graphqlError(err) };
                resolve(objToBeReturned);
            });
    });
}