import React, { useState } from 'react';
import OperatorCard from '../giftCards-v2/OperatorSelection/components/OperatorCard';
import Box from '../../remitbee/components/box/Box';
import Button from '../../remitbee/components/button/Button';
import theme from '../../remitbee/theme/Theme';
import Typography from '../../remitbee/components/typography/Typography';
import Spacing from '../../remitbee/styles/spacing/Spacing';
import Divider from '../../remitbee/components/divider/Divider';
import classes from './MobileTopUp.module.scss'
import { ACTIONS, Analytics } from '../../settings/analytics';

const ChangeCarrier = ({
    t,
    flow,
    selectedOperator,
    setSelectedProduct,
    setSelectedOperator,
    operatorsAvailable,
    onContinue,
    isMobile
}) => {
    const [selected, setSelected] = useState(selectedOperator);

    const handleChange = () => {
        Analytics.track(ACTIONS.MOBILE_TOP_UP_CARRIER_CHANGED, {selectedOperator: selected})
        if (setSelectedOperator) setSelectedOperator(selected);
        if (setSelectedProduct) setSelectedProduct(null);
        if (onContinue) onContinue(selected);
    }

    return (
        <>
           {flow !== 'landing' && <Typography id='change-carrier-title' variant={isMobile ? 'h3' : 'h2'} color={theme.palette.primary.navy} weight="semibold">{t(`${flow}:change_carrier.title`)}</Typography>}
           {isMobile ? <>
             <Spacing variant='titleToDivider' />
             <Divider />
           </> : null}
           {flow !== 'landing' && < Spacing variant='titleToDivider' />}

            <Box>
                <Typography variant='body1'>{t(`${flow}:change_carrier.description`)}</Typography>
                <Spacing variant={isMobile ? 'betweenSectionToInputFields' : 'betweenSections'} />
                <Box className={classes['rb-providers-wrapper']}>
                    {operatorsAvailable && operatorsAvailable.length && operatorsAvailable.map(operator => <OperatorCard
                            key={operator.id}
                            onChange={()=> setSelected(operator)}
                            selected={selected?.id === operator.id}
                            logo={operator.imageUrl}
                            label={operator.label}
                            altImage={'/services/topUpPlaceholder.svg'}
                        />
                    )}
                </Box>
            </Box>
            <Spacing variant='contentToCta' />
            <Button
                id='carrier-cahnge-continue'
                fullWidth
                disabled={!selected}
                onClick={handleChange}
            >
                {t(`${flow}:change_carrier.continue`)}
            </Button>
        </>
    );
};

export default ChangeCarrier;