import React, { useState, useEffect } from 'react';
import Card from '../../components/card/Card';
import classes from './CurrencyExchangeSection.module.scss';
import CurrencyInputField from '../../components/moneyTransferBox/CurrencyInputField';
import Box from '../../components/box/Box';
import Button from '../../components/button/Button';
import Spacing from '../../styles/spacing/Spacing';
import Typography from '../../components/typography/Typography';
import theme from '../../theme/Theme';
import Swap from '../../icons/Swap';
import { snackbar } from '../../components/snackbar/SnackBar';
import { getCurrencyExchangeRates } from '../../../shared/exchange-rates';
import { calculateCurrencyExchangeSession } from '../../../redux/exchange/session/actions';
import { useRouter } from 'next/router';
import { useMediaQuery } from '@mui/material';
import CurrencyExchangeComponent from '../../../components/landingPage/components/DesignSystem/MultiConverterTab/CurrencyExchangeComponent';

interface CurrencyExchangeSectionProps {
    isMobileServer: boolean;
    isHorizontal?: boolean;
}

const CurrencyExchangeSection: React.FC<CurrencyExchangeSectionProps> = ({
    isMobileServer,
    isHorizontal = true,
}) => {
    const router = useRouter();
    const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.lg}px)`);
    const [horizontal, setHorizontal] = useState(isHorizontal || !isMobileServer);
    const [sendingRate, setSendingRate] = useState(null);
    const [receivingRate, setReceivingRate] = useState(null);
    const [receivingAmount, setReceivingAmount] = useState('');
    const [sendingAmount, setSendingAmount] = useState('1000.00');
    const [receiveAmountLoading, setReceiveAmountLoading] = useState(false);
    const [sendAmountLoading, setSendAmountLoading] = useState(false);
    const [sendingCurrency, setSendingCurrency] = useState({
        id: 1,
        country_name: "Canada",
        country_id: 40,
        currency_code: "CAD",
        currency_name: "Canadian Dollar",
        iso2: "CA",
        iso3: "CAN",
    });
    const [receivingCurrency, setReceivingCurrency] = useState({
        id: 1,
        country_name: "United States",
        country_id: 236,
        currency_code: "USD",
        currency_name: "United States Dollar",
        iso2: "US",
        iso3: "USA",
    });

    useEffect(() => {
        const initialLoadInfo = async () => {
            await loadTransferRatesRest();
            calculateTransferAmount(sendingAmount, null);
        }
        initialLoadInfo();
    }, []);

    useEffect(() => {
        if (horizontal && isMobile) setHorizontal(false);
    }, [isMobile]);

    useEffect(() => {
        calculateTransferAmount(sendingAmount, null);
    }, [sendingCurrency]);

    const loadTransferRatesRest = async () => {
        try {
            // if (!loading) setLoading(true);
            const data = await getCurrencyExchangeRates();
            if (data && data.length > 0) {
                const exchangeData = data[0];
                if (!exchangeData) return;

                const US = {
                    id: exchangeData?.id,
                    country_name: exchangeData?.country_from,
                    country_id: exchangeData?.country_from_id,
                    currency_code: exchangeData?.currency_code,
                    currency_name: exchangeData?.currency_name,
                    iso2: exchangeData?.iso2,
                    iso3: exchangeData?.iso3,
                }
                const CA = {
                    id: exchangeData?.id,
                    country_name: exchangeData?.country_to,
                    country_id: exchangeData?.country_to_id,
                    currency_code: 'CAD',
                    currency_name: "Canadian Dollar",
                    iso2: "CA",
                    iso3: "CAN",
                }
                setSendingCurrency(CA);
                setReceivingCurrency(US);
            }
        }
        catch (error) {
            snackbar.error(error.message, 3000)
        }
        // finally {
        //     setLoading(false);
        // }
    }

    const calculateTransferAmount = async (transferValue?: string, receivingValue?: string) => {
        try {
            // setCurrencyRateLoading(true);
            if (!sendingCurrency?.country_id || !receivingCurrency?.country_id) return;

            const _args: any = {};
            _args.country_from_id = sendingCurrency.country_id;
            _args.country_to_id = receivingCurrency.country_id;
            _args.is_promo_rate = false;
            _args.is_special_rate = true;
            _args.receiving_amount = receivingValue || '';
            _args.transfer_amount = transferValue || '';

            const { success, data, errorMessage } = await calculateCurrencyExchangeSession(_args);
            if (success && data) {
                const exchangeRates = data.CalculateCurrencyExchangeAmount;
                setReceivingRate(exchangeRates.rate);
                setSendingRate((1 / exchangeRates.rate).toFixed(4));

                if (transferValue) setReceivingAmount(exchangeRates.receiving_amount);
                if (receivingValue) setSendingAmount(exchangeRates.transfer_amount);
            } else if (errorMessage) throw new Error(errorMessage);
            // setCurrencyRateLoading(false);
        }
        catch (error) {
            snackbar.error(error.message, 3000)
        }
    }

    const handleSendAmountChange = async (value: string) => {
        setReceiveAmountLoading(true);
        await calculateTransferAmount(value, null);
        setReceiveAmountLoading(false);
    }

    const handleReceiveAmountChange = async (value: string) => {
        setSendAmountLoading(true);
        await calculateTransferAmount(null, value);
        setSendAmountLoading(false);
    }

    const onSwapHandler = () => {
        setSendingCurrency((prevSendingCurrency) => {
            setReceivingCurrency(prevSendingCurrency);
            return receivingCurrency;
        });
    }

    if (horizontal) {
        return (
            <Card className={classes['rb-ceSection-horizontal-box']}>
                <Box display='flex' gap={24} alignItems='center'>
                    <div className={classes['rb-ceSection-horizontal-box-item']}>
                        <CurrencyInputField
                            label="You have"
                            id="sendingEnd"
                            countrySelectable={true}
                            flagCode={sendingCurrency?.iso2}
                            currency={sendingCurrency?.currency_code}
                            loading={sendAmountLoading}
                            editable
                            handleCountryChange={onSwapHandler}
                            handleChange={handleSendAmountChange}
                            value={sendingAmount}
                            countriesList={[sendingCurrency, receivingCurrency]}
                            errorMessage={Number(sendingAmount) < 10 ? 'Minimum sending amount is $10 CAD' : null}
                        />
                    </div>

                    <div style={{ maxWidth: 'fit-content', maxHeight: 'fit-content' }}>
                        <Button
                            id='swap'
                            variant='text'
                            onClick={onSwapHandler}
                            className={classes['rb-ceSection-swap']}>
                            <Swap width={32} height={32} />
                        </Button>
                    </div>

                    <div className={classes['rb-ceSection-horizontal-box-item']}>
                        <CurrencyInputField
                            label="You have"
                            id="sendingEnd"
                            countrySelectable={true}
                            flagCode={receivingCurrency?.iso2}
                            currency={receivingCurrency?.currency_code}
                            loading={receiveAmountLoading}
                            editable
                            handleCountryChange={onSwapHandler}
                            handleChange={handleReceiveAmountChange}
                            value={receivingAmount}
                            countriesList={[sendingCurrency, receivingCurrency]}
                        />
                    </div>

                    <div style={{ flexGrow: 1 }}>
                        <Button
                            id='exchange-now'
                            fullWidth
                            variant='filledPrimary'
                            onClick={() => router.push('/signup')}
                            className={classes['rb-ceSetion-button']}
                        >
                            Exchange now
                        </Button>
                    </div>
                </Box>
                <Spacing variant='contentToButton' />
                <Box display='flex'>
                    <Box flexGrow={1}>
                        {receivingRate && <Typography variant='textLg' weight='medium' color={theme.palette.primary.navy}>
                            {`1 CAD = ${receivingRate} USD`}
                        </Typography>}
                        {sendingRate && <Typography variant='textMd'>
                            {`1 USD = ${sendingRate} CAD`}
                        </Typography>}
                    </Box>

                    <Box display='flex' justifyContent='flex-end' gap={4}>
                        <Typography variant='textMd'>
                            {'Our exchange fee:'}
                        </Typography>
                        <Typography variant='textMd' weight='medium' color={theme.palette.success.dark}>
                            {'$0.00 CAD'}
                        </Typography>
                    </Box>

                </Box>
            </Card>
        );
    }

    return (
        <Box display='flex' justifyContent='center'>
            <div className={classes['rb-ceSection-vertical-box']}>
                <CurrencyExchangeComponent />
            </div>
        </Box>
    );
};

export default CurrencyExchangeSection;
