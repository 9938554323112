import { DocumentNode } from "graphql";
import { FetchPolicy } from "@apollo/client";
import client from "./client";
import * as Sentry from "@sentry/nextjs";
import { BrowserLogger } from "../../browser-logger-v2";

export interface QueryResult {
    success: boolean;
    errorMessage: string | null;
    data: any;
}

export const dtoneQuery = (
    query: DocumentNode,
    variables: Record<string, any> = {},
    fetchPolicy: FetchPolicy = 'network-only'
): Promise<QueryResult> => {
    return new Promise(resolve => {
        let objToBeReturned = { success: true, errorMessage: null, data: null };
        client
            .query({
                variables,
                query,
                fetchPolicy
            })
            .then(({ data }) => {
                objToBeReturned.data = data;
                resolve(objToBeReturned);
            })
            .catch(err => {
                BrowserLogger.error(err, {
                    action_from: 'dtoneQuery',
                    query: query?.definitions?.[0]?.['name']?.value,
                })
                Sentry.captureException(err, (scope) => {
                    scope.clear();
                    scope.setContext("additionalData", {
                      query: query && query?.definitions && query?.definitions?.length > 0 && query?.definitions[0]['name'] && query?.definitions[0]['name']?.value
                    });
                    return scope;
                });

                objToBeReturned = { ...objToBeReturned, success: false, errorMessage: err.message };
                resolve(objToBeReturned);
            });
    });
};