import React, { useCallback, useState } from 'react'
import { AreaChart, ResponsiveContainer, XAxis, YAxis, Tooltip, Area, } from 'recharts'
import Typography from '../../../remitbee/components/typography/Typography'
import theme from '../../../remitbee/theme/Theme'
import classes from './FavoriteRates.module.scss'
import dayjs from 'dayjs'
import { interval } from 'date-fns'


const RatesGraphV2 = ({ ratesHistory, onMouseHover, showAxisX = false, showAxisY = false, selectedFilter, currencyCode, setDateData, setTimeData, isCurrencyExchange, source, target }) => {
    const [lastPosition, setLastPosition] = useState(null)

    const CustomTooltip: React.FC<any> = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className={classes['rb-graph-tooltip']}>
                    <Typography variant='textLg' color={theme.palette.primary.navy} weight='semibold'>
                        {isCurrencyExchange ? `1 ${source?.currency_code} = ${payload[0].value} ${target?.currency_code}`
                        : payload[0].value}
                    </Typography>
                    <Typography variant='textXs'>{payload[0]?.payload?.name}</Typography>
                </div>
            )
        }
    }

    const formatData = ratesHistory.map(item => {


        return isCurrencyExchange ? {
           rate: parseFloat(item.rate).toFixed(2),
              name: item?.datetime_added || item?.date_added
        } : {
           name: item.datetime_added,
           rate: parseFloat(item.rate).toFixed(2)
        }
    });

    const rates = formatData.map(data => parseFloat(data.rate));
    const minRate = Math.min(...rates);
    const maxRate = Math.max(...rates);

    const padding = 0.5;

    const getXAxisProps = (filter) => {
        if (filter === '1M') {
            return {
                tickFormatter: (date) => dayjs(date).format('MMM D'), // Format as "Jul 5"
                interval: (index) => index % 7 === 0 // Show ticks every 7th data point (weekly)
            };
        } else if (filter === '1Y') {
            return {
                tickFormatter: (date) => dayjs(date).format('MMM D'), // Format as "Jul"
                interval: (index) => index % 30 === 0 // Show ticks every ~30 data points (monthly)
            };
        } else {
            // Default case: Show all dates
            return {
                tickFormatter: (date) => dayjs(date).format('MMM D'),
                interval: 'preserveStartEnd'
            };
        }
    };
    

    const handleMouseMove = useCallback((state) => {
        if (state.isTooltipActive) {
            const { activePayload } = state;
            if (activePayload && activePayload.length && (lastPosition?.name !== activePayload[0].payload.name)) {
                const date = dayjs(activePayload[0].payload.name, "MMM DD, YYYY h:mm a");

                const formattedDate = date.format("DD-MM-YYYY");
                const formattedTime = date.format("h:mm A");
                setLastPosition({
                    name: activePayload[0].payload.name,
                    rate: activePayload[0].payload.rate,
                });
                if(setDateData) setDateData(formattedDate);
                if(setTimeData) setTimeData(formattedTime);
                if(onMouseHover) onMouseHover({
                    date: activePayload[0].payload.name,
                    rate: activePayload[0].payload.rate,
                    currency_code: currencyCode
                });
            }
        }
    }, [lastPosition]);

    return (
        <ResponsiveContainer width="100%" height={220}>
            <AreaChart
                data={formatData}
                onMouseMove={handleMouseMove}
                margin={showAxisY && { top: 10, right: -10, left: -10, bottom: -10 }}
            >
                <defs>
                    <linearGradient id="colorGradient" x1="0" y1="0" x2="1" y2="1">
                        <stop offset="25.52%" stopColor="#1167F7" stopOpacity={1} />
                        <stop offset="111.38%" stopColor="rgba(255, 255, 255, 0.00)" stopOpacity={0} />
                    </linearGradient>
                </defs>
                <XAxis
                    dataKey="name"
                    hide={!showAxisX}
                    axisLine={false}
                    tickLine={false}
                    tickFormatter={(date) => dayjs(date).format('MMM DD')} // Format the date
                    style={{ fontSize: '16px', fill: theme.palette.input.body, fontFamily: 'Inter' }}
                    // {...getXAxisProps(selectedFilter)}
                    interval="preserveStartEnd"
                />
                <YAxis
                    domain={[minRate - padding, maxRate + padding]}
                    hide={!showAxisY}
                    dataKey={'rate'}
                    orientation='right'
                    axisLine={false}
                    tickLine={false}
                    tickFormatter={(value) => value.toFixed(2)}
                    interval="preserveStartEnd"
                    style={{ fontSize: '16px', fill: theme.palette.input.body, fontFamily: 'Inter' }}
                />

                <Tooltip content={<CustomTooltip />} />
                <Area type="monotone" fill="url(#colorGradient)" dataKey="rate" stroke={theme.palette.primary.blue} strokeWidth={'3px'}
                    activeDot={{ r: 4, fill: theme.palette.primary.blue, stroke: theme.palette.primary.blue, strokeWidth: 2 }}
                    dot={false} // Hide the dots
                />
            </AreaChart>
        </ResponsiveContainer>
    )
}

export default RatesGraphV2