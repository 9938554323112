import React from 'react'

const TransferHoriz = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
  <path d="M5.33334 22.6667H26.6667M26.6667 22.6667L21.3333 17.3333M26.6667 22.6667L21.3333 28M26.6667 9.33333H5.33334M5.33334 9.33333L10.6667 4M5.33334 9.33333L10.6667 14.6667" stroke="#1160F7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  )
}

export default TransferHoriz