import React, { useEffect, useRef, useState } from 'react';
import ArrowDown from '../../icons/ArrowDown';
import ArrowUp from '../../icons/ArrowUp';
import theme from '../../theme/Theme';
import classes from './NavigationBar.module.scss';
import Typography from '../../components/typography/Typography';
import Box from '../../components/box/Box';
import { useRouter } from 'next/router';

interface NavigationBarMenuProps {
    label: string;
    id: string;
    icon?: any;
    submenus?: any;
    onClick?: any;
    onChangeLanguage?: any;
    isMobile?: boolean;
    isPrimaryVariant?: boolean;
}

const NavigationBarMenu: React.FC<NavigationBarMenuProps> = ({ label, id, icon, onClick, onChangeLanguage, submenus, isMobile, isPrimaryVariant }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const router = useRouter();
    const componentRef = useRef(null);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    }

    const handleSubmenuClick = (link: string) => {
        if (onChangeLanguage) {
            onChangeLanguage(link);
            return;
        }
        if (!isMobile) setMenuOpen(false);
        router.push(link);
    }

    const handleClickOutside = (event: { target: any; }) => {
        if (menuOpen && componentRef.current && !componentRef.current.contains(event.target as Node)) {
            setMenuOpen(false);
        }
    };

    useEffect(() => {
        if (!isMobile) {
            document.addEventListener('mousedown', handleClickOutside);

            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }
    }, []);

    return (
        <div ref={componentRef} className={isPrimaryVariant ? classes['rb-navigationBar-menu-primary'] : classes['rb-navigationBar-menu']}>
            <button key={id} id={id} onClick={onClick ? onClick : toggleMenu}>
                <div className={classes['rb-navigationBar-menu-language']}>
                    {icon}
                    <Typography variant='textMd' weight='medium' color={isPrimaryVariant ? theme.palette.background.white : theme.palette.primary.navy}>{label}</Typography>
                </div>
                {submenus && <>
                    {menuOpen ?
                        <ArrowUp
                            height={20}
                            width={20}
                            color={isPrimaryVariant ? theme.palette.background.white : theme.palette.primary.navy}
                        /> :
                        <ArrowDown
                            height={20}
                            width={20}
                            color={isPrimaryVariant ? theme.palette.background.white : theme.palette.primary.navy}
                        />}
                </>
                }
            </button>
            {submenus && menuOpen && (
                <>
                    {!isMobile ? <nav className={classes['rb-navigationBar-submenus']} >
                        <ul className={classes['rb-navigationBar-submenus-ul']}>
                        {submenus.map(s => (
                            <li
                                key={s.id}
                                className={classes['rb-navigationBar-submenus-option']}
                                onClick={() => handleSubmenuClick(s.link || s.value)}
                            >
                                <Box key={s.id} display='flex' gap={12}>
                                    {s.icon && <div className={classes['rb-navigationBar-submenus-icon']}>
                                        {s.icon}
                                    </div>}
                                    <Box display='flex' flexDirection='column' gap={4}>
                                        <Typography
                                            variant="textMd"
                                            color={theme.palette.primary.navy}
                                            weight="semibold">{s.label}</Typography>
                                        {s.description &&
                                            <Typography variant="textSm">{s.description}</Typography>
                                        }
                                    </Box>
                                </Box>
                            </li>
                        ))}
                        </ul>
                    </nav> : <nav ref={componentRef}>
                        <ul className={classes['rb-navigationBar-submenus-ul']}>
                        {submenus.map(s => (
                            <li
                                key={s.id}
                                className={classes['rb-navigationBar-submenus-option-mobile']}
                                onClick={() => handleSubmenuClick(s.link || s.value)}
                            >
                                <Typography
                                    variant="textMd"
                                    weight='medium'
                                    color={isMobile && isPrimaryVariant ? theme.palette.background.white : theme.palette.primary.navy}>{s.label}</Typography>
                            </li>
                        ))}
                        </ul>
                    </nav>}
                </>
            )}
        </div>
    );
};

export default NavigationBarMenu;
