import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import { useTheme } from '@mui/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import classnames from 'classnames';
import Markdown from 'markdown-to-jsx';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React from 'react';
import { getImageAlt, parseArrFromString } from '../../../shared/utility';
import { bigTitle, body1, container, mediumTitle } from '../globalStyles';

import dynamic from 'next/dynamic'
import { setRedirectSignupCookie } from '../../../shared/cookie-handler';

// const CustomButton = dynamic(() => import('../components/CustomButtonsWebsite/CustomButton'))
// const CustomUnderlineBigHeading = dynamic(() => import('../components/CustomUnderlinesWebsite/CustomUnderlineBigHeading'))
// const CanadianBanksSection = dynamic(() => import('../sections/CanadianBanksSection'))
// const HowToPayBanksSection = dynamic(() => import('../sections/HowToPayBanksSection'))
// const HowToSendMoneySection = dynamic(() => import('../sections/HowToSendMoneySection'))
// const ReviewsGoogleTruspilotSection = dynamic(() => import('../sections/ReviewsGoogleTruspilotSection'))
// const Reviews = dynamic(() => import('../../../components/landingPage/sections/new/Reviews'))
// const CareersJobOpenings = dynamic(() => import('../../../components/landingPage/sections/CareersJobOpenings'))
// const TwoBoxOffers = dynamic(() => import('../../../components/landingPage/sections/TwoBoxOffers'))
// const ExchangeRateHistory = dynamic(() => import('../components/ExchangeRateHistory/ExchangeRateHistory'))
// const ExchangeReasons = dynamic(() => import('../sections/ExchangeReasons'))
// const ExchangeRateCompare = dynamic(() => import('../components/ExchangeRateComparion/ExchangeRateCompare'))
// const CurrencyExchange = dynamic(() => import('../sections/CurrencyExchange'))
// const CustomerReviews = dynamic(() => import('../sections/CustomerReviews'))

import CustomButton from '../components/CustomButtonsWebsite/CustomButton';
import CustomUnderlineBigHeading from '../components/CustomUnderlinesWebsite/CustomUnderlineBigHeading';
import CanadianBanksSection from '../sections/CanadianBanksSection';
import HowToPayBanksSection from '../sections/HowToPayBanksSection';
import HowToSendMoneySection from '../sections/HowToSendMoneySection';
import ReviewsGoogleTruspilotSection from '../sections/ReviewsGoogleTruspilotSection';
import Reviews from '../../../components/landingPage/sections/new/Reviews';
import CareersJobOpenings from '../../../components/landingPage/sections/CareersJobOpenings';
import TwoBoxOffers from '../../../components/landingPage/sections/TwoBoxOffers';
import ExchangeRateHistory from '../components/ExchangeRateHistory/ExchangeRateHistory';
import ExchangeReasons from '../sections/ExchangeReasons';
import ExchangeRateCompare from '../components/ExchangeRateComparion/ExchangeRateCompare';
import CurrencyExchange from '../sections/CurrencyExchange';
import CustomerReviews from '../sections/CustomerReviews';

const styles = theme => ({
    container,
    mediumTitle,
    body1,
    bigTitle: {
        ...bigTitle,
        textAlign: 'left'
    },
    root: {
        display: 'flex',
        paddingTop: '120px',
        paddingBottom: '135px',
        marginBottom: '-15px',
        [theme.breakpoints.down('sm')]: {
            paddingTop: '80px',
            paddingBottom: '60px',
            clipPath: 'none',
        },
    },
    stacked: {
        display: 'flex',
        paddingTop: '40px',
        paddingBottom: '50px',
        marginBottom: '-15px',
        [theme.breakpoints.down('sm')]: {
            paddingTop: '30px',
            paddingBottom: '20px',
            clipPath: 'none',
        },
    },
    textCenter: {
        textAlign: 'center',
    },
    title_h2: {
        fontSize: '30px !important'
    },
    title_h1: {
        fontSize: '40px !important',
        "@media (max-width: 959px)": {
            fontSize: '25px !important'
            
        },
    },
    image: {
        maxWidth: '100%',
    },
    widthset: {
        width: '70%',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
          },
    },
    spacing: {
        [theme.breakpoints.down('sm')]: {
            paddingTop: '30px'
        }
    },
    text: {
        fontSize: '16px',
        opacity: '0.85',
        lineHeight: '1.6',
    },
    grey: {
        color: '#31517A !important'
    },
    white: {
        color: '#FFFFFF !important'
    },
});


function FullColumnInfo(props) {
    const { classes, title, text, image, typography, background, actionText, actionUrl, actionType, color, backgroundColor, contentType, contentProps, alignLeft, imageAlt, isUnderlined, reviews, data, countrySending, promotion, stack, canadianBankList, newReview } = props;
    const tp = typography || 'h2';
    const alt_tag = imageAlt || getImageAlt(image);
    const content = parseArrFromString(contentProps);
    const theme = useTheme();
    const router = useRouter();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    
    const getContent = (type, content, data, newReview) => {  
        switch (type) {
            case 'CanadianBankSection': return (<CanadianBanksSection canadianBankList={canadianBankList} />)
            case 'ReviewsGoogleTruspilotSection': return (<ReviewsGoogleTruspilotSection newReview={newReview}/>)
            case 'Reviews' : return (<Reviews />)
            case 'HowToPayBanksSection': return (<HowToPayBanksSection content={content} />)
            case 'HowToSendMoneySection': return (<HowToSendMoneySection content={content} />)
            case 'TwoBoxOffers': return (<TwoBoxOffers content={content} />)
            case 'CareersJobOpenings': return (<CareersJobOpenings content={content} />)
            case 'ExchangeRateHistory': return (<Grid item xs={12}><ExchangeRateHistory /></Grid>)
            case 'ExchangeReasons': return (<ExchangeReasons/>)
            case 'ExchangeRateCompare': return (<ExchangeRateCompare hideConverter={true} data={data} countrySending={countrySending} />)
            case 'CurrencyExchange': return (<CurrencyExchange/>)
            case 'CustomerReviews': return(<CustomerReviews reviews={parseArrFromString(reviews)}/>)
        }
    }

    const goToUrl = async () => {
        let query = null;
        if (actionUrl && actionUrl.includes('signup')) {
            await setRedirectSignupCookie(router.asPath)
          }
        if (promotion) {
            query = addReferralLink()
        }
        router.push({pathname: actionUrl, query});
    }
    
    const addReferralLink = () => {
        let _args = {}
        _args = { promo: promotion };
        return _args;
    }

    return (
        <div className={classnames((stack)?classes.stacked:classes.root, !alignLeft && classes.textCenter)}
            style={{ background: background ? `url('${background}') center no-repeat` : backgroundColor }}>
            <Container className={classes.container}>
                <Grid container spacing={isMobile ? 0 : 5} justifyContent={alignLeft ? 'left' : 'center'} className={stack && classes.widthset}>
                    {title && <Grid item xs={12}>
                        <Typography component="div" variant="h2" className={classnames(classes.bigTitle, classes[`title_${tp}`], classes[color], !alignLeft && classes.textCenter)}>
                            {title}
                        </Typography>
                        {isUnderlined && <Grid item xs={12}>
                            <CustomUnderlineBigHeading/>
                        </Grid>}
                        {text && <Typography component="div" variant="body1" marked="center" className={classnames(classes.text, classes[color])}>
                            <Markdown
                                children={text}
                            />
                        </Typography>}
                    </Grid>}
                    {image && <Grid item xs={12} className={classes.featuresBox}>
                        <div className={classes.item}>
                            <img
                                className={classes.image}
                                src={image}
                                alt={alt_tag || 'imageAlt'}
                                loading="lazy"
                            />
                        </div>
                    </Grid>}
                    {contentType && getContent(contentType, content, data, newReview)}
                    {actionText &&
                        <Grid item xs={12} md={4} className={classes.spacing}>
                            <CustomButton type={actionType} onClick={goToUrl}>{actionText}</CustomButton>
                        </Grid>}
                </Grid>
            </Container>
        </div>
    );
}

FullColumnInfo.propTypes = {
    actionText: PropTypes.string,
    actionUrl: PropTypes.string,
    actionType: PropTypes.string,
    title: PropTypes.any,
    image: PropTypes.string,
    typography: PropTypes.string,
    text: PropTypes.string,
    background: PropTypes.string,
    color: PropTypes.string,
    content: PropTypes.any,
    backgroundColor: PropTypes.string,
    contentType: PropTypes.string,
    stack: PropTypes.bool
};

export default withStyles(styles)(FullColumnInfo);