import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { makeStyles } from '@mui/styles';
import React from "react";
import { CustomSlider, CustomThumbComponent } from './CustomSlider';

const useStyles = makeStyles(theme => ({
    root: {
        //width: 300
        maxWidth: 900,
    },
    header: {
        backgroundColor: theme.palette.primary.dark,
        color: "white",
        maxWidth: '220px',
        padding: '15px 10px',
        borderRadius: '8px',
        margin: 'auto',
        textAlign: 'center',
        position: 'relative',
        top: '-48px',
        fontSize: '20px',
    },
    paper: {
        padding: "20px 40px 50px 40px",
        width: "100%",
        boxShadow: '12px 16px 20px rgba(18,71,155,0.15)',
        borderRadius: '20px',
    },
    sendingValue: {
        fontSize: "20px",
        color: theme.palette.primary.dark,
        fontWeight: "500",
        minWidth: "150px",
        display: "inline-block",
    },
    sendingLabel: {
        fontSize: "18px",
        color: theme.palette.primary.light,
    },
    margin: {
        height: theme.spacing(3)
    },
    itemTitle: {
        backgroundColor: theme.palette.primary.dark,
        color: "white",
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
        padding: '5px',
    },
    itemRoot: {

    },
    itemValue: {
        marginTop: "20px",
        fontSize: "20px",
        color: theme.palette.primary.dark,
    },
    helpIcon: {
        float: 'right',
        cursor: 'pointer',
        color: '#fff',
        paddingRight: '10px',
        textDecoration: 'none',
        fontWeight: 'bold'
    },
    itemPaper: {
        textAlign: "center",
        minHeight: "100px",
        minWidth: "100px",
        maxHeight: "190px",
        maxWidth: "190px",
        boxShadow: '0px 4px 6px rgba(18,71,155,0.15)',
        margin: 'auto',
    },
    itemPaperContainer: {
        "@media (max-width: 599px)": {
            paddingBottom: '20px',
        },
    },
    innerContainer: {
        display: 'flex',
    },
    sliderContainer: {
        paddingTop: '20px',
    },
}));

// Each card with a fee value
const FeeItem = ({ value, title }) => {
    const classes = useStyles();
    let valueText = value > 0 ? `${value} CAD` : 'FREE';
    let textStyle = value > 0 ? {} : { color: "green" };
    return (
        <div className={classes.itemPaperContainer}>
            <Paper className={classes.itemPaper}>
                <div className={classes.itemTitle}>{title} {title === 'Wallet' ? <a href="/remitbee-balance" className={classes.helpIcon}>
                    ?</a> : null}</div>
                <div className={classes.itemValue} style={textStyle}>{valueText} </div>
            </Paper>
        </div>
    )
}

let marks = [500, 1000, 1500, 2000, 2500].map(e => ({ value: e }));

export default function FeeSlider({ content }) {
    const classes = useStyles();
    const [fees, setFees] = React.useState({
        wallet: 0,
        interac: 2.99,
        debit: 2.99
    });
    const [sliderValue, setSliderValue] = React.useState(500);
    const [resultAmount, setResultAmount] = React.useState();

    const handleSliderChange = (event, newValue) => {
        setSliderValue(newValue);
    };

    // const handleInputChange = event => {
    //     setSliderValue(event.target.value === "" ? "" : Number(event.target.value));
    // };

    // const handleBlur = () => {
    //     if (sliderValue < 0) {
    //         setSliderValue(0);
    //     } else if (sliderValue > 100) {
    //         setSliderValue(100);
    //     }
    // };

    const calculateFees = () => {
        let amount = sliderValue;
        setResultAmount(Number(amount).toFixed(2));

        let newFees = {
            wallet: (amount >= 499.99 ? 0 : 2.99).toFixed(2),
            interac: (2.99).toFixed(2),
            debit: (amount <= 100 ? 2.99 : amount <= 300 ? 4.99 : amount <= 500 ? 6.99 : amount <= 1000 ? 8.99 : amount <= 1500 ? 14.99 : amount <= 2000 ? 19.99 : amount <= 2500 ? 24.99 : 29.99).toFixed(2)
        };
        setFees(newFees);
    };

    React.useEffect(() => {
        calculateFees();
    }, [sliderValue]);

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Grid container spacing={3} justifyContent="space-evenly" alignItems="center"> git checkout RBC-629-new-fees
                    <Grid item xs={12}>
                        <div className={classes.header}>{content.title}</div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FeeItem value={fees.wallet} title={content.wallet} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FeeItem value={fees.interac} title={content.interac} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FeeItem value={fees.debit} title={content.debit} />
                    </Grid>
                    <Grid item xs={12} sm={10} className={classes.sliderContainer}>
                        <CustomSlider
                            ThumbComponent={CustomThumbComponent}
                            value={typeof sliderValue === "number" ? sliderValue : 0}
                            onChange={handleSliderChange}
                            aria-labelledby="fee-input-slider"
                            defaultValue={500}
                            marks={marks}
                            min={10}
                            max={3000}
                            valueLabelDisplay="off"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {/* <Input
                            className={classes.input}
                            value={value}
                            size="small"
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            inputProps={{
                                step: 10,
                                min: 0,
                                max: 100,
                                type: "number",
                                "aria-labelledby": "input-slider"
                            }}
                        /> */}
                        <div>
                            <span className={classes.sendingLabel}>You sending: </span>
                            <span className={classes.sendingValue}>{resultAmount} CAD</span>
                        </div>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}