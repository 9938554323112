import React, { useEffect, useRef, useState } from 'react';
import classes from './UserReviewsSection.module.scss';
import { useMediaQuery } from '@mui/material';
import theme from '../../../../../remitbee/theme/Theme';
import Box from '../../../../../remitbee/components/box/Box';
import Typography from '../../../../../remitbee/components/typography/Typography';
import ArrowLeftCircled from '../../../../../remitbee/icons/ArrowLeftCircled';
import ArrowRightCircled from '../../../../../remitbee/icons/ArrowRightCircled';
import Avatar from '../../../../../../public/design-system/landing/UserReviewsSection/Avatar.svg';
import Star from '../../../../../../public/design-system/landing/UserReviewsSection/Star.svg';
import Quotes from '../../../../../../public/design-system/landing/UserReviewsSection/Quotes.svg';
import NewQuotes from '../../../../../../public/design-system/landing/UserReviewsSection/NewQuotes.svg';
import Ellipse from '../../../../../remitbee/icons/Ellipse';
import { googleReviews } from '../../../../../redux/system/actions';
import { parseArrFromString } from './../../../../../shared/utility';
import Image from '../../../../../remitbee/components/image/Image';
import Container from '../../../../../remitbee/components/container/Container';
import Spacing from '../../../../../remitbee/styles/spacing/Spacing';
import { getImageAlt } from '../../../../../shared/utility';

interface Props {
  backgroundColor?: string;
  type?: string;
  title?: string;
  titleVariant?: string;
  titleColor?: string;
  text?: string;
  textVariant?: string;
  textColor?: string;
  reviews?: any;
  mockReviews?: any;
  imageUrl?: string;
  userRating?: string;
  reverse?: boolean;
  userName?: string;
  userOccupation?: string;
}

const UserReviewsSection: React.FC<Props> = ({
  backgroundColor,
  type,
  text,
  title,
  reviews,
  mockReviews = [],
  textColor,
  textVariant,
  titleColor,
  titleVariant,
  userRating,
  imageUrl,
  reverse,
  userName,
  userOccupation,
}) => {
  const isNotMobile = useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`);
  const [selectedTab, setSelectedTab] = useState(0);
  const [userReviews, setUserReviews] = useState(typeof mockReviews === 'string'? parseArrFromString(mockReviews)  :mockReviews);
  const scrollRef = useRef(null);
  const reviewsData = reviews ? parseArrFromString(reviews) : null;

  const loadReviews = async () => {
    try {
      const { success, errorMessage, data } = await googleReviews();
      if (errorMessage || !success) return;
      const negativeKeywords = [
        "do not recommend","bad experience","bad service",
        "poor service","money not received","poor exchange rate","disappointed", "not happy",
        "terrible experience",
        "not satisfied",
      ];
      const reviews = data?.GoogleReviews?.filter((review) => {
        const reviewText = review.feedback.toLowerCase();
        return !negativeKeywords.some((keyword) => reviewText.includes(keyword));
      });
      setUserReviews(reviews);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    loadReviews();
  }, []);

  const getContent = () => {
    let ImagePropsObject;

    if (imageUrl)
      return (
        <Image
          url={imageUrl}
          alt={getImageAlt(imageUrl) || 'imageAlt'}
          className={classes['rb-image']}
          //width={480}
          //height={contentType === 'userReview' ? 500 : 380}
          {...ImagePropsObject}
        />
      );
  };

  const setSelectedTabAndScroll = (index) => {
    if (index > userReviews?.length - 1 || index < 0) return;
    setSelectedTab(index);
    if (scrollRef.current) {
      const containerWidth = scrollRef?.current?.clientWidth;
      const tabWidth = scrollRef?.current?.children[index]?.offsetWidth;
      const tabPosition = scrollRef?.current?.children[index]?.offsetLeft;
      const scrollPosition = tabPosition - (containerWidth / 2 - tabWidth / 2);
      scrollRef?.current?.scrollTo({
        left: isNotMobile ? scrollPosition : tabPosition,
        behavior: 'smooth',
      });
    }
  };

  const handleScroll = () => {
    if (scrollRef?.current) {
      const container = scrollRef?.current;
      const containerWidth = container?.clientWidth;
      const scrollLeft = container?.scrollLeft;

      let currentIndex = 0;
      let currentScroll = 0;
      Array.from(container?.children)?.some((tab: any, index) => {
        currentScroll += tab?.offsetWidth;
        if (currentScroll > scrollLeft + containerWidth / 2) {
          currentIndex = index;
          return true;
        }
        return false;
      });

      if (scrollLeft === 0) {
        currentIndex = 0;
      }

      setSelectedTab(currentIndex);
    }
  };

  React.useEffect(() => {
    if (scrollRef?.current) {
      scrollRef?.current?.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollRef?.current) {
        scrollRef?.current?.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <section style={{ backgroundColor: backgroundColor || null }}>
    {
      type === 'halfWidthUserReview' ?
       <Container type="large" className={classes['rb-root-review']}>
       <Box
          display="flex"
          className={classes['rb-main-grid']}
          gap={isNotMobile && 64}
          flexDirection={
            !isNotMobile
                ? 'column'
              : reverse
              ? 'row-reverse'
              : 'row'
          }
        >
          <Box className={classes['rb-info-grid']}>
          <Box mb={10}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="43"
                  height="35"
                  viewBox="0 0 43 35"
                  fill="none"
                >
                  <path
                    d="M42.28 34.3H26.04C24.4533 29.1667 23.66 23.8467 23.66 18.34C23.66 12.74 25.1067 8.30666 28 5.03999C30.9867 1.68 35.3733 0 41.16 0V7.84C36.4933 7.84 34.16 10.6867 34.16 16.38V19.04H42.28V34.3ZM18.62 34.3H2.38C0.793333 29.1667 0 23.8467 0 18.34C0 12.74 1.44667 8.30666 4.34 5.03999C7.32667 1.68 11.7133 0 17.5 0V7.84C12.8333 7.84 10.5 10.6867 10.5 16.38V19.04H18.62V34.3Z"
                    fill="#C5D9FC"
                  />
                </svg>
              </Box>
              <Box display="flex" flexDirection={'column'} gap={8}>
                {title && (
                  <Typography
                    className={classes['rb-title']}
                    variant={titleVariant || 'displayMd'}
                    weight={'bold'}
                    color={titleColor || theme.palette.primary.navy}
                    align={ 'left'}
                    style={{
                      display: !isNotMobile && 'block',
                      textWrap: !isNotMobile ? 'nowrap' : 'wrap',
                    }}
                  >
                    {`"${title}"`}
                  </Typography>
                )}
              </Box>
              {text && (
                <>
                  <Spacing variant="betweenHeaderToSubheader" />
                  <Typography
                    variant={textVariant || 'textLg'}
                    weight={ 'regular'}
                    color={textColor || theme.palette.input.body}
                    align={ 'left'}
                  >
                    {text}
                  </Typography>
                </>
              )}
              <Box mt={isNotMobile ? 10 : 8} display="flex" flexDirection="column" gap={12}>
                <Box display="flex" gap={8}>
                  {Array(parseInt(userRating))
                    .fill(0)
                    .map((_, index) => (
                      <svg
                        key={index}
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="19"
                        viewBox="0 0 18 19"
                        fill="none"
                      >
                        <path
                          d="M9.35279 0.530828L11.9212 5.98908L17.6625 6.86456C17.7354 6.87589 17.8038 6.90825 17.86 6.95802C17.9162 7.00778 17.9581 7.07297 17.9808 7.14624C18.0035 7.2195 18.0061 7.29794 17.9885 7.37271C17.9708 7.44748 17.9336 7.51561 17.8809 7.56945L13.7272 11.8172L14.7079 17.8162C14.7206 17.8921 14.7126 17.9701 14.685 18.0415C14.6574 18.1128 14.6112 18.1747 14.5517 18.22C14.4922 18.2653 14.4217 18.2923 14.3482 18.2978C14.2748 18.3034 14.2013 18.2874 14.1362 18.2515L9.00059 15.4197L3.86501 18.2524C3.7999 18.2883 3.72643 18.3045 3.65297 18.299C3.5795 18.2935 3.50898 18.2666 3.44942 18.2213C3.38987 18.176 3.34366 18.1141 3.31606 18.0427C3.28845 17.9714 3.28055 17.8933 3.29326 17.8175L4.27396 11.8172L0.119092 7.56945C0.0664096 7.51561 0.0291506 7.44748 0.0115104 7.37271C-0.00612975 7.29794 -0.00345047 7.2195 0.0192466 7.14624C0.0419436 7.07297 0.0837569 7.00778 0.139978 6.95802C0.196198 6.90825 0.264593 6.87589 0.33746 6.86456L6.07883 5.98908L8.64838 0.530828C8.68042 0.46166 8.73046 0.403328 8.79275 0.362512C8.85504 0.321697 8.92707 0.300049 9.00059 0.300049C9.0741 0.300049 9.14613 0.321697 9.20842 0.362512C9.27071 0.403328 9.32075 0.46166 9.35279 0.530828Z"
                          fill="#FAC948"
                        />
                      </svg>
                    ))}
                </Box>
                <Typography variant="textLg" weight="semibold" color={theme.palette.primary.navy}>
                  {userName}
                  {userOccupation && (
                    <span className={classes['rb-userOccupation']}> {userOccupation}</span>
                  )}
                </Typography>
              </Box>
          </Box>
          {getContent()}
          </Box>
       </Container>
       :   <Box flexDirection={'column'} className={classes['rb-root']}>
      <Box display="flex" flexDirection={'row'} justifyContent="space-between">
        <Box display="flex" flexDirection={'column'} gap={16} className={classes['rb-content']}>
          <Typography
            className={classes['rb-title']}
            variant={titleVariant || 'displayLg'}
            weight={'bold'}
            color={titleColor || theme.palette.primary.navy}
            align={isNotMobile ? (type === 'cardView' ? 'center' : 'left') : 'center'}
          >
            {title
              ? title
              : type === 'review'
              ? 'Google Reviews'
              : `Don’t just take our word for it`}
          </Typography>
          <Typography
            paragraph={true}
            className={type === 'cardView' && classes['rb-text']}
            variant={textVariant || 'textLg'}
            weight={'regular'}
            color={textColor || theme.palette.input.body}
            align={isNotMobile ? (type === 'cardView' ? 'center' : 'left') : 'center'}
          >
            {text ? text : `We have thousands of happy Remitbee users to back us up`}
          </Typography>
        </Box>
        {isNotMobile && type !== 'cardView' && (
          <Box className={classes['rb-arrows']}>
            <Box onClick={() => setSelectedTabAndScroll(selectedTab - 1)}>
              <ArrowLeftCircled active={selectedTab !== 0} />
            </Box>
            <Box onClick={() => setSelectedTabAndScroll(selectedTab + 1)}>
              <ArrowRightCircled
                active={selectedTab < (reviews ? reviews?.length - 1 : userReviews?.length - 1)}
              />
            </Box>
          </Box>
        )}
      </Box>
      <Box>
        {type !== 'cardView' ? (
          <>
            {' '}
            {userReviews && reviewsData === null && (
              <Box
                componentRef={scrollRef}
                display="flex"
                gap={24}
                alignItems={'stretch'}
                className={classes['rb-review-cards']}
              >
                {userReviews?.length > 0 &&
                  userReviews.map((item, index) => {
                    return (
                      <Box
                        key={index}
                        className={`
                      ${classes['rb-review-box']}
                      ${index === selectedTab && classes['rb-tab-review-active']}
                  `}
                      >
                        <Box className={classes['rb-review']}>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="flex-start"
                          >
                            <Box display="flex" justifyContent="flex-start" gap={24}>
                              <Box className={classes['rb-avatar']}>
                                <Image
                                  url={item?.image_url || Avatar}
                                  className={classes['rb-avatar-image']}
                                  width={80}
                                  height={80}
                                />
                              </Box>
                              <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="flex-start"
                                justifyContent="center"
                                gap={12}
                              >
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="flex-start"
                                  gap={8}
                                >
                                  {item?.rating &&
                                    Array.from({ length: item?.rating }).map((_, index) => (
                                      <img key={index} src={Star} alt="star" />
                                    ))}
                                </Box>
                                <Typography
                                  className={classes['rb-review-name']}
                                  variant={'textXl'}
                                  weight={'bold'}
                                  color={theme.palette.primary.navy}
                                  align="left"
                                >
                                  {item?.name}
                                </Typography>
                              </Box>
                            </Box>
                            {isNotMobile && (
                              <Box className={classes['rb-quotes']}>
                                <img src={Quotes} alt="quotes" />
                              </Box>
                            )}
                          </Box>
                          <Typography
                            className={
                              type === 'normalText'
                                ? classes['rb-review-text-normal']
                                : classes['rb-review-text']
                            }
                            variant={'textLg'}
                            weight={'regular'}
                            color={theme.palette.input.body}
                            align="left"
                          >
                            {item?.feedback}
                          </Typography>
                          {item?.isLinkText !== 'none' && (
                            <Typography
                              className={classes['rb-review-link']}
                              variant={'textMd'}
                              weight={'regular'}
                              color={theme.palette.primary.blue}
                              align="left"
                              onClick={() => window.open(item?.author_url, '_blank')}
                            >
                              See it on Google
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    );
                  })}
              </Box>
            )}{' '}
          </>
        ) : (
          <Box display="flex" gap={24} className={classes['rb-review-cards-view']}>
            {userReviews?.length > 0 &&
              userReviews.map((item, index) => {
                return (
                  <Box
                    className={`
                      ${classes['rb-review-box-card-view']}
                  `}
                  >
                    <Box className={classes['rb-review']}>
                      <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                          <Box>
                            <img src={NewQuotes} alt="quotes" />
                          </Box>
                      </Box>
                      <Typography
                        className={classes['rb-card-review-text']}
                        variant={'textLg'}
                        weight={'regular'}
                        color={theme.palette.input.body}
                        align="left"
                      >
                        {item?.feedback}
                      </Typography>
                      <Box display="flex" justifyContent="flex-start" gap={16}>
                        <Box className={classes['rb-avatar']}>
                          <Image
                            url={item?.image_url || Avatar}
                            className={classes['rb-avatar-image']}
                            width={64}
                            height={64}
                          />
                        </Box>
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="flex-start"
                          justifyContent="center"
                          gap={8}
                        >
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-start"
                            gap={isNotMobile && 8}
                          >
                            {item?.rating &&
                              Array.from({ length: item?.rating }).map((_, index) => (
                                <img key={index} src={Star} alt="star"  width={isNotMobile ? 20 : 18} height={isNotMobile ? 20 : 16}/>
                              ))}
                          </Box>
                          <Typography
                            className={classes['rb-review-name']}
                            variant={isNotMobile ? 'textLg' : 'textSm'}
                            weight={'semibold'}
                            color={theme.palette.primary.navy}
                            align="left"
                          >
                            {item?.name}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                );
              })}
          </Box>
        )}
        {reviewsData && (
          <Box
            componentRef={scrollRef}
            display="flex"
            gap={24}
            alignItems={'stretch'}
            className={classes['rb-review-cards']}
          >
            {reviewsData?.length > 0 &&
              reviewsData.map((item, index) => {
                return (
                  <Box
                    key={index}
                    className={`
                      ${classes['rb-review-box']}
                      ${index === selectedTab && classes['rb-tab-review-active']}
                  `}
                  >
                    <Box className={classes['rb-review']}>
                      <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                        <Box display="flex" justifyContent="flex-start" gap={24}>
                          <Box className={classes['rb-avatar']}>
                            <Image
                              url={item?.image_url || Avatar}
                              width={80}
                              height={80}
                              className={classes['rb-avatar-image']}
                            />
                          </Box>
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="flex-start"
                            justifyContent="center"
                            gap={12}
                          >
                            <Typography
                              className={classes['rb-review-name']}
                              variant={'textXl'}
                              weight={'bold'}
                              color={theme.palette.primary.navy}
                              align="left"
                            >
                              {item?.name}
                            </Typography>
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="flex-start"
                              gap={8}
                            >
                              {item?.inst && (
                                <>
                                  <img key={0} src={item?.inst} alt="star" />
                                  <Typography>{item?.instFollower}</Typography>
                                </>
                              )}
                              {item?.tik && (
                                <>
                                  <img key={0} src={item?.tik} alt="star" />
                                  <Typography>{item?.tikFollower}</Typography>
                                </>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <Typography
                        className={classes['rb-review-text']}
                        variant={'textLg'}
                        weight={'regular'}
                        color={theme.palette.input.body}
                        align="left"
                      >
                        {item?.feedback}
                      </Typography>
                      <Typography
                        className={classes['rb-review-link']}
                        variant={'textMd'}
                        weight={'regular'}
                        color={theme.palette.primary.blue}
                        align="left"
                      >
                        <a href={item.link}> {item?.linkText} </a>
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
          </Box>
        )}
      </Box>
      {type !== 'cardView' && (
        <Box display="flex" flexDirection="column">
          <Box display="flex" justifyContent="center" gap={16}>
            {userReviews &&
              userReviews?.length > 0 &&
              Array.from({ length: userReviews?.length }).map((_, index) => (
                <Box
                  className={classes['rb-ellipse']}
                  onClick={() => setSelectedTabAndScroll(index)}
                >
                  <Ellipse color={index === selectedTab ? '#2563EB' : '#C5D9FC'} />
                </Box>
              ))}
          </Box>
        </Box>
      )}
    </Box>
    }
    </section>
  );
};

export default UserReviewsSection;
