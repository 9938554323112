import React from 'react';
import Typography from '../../components/typography/Typography';
import Container from '../../components/container/Container';
import classes from './HorizontalHeroSection.module.scss'
import Spacing from '../../styles/spacing/Spacing';
import CurrencyExchangeSection from '../CurrencyExchangeSection/CurrencyExchangeSection';

enum ContentType {
    IMAGE = 'image',
    VIDEO = 'video',
    TEXT = 'text',
}

interface HorizontalHeroSectionProps {
    title: string;
    text: string;
    contentType: ContentType;
    isMobileServer: boolean;
}

const HorizontalHeroSection: React.FC<HorizontalHeroSectionProps> = ({ title, text, contentType, isMobileServer }) => {
    return (
        <Container type='large' className={classes['rb-horizontalHero-container']}>
            {title && (
                <Typography variant="display2Xl" className="title" align='center'>
                    {title}
                </Typography>
            )}
            {text && (
                <>
                    <Spacing variant="betweenHeaderToSubheader" />
                    <Typography variant="textXl" className="title" align='center'>
                        {text}
                    </Typography>
                </>
            )}
            <div className={`content ${contentType}`}>
                <Spacing variant="betweenSections" />
                <CurrencyExchangeSection isMobileServer={isMobileServer} />
            </div>
        </Container>
    );
};

export default HorizontalHeroSection;