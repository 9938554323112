import React from 'react';
import classes from './Card.module.scss';

type CardProps = {
    id?: string,
    children?: React.ReactNode,
    type?: string,
    isDisabled?: boolean;
    className?: any;
    onClick?: any;
    style?: any;
}

const Card: React.FC<CardProps> = ({
    id,
    children,
    isDisabled,
    className,
    onClick,
    style = null
}) => {
    return (
        <div
            id={id}
            className={`${classes['rb-card']} ${className || ''}`}
            onClick={onClick}
            style={isDisabled ? { pointerEvents: 'none', opacity: '0.5', ...style } :
                onClick ? { cursor: 'pointer', ...style } :
                    style}
        >
            {children}
        </div>
    )
}

export default Card;
