import React, { useEffect, useRef, useState } from 'react';
import Box from '../../../../../remitbee/components/box/Box';
import classes from './TabsSection.module.scss';
import { useMediaQuery } from '@mui/material';
import theme from '../../../../../remitbee/theme/Theme';
import Typography from '../../../../../remitbee/components/typography/Typography';
import Spacing from '../../../../../remitbee/styles/spacing/Spacing';
import Button from '../../../../../remitbee/components/button/Button';
import { setRedirectSignupCookie } from '../../../../../shared/cookie-handler';
import { useRouter } from 'next/router';
import { getImageAlt, parseArrFromString } from '../../../../../shared/utility';
import Markdown from 'markdown-to-jsx';
import { getCountriesPublicByType, esimProductsPublic } from '../../../../../redux/esim/actions';
import BoxList from '../../BoxList';
import ProductCards from '../../../../eSim/ProductSelection/ProductCards';
import { useTranslation } from 'next-i18next';
import Input from '../../../../../remitbee/components/input/Input';
import SearchIcon from '../../../../../remitbee/icons/SearchIcon';
import Image from '../../../../../remitbee/components/image/Image';
import Container from '../../../../../remitbee/components/container/Container';

interface Props {
  reverse?: boolean;
  reverseMobile?: boolean;
  backgroundColor?: string;
  promotion?: any;
  listItems: any;
  title?: any;
  align?: string;
  titleSize?: string;
  type?: string;
  subtitle?: string;
  selectedLocalCountry?: any;
  subtitleVariant?: string;
  subtitleWeight?: string;
  tag?: string;
  titleBottomSpacing?: string;
  actionText?: string;
}

const TabsSectionDS: React.FC<Props> = ({
  reverse,
  reverseMobile,
  backgroundColor,
  promotion,
  listItems,
  title,
  align,
  titleSize,
  type,
  subtitle,
  subtitleVariant,
  selectedLocalCountry,
  subtitleWeight,
  tag,
  titleBottomSpacing,
  actionText,
}) => {
  const { t } = useTranslation('e-sim');
  const isNotTablet = useMediaQuery(`(min-width: ${theme.breakpoints.lg}px)`);
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md}px)`);
  const [selectedTab, setSelectedTab] = React.useState(0);
  const router = useRouter();
  const [countriesListData, setCountriesListData] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [globalList, setGlobalList] = useState(null);
  const [globalListData, setGlobalListData] = useState(null);
  const [localList, setLocalList] = useState(null);
  const [regionalList, setRegionalList] = useState(null);
  const [openFAQ1, setOpenFAQ1] = useState(true);
  const [openFAQ2, setOpenFAQ2] = useState(false);

  const scrollRef = useRef(null);
  align = align
    ? align
    : type === 'fullWidthTab'
    ? 'center'
    : !isNotTablet && title
    ? 'center'
    : 'flex-start';

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const promises = [];
        promises[0] = getCountriesPublicByType('LOCAL');
        promises[1] = getCountriesPublicByType('REGIONAL');
        promises[2] = getCountriesPublicByType('GLOBAL');

        const [resultLocal, resultRegional, resultGlobal] = await Promise.all(promises);
        const countriesList = {
          local: resultLocal?.data?.getCountriesPublic?.countries,
          regional: resultRegional?.data?.getCountriesPublic?.countries,
          global: resultGlobal?.data?.getCountriesPublic?.countries,
        };
        setCountriesListData(countriesList);
        setLocalList(countriesList?.local);
        setRegionalList(countriesList?.regional);
        if (type === 'local' && selectedLocalCountry) {
          const selectedCountry = countriesList?.local.find(
            (country) => country?.iso2 === selectedLocalCountry
          );
          const { success, data, errorMessage } = await esimProductsPublic(
            selectedCountry?.countryId,
            null,
            4,
            'FIXED_VALUE_PIN_PURCHASE'
          );
          if (success && data && data.getProductsPublic) {
            data.getProductsPublic?.products && setGlobalList(data.getProductsPublic?.products);
          }
        }
        if (type === 'regional' && selectedLocalCountry) {
          const selectedCountry = countriesList?.regional.find(
            (country) => country?.iso2 === selectedLocalCountry
          );
          const { success, data, errorMessage } = await esimProductsPublic(
            selectedCountry?.countryId,
            null,
            4,
            'FIXED_VALUE_PIN_PURCHASE'
          );
          if (success && data && data.getProductsPublic) {
            data.getProductsPublic?.products && setGlobalList(data.getProductsPublic?.products);
          }
        }
        if (type === 'esim' || type === 'global') {
          const { success, data, errorMessage } = await esimProductsPublic(
            countriesList?.global[0].countryId,
            null,
            4,
            'FIXED_VALUE_PIN_PURCHASE'
          );
          if (success && data && data.getProductsPublic) {
            data.getProductsPublic?.products && setGlobalList(data.getProductsPublic?.products);
          }
        }
      } catch (error) {
        //   snackbar.error('Something went wrong', 3000)
      } finally {
        //   setLoading(false)
      }
    };
    fetchProducts();
  }, []);

  useEffect(() => {
    if (countriesListData) {
      setLocalList(
        countriesListData?.local?.filter((country) =>
          country?.name.toLowerCase().includes(searchText.toLowerCase())
        )
      );
      setRegionalList(
        countriesListData?.regional?.filter((country) =>
          country?.name.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    }
  }, [searchText]);

  useEffect(() => {
    if (globalList) {
      const data = [...globalList].sort((a, b) => {
        return parseFloat(a.retailAmount) - parseFloat(b.retailAmount);
      });
      setGlobalListData(data);
    }
  }, [globalList]);

  let listEsimData = [
    {
      tabHeader: 'Local',
      title: 'Explore Local plans',
      text: 'Get a prepaid eSIM data plan at local rates in 140+ countries. Enjoy the convenience of reliable and high-speed mobile data tailored to meet your needs.',
      body: <BoxList withoutTopPadding={true} list={localList} title={''} withOutImage={true} />,
    },
    {
      tabHeader: 'Regional',
      title: 'Explore Regional plans',
      text: 'Stay connected across multiple countries with our Regional prepaid eSIM plan. Access fast and reliable data and seamless connectivity without borders.',
      body: (
        <BoxList
          withoutTopPadding={true}
          list={regionalList}
          title={''}
          withOutImage={true}
          isBox={true}
        />
      ),
    },
    {
      tabHeader: 'Global',
      title: 'Explore Global plans',
      text: 'Explore the world with our Global eSIM plan, offering uninterrupted mobile data across the globe. Experience high-speed connectivity no matter where you travel.',
      body: (
        <>
          <Box gap={16} flexWrap="wrap" display="flex">
            {globalListData?.map((data) => {
              return (
                <ProductCards
                  plan={data}
                  selectedPlan={null}
                  destination_type={'public'}
                  t={t}
                  onSelect={() => {}}
                />
              );
            })}
          </Box>
          <Spacing variant="betweenSections" />
          <Box display="flex" justifyContent="center" alignItems="center">
            <Button variant="outlined" id="Know more about Global plans">
              {'Know more about Global plans'}
            </Button>
          </Box>
        </>
      ),
    },
  ];

  const list = listItems
    ? typeof listItems === 'string'
      ? parseArrFromString(listItems)
      : listItems
    : type === 'esim'
    ? listEsimData
    : null;

  const setSelectedTabAndScroll = (index) => {
    setSelectedTab(index);
    if (scrollRef.current) {
      const containerWidth = scrollRef?.current?.clientWidth;
      const tabWidth = scrollRef?.current?.children[index]?.offsetWidth;
      const tabPosition = scrollRef?.current?.children[index]?.offsetLeft;
      const scrollPosition = tabPosition - (containerWidth / 2 - tabWidth / 2);
      scrollRef?.current?.scrollTo({
        left: scrollPosition,
        behavior: 'smooth',
      });
    }
  };

  const addReferralLink = () => {
    let _args = {};
    _args = { promo: promotion };
    return _args;
  };

  const goToUrl = async (actionUrl) => {
    if (!actionUrl) return;
    if (actionUrl.startsWith('http')) {
      window.open(actionUrl, '_blank');
    } else if (actionUrl.startsWith('www')) {
      window.open(`https://${actionUrl}`, '_blank');
    } else {
      let query = null;
      if (actionUrl && actionUrl.includes('signup')) await setRedirectSignupCookie(router.asPath);
      if (actionUrl && actionUrl.includes('signup?')) {
        router.push(actionUrl);
        return;
      }
      if (promotion) query = addReferralLink();
      router.push({ pathname: actionUrl, query });
    }
  };

  return (
    <section style={{ backgroundColor: backgroundColor || null }}>
      <Container type={'regular'} className={type === 'lineTab' ? classes['rb-root-line'] :classes['rb-root']}>
        {
           type === 'lineTab' && <Box display='flex' flexDirection='column' justifyContent='flex-start' className={classes['rb-line-tab']}>
   {tag && (
              <>
                <div className={isMobile ? classes['rb-title'] : null}>
                  <Typography
                    variant={'textLg'}
                    weight="bold"
                    color={theme.palette.primary.blue}
                    align={align && align}
                  >
                    {tag}
                  </Typography>
                </div>
              </>
            )}
            {title && (
              <>
                <div className={isMobile ? classes['rb-title'] : null}>
                  <Typography
                    variant={ 'displayLg'
                    }
                    weight="bold"
                    color={theme.palette.primary.navy}
                    align={align && align}
                  >
                    {title}
                  </Typography>
                  <Spacing variant={titleBottomSpacing ? titleBottomSpacing : 'betweenSections'} />
                </div>
              </>
            )}
            {subtitle && (
              <>
                <div className={isMobile ? classes['rb-title'] : null}>
                  <Typography
                    variant={subtitleVariant ? subtitleVariant : 'textXl'}
                    weight={subtitleWeight ? subtitleWeight : 'semibold'}
                    color={theme.palette.primary.navy}
                    align={align && align}
                  >
                    {subtitle}
                  </Typography>
                  <Spacing variant="betweenSections" />
                </div>
              </>
            )}
            </Box>
        }
        <Box
          display="flex"
          flexDirection={
            type === 'fullWidthTab'
              ? 'column'
              : !isNotTablet
              ? reverseMobile
                ? 'column-reverse'
                : 'column'
              : reverse
              ? 'row-reverse'
              : 'row'
          }
          justifyContent="center"
          alignItems={title ? 'center' : 'flex-start'}
          gap={!isNotTablet ? 40 : 64}
        >
          {list?.length > 0 && list[selectedTab] && list[selectedTab]?.imageUrl && (
            <Box
              display="flex"
              justifyContent={!isNotTablet ? 'center' : reverse ? 'flex-start' : 'flex-end'}
              alignItems="center"
              className={classes['rb-image-box']}
            >
              {/* <img
                            src={list[selectedTab]?.imageUrl}
                            alt={getImageAlt(list[selectedTab]?.imageUrl)}
                            className={classes['rb-image']} width={list[selectedTab]?.width ? list[selectedTab]?.width : 456}
                            height={list[selectedTab]?.height ? list[selectedTab]?.height : 320}
                            /> */}
              <Image
                url={list[selectedTab]?.imageUrl}
                alt={getImageAlt(list[selectedTab]?.imageUrl) || 'image'}
                width={list[selectedTab]?.width ? list[selectedTab]?.width : 456}
                height={380}
                className={classes['rb-image']}
              />
            </Box>
          )}
          <Box>
          { type !== 'lineTab'  &&<> {tag && (
              <>
                <div className={isMobile ? classes['rb-title'] : null}>
                  <Typography
                    variant={'textLg'}
                    weight="bold"
                    color={theme.palette.primary.blue}
                    align={align && align}
                  >
                    {tag}
                  </Typography>
                </div>
              </>
            )}
            {title && (
              <>
                <div className={isMobile ? classes['rb-title'] : null}>
                  <Typography
                    variant={
                      type === 'esim' ||
                      type === 'local' ||
                      type === 'regional' ||
                      type === 'global'
                        ? 'displayXl'
                        : 'displayLg'
                    }
                    weight="bold"
                    color={theme.palette.primary.navy}
                    align={align && align}
                  >
                    {title}
                  </Typography>
                  <Spacing variant={titleBottomSpacing ? titleBottomSpacing : 'betweenSections'} />
                </div>
              </>
            )}
            {subtitle && (
              <>
                <div className={isMobile ? classes['rb-title'] : null}>
                  <Typography
                    variant={subtitleVariant ? subtitleVariant : 'textXl'}
                    weight={subtitleWeight ? subtitleWeight : 'semibold'}
                    color={theme.palette.primary.navy}
                    align={align && align}
                  >
                    {subtitle}
                  </Typography>
                  <Spacing variant="betweenSections" />
                </div>
              </>
            )} </>}
            {type === 'esim' && (
              <Box display="flex" alignItems="center" justifyContent="center">
                <Box mb={12} className={classes['rb-search']}>
                  <Input
                    id="search-destination"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    placeholder={'Search for a country or a region'}
                    startAdornment={
                      <SearchIcon width={20} height={20} color={theme.palette.input.body} />
                    }
                    fullWidth
                  />
                </Box>
              </Box>
            )}
            {type === 'lineTab' ? (
              <>
                {list.map((item, index) => (
                  <Box mt={2} key={index}>
                    <Box
                      style={{
                        borderLeft: `3px solid ${
                          index === selectedTab
                            ? theme.palette.primary.blue
                            : theme.palette.border.light
                        }`,
                      }}
                      className={classes['rb-box']}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        onClick={() => {
                          setSelectedTab(index);
                          setOpenFAQ1(index === 0 ? !openFAQ1 : false);
                          setOpenFAQ2(index === 1 ? !openFAQ2 : false);
                        }}
                        style={{ minHeight: '72px' }}
                      >
                        <Typography
                          variant="textXl"
                          color={theme.palette.primary.navy}
                          weight="semibold"
                        >
                          {item?.title}
                        </Typography>
                      </Box>
                      {index === selectedTab && (
                        <Box>
                          <Typography
                            paragraph={true}
                            variant="textMd"
                            style={{ display: 'inline' }}
                            align={align && align}
                          >
                            <Markdown children={item?.text} style={{ display: 'inline' }} />
                          </Typography>
                          <Spacing variant="betweenSummaryCards" />
                        </Box>
                      )}
                    </Box>
                  </Box>
                ))}

                <Spacing variant="betweenSections" />

                {list[selectedTab]?.actionText && (
                  <Box display="flex" justifyContent="flex-start" flexDirection="column" gap={16}>
                    <Button
                      id={`Tab-${selectedTab}`}
                      fullWidth={!isNotTablet}
                      variant={list[selectedTab]?.actionVariant || 'outlined'}
                      onClick={() => goToUrl(list[selectedTab]?.actionUrl)}
                      className={classes['rb-button']}
                    >
                      {list[selectedTab]?.actionText}
                    </Button>
                  </Box>
                )}
              </>
            ) : (
              <>
                {' '}
                <Box
                  componentRef={scrollRef}
                  className={classes['rb-tab-title-box']}
                  justifyContent={align && align}
                >
                  {list?.map((item, index) => {
                    return (
                      <Box
                        className={`
                                                ${classes['rb-tab-title']}
                                                ${
                                                  index === selectedTab &&
                                                  classes['rb-tab-title-active']
                                                }
                                            `}
                        onClick={() => setSelectedTabAndScroll(index)}
                        key={index}
                      >
                        <Typography
                          variant="textMd"
                          weight="semibold"
                          className={classes['rb-tab']}
                          color={index === selectedTab && theme.palette.background.white}
                        >
                          <Markdown children={item.tabHeader as React.ReactNode} />
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
                {list?.length > 0 && list[selectedTab] && (
                  <Box className={classes['rb-content-box']}>
                    {type === 'esim' ? (
                      <Spacing variant="betweenSectionToInputFields" />
                    ) : (
                      <Spacing variant="betweenSections" />
                    )}
                    <Box display="flex" justifyContent="flex-start" flexDirection="column" gap={16}>
                      <Box display="flex" flexDirection="column" gap={8}>
                        {list[selectedTab]?.tag && (
                          <Typography
                            variant="textMd"
                            weight="bold"
                            color={theme.palette.primary.blue}
                            align={align && align}
                          >
                            <Markdown children={list[selectedTab]?.tag} />
                          </Typography>
                        )}
                        <Typography
                          variant={titleSize ? titleSize : 'displayLg'}
                          weight="bold"
                          color={theme.palette.primary.navy}
                          align={align && align}
                        >
                          <Markdown children={list[selectedTab]?.title} />
                        </Typography>
                      </Box>
                      <Box display="flex" justifyContent={align && align}>
                        <Typography variant="textMd" style={{ display: 'inline' }}>
                          <Markdown
                            children={list[selectedTab]?.text}
                            style={{ display: 'inline' }}
                          />
                        </Typography>
                      </Box>
                    </Box>
                    {type === 'esim' ? (
                      <Spacing variant="betweenSectionToInputFields" />
                    ) : (
                      <Spacing variant="betweenSections" />
                    )}
                    {list[selectedTab]?.actionText && (
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        flexDirection="column"
                        gap={16}
                      >
                        <Button
                          id={`Tab-${selectedTab}`}
                          fullWidth={!isNotTablet}
                          variant={list[selectedTab]?.actionVariant || 'outlined'}
                          onClick={() => goToUrl(list[selectedTab]?.actionUrl)}
                          className={classes['rb-button']}
                        >
                          {list[selectedTab]?.actionText}
                        </Button>
                      </Box>
                    )}
                    {list[selectedTab]?.body && list[selectedTab]?.body}
                  </Box>
                )}
              </>
            )}

            {type === 'local' ? (
              selectedLocalCountry ? (
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  flexDirection="column"
                  gap={16}
                  pl={12}
                  pr={12}
                >
                  <Box gap={16} flexWrap="wrap" display="flex">
                    {globalListData?.map((data) => {
                      return (
                        <ProductCards
                          plan={data}
                          selectedPlan={null}
                          destination_type={'public'}
                          t={t}
                          onSelect={() => {}}
                        />
                      );
                    })}
                  </Box>
                  <Spacing variant="betweenSections" />
                  <Box display="flex" justifyContent="center" alignItems="center">
                    <Button id="Know more about Global plans" onClick={() => router.push('/e-sim')}>
                      {actionText ? actionText : 'Get your India eSIM today'}
                    </Button>
                  </Box>
                </Box>
              ) : (
                <Box display="flex" justifyContent="flex-start" flexDirection="column" gap={16}>
                  <BoxList
                    withoutTopPadding={true}
                    list={localList}
                    title={''}
                    withOutImage={true}
                    noPadding={true}
                  />
                </Box>
              )
            ) : type === 'regional' ? (
              selectedLocalCountry ? (
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  flexDirection="column"
                  gap={16}
                  pl={12}
                  pr={12}
                >
                  <Box gap={16} flexWrap="wrap" display="flex">
                    {globalListData?.map((data) => {
                      return (
                        <ProductCards
                          plan={data}
                          selectedPlan={null}
                          destination_type={'public'}
                          t={t}
                          onSelect={() => {}}
                        />
                      );
                    })}
                  </Box>
                  <Spacing variant="betweenSections" />
                  <Box display="flex" justifyContent="center" alignItems="center">
                    <Button id="Know more about Global plans" onClick={() => router.push('/e-sim')}>
                      {actionText ? actionText : 'Get your India eSIM today'}
                    </Button>
                  </Box>
                </Box>
              ) : (
                <Box display="flex" justifyContent="flex-start" flexDirection="column" gap={16}>
                  <BoxList
                    withoutTopPadding={true}
                    list={regionalList}
                    title={''}
                    withOutImage={true}
                    isBox={true}
                    noPadding={true}
                    withoutButton={true}
                  />
                </Box>
              )
            ) : type === 'global' ? (
              <Box
                display="flex"
                justifyContent="flex-start"
                flexDirection="column"
                gap={16}
                pl={12}
                pr={12}
              >
                <Box gap={16} flexWrap="wrap" display="flex">
                  {globalListData?.map((data) => {
                    return (
                      <ProductCards
                        plan={data}
                        selectedPlan={null}
                        destination_type={'public'}
                        t={t}
                        onSelect={() => {}}
                      />
                    );
                  })}
                </Box>
                {selectedLocalCountry && (
                  <>
                    <Spacing variant="betweenSections" />
                    <Box display="flex" justifyContent="center" alignItems="center">
                      <Button
                        id="Know more about Global plans"
                        onClick={() => router.push('/e-sim')}
                      >
                        {actionText ? actionText : 'Get your India eSIM today'}
                      </Button>
                    </Box>
                  </>
                )}
              </Box>
            ) : null}
          </Box>
        </Box>
        {/* {type !== 'global' && <Spacing variant='fromHeaderToContentInSection' />} */}
      </Container>
    </section>
  );
};

export default TabsSectionDS;
