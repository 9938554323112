import { useEffect } from 'react';

const useGTM = () => {
  useEffect(() => {
    const loadGTM = () => {
      if (!window.gtmDidInit) {
        const GTAG_ID = process.env.REACT_APP_ENV === 'prod' ? 'G-57ZJ2XBXFT' : 'G-8257L8SMN6'; // Use GTag ID here (G-, GT-. AW-)
        const GTAG_WEB_ID = process.env.REACT_APP_ENV === 'prod' ? 'GTM-WP9Q2P' : 'GTM-5X5R7BCS'; //'GTM-T7CDXL2'; // Use Web Container ID here (GTM-)
        const SERVER_CONTAINER = process.env.GTM_SERVICE_DNS || '';

        window.gtmDidInit = true;

        // tag manager web setup
        const gtmScript = document.createElement('script');
        gtmScript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${GTAG_WEB_ID}');`;
        document.head.appendChild(gtmScript);

        // tag manager server setup
        const gtagLink = `https://www.googletagmanager.com/gtag/js?id=${GTAG_ID}`;
        const gtagScript = document.createElement('script');
        gtagScript.src = gtagLink;
        gtagScript.async = true;
        document.head.appendChild(gtagScript);
    
        const inlineGtagScript = document.createElement('script');
        inlineGtagScript.innerHTML = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${GTAG_ID}', {server_container_url: '${SERVER_CONTAINER}',});`;
        document.head.appendChild(inlineGtagScript);
      }
    };

    const onUserInteraction = () => loadGTM();

    window.addEventListener('scroll', onUserInteraction);
    window.addEventListener('mousemove', onUserInteraction);
    window.addEventListener('touchstart', onUserInteraction);

    return () => {
      window.removeEventListener('scroll', onUserInteraction);
      window.removeEventListener('mousemove', onUserInteraction);
      window.removeEventListener('touchstart', onUserInteraction);
    };
  }, []);
};

export default useGTM;
