import classes from './ExchangeRateGraph.module.scss'
import React, { useEffect, useState } from 'react'
import { subscribeRateAlert } from '../../../../../redux/auth/actions';
import Box from '../../../../../remitbee/components/box/Box';
import Typography from '../../../../../remitbee/components/typography/Typography';
import theme from '../../../../../remitbee/theme/Theme';
import Input from '../../../../../remitbee/components/input/Input';
import FlagIcon from '../../../../common/FlagIcon';
import Flag from '../../../../../remitbee/components/flag/Flag';
import CustomCheckbox from '../../../../../remitbee/components/checkbox/Checkbox';
import Divider from '../../../../../remitbee/components/divider/Divider';
import Button from '../../../../../remitbee/components/button/Button';
import { useMediaQuery } from '@mui/material';

const RateEmail = ({ selected, current, isCurrencyExchange }) => {


    const [currency, setCurrency] = useState(null);
      const [weekly, setWeekly] = useState(true);
      const [email, setEmail] = useState('');
      const [rate, setRate] = useState('');
      const [submitted, setSubmitted] = useState(false);
      const [checked, setChecked] = useState(false);
      const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md}px)`);
      

        useEffect(() => {
          if (current) setRate(current);
        }, [current]);
      
        useEffect(() => {
          if (selected.currency_code && selected.iso2) setCurrency(selected)
        }, [selected]);
      
        const handleSetFrequency = () => {
          setWeekly(!weekly);
        }
      
        const handleSubmit = async event => {
          event.preventDefault();
          const data:any = {
            lead_email: email,
            threshold: rate,
            exchange_rate_id: '',
            exchange_type: '',
          }
      
          if (isCurrencyExchange && selected.currency_code === 'USD') {
            data.exchange_rate_id = selected.id;
            data.exchange_type = 'BUY';
          } else if (isCurrencyExchange && selected.currency_code === 'CAD') {
            data.exchange_rate_id = selected.id;
            data.exchange_type = 'SELL';
          } else {
            data.rate_id = selected.id;
          }
      
          await subscribeRateAlert(data);
          setSubmitted(true);
        }

        const handleTryAgain = event => {
            event.preventDefault()
            setEmail('');
            setRate(current || '');
            setSubmitted(false);
          }


  return (
    <>
    <Box display='flex' flexDirection='column' gap={16}>
                            <Typography color={theme.palette.primary.navy} variant='displayLg' weight='bold' align='center'>
                                Want to catch the best rate?
                            </Typography>
<Typography variant='textLg' align='center'>
Don’t worry! We’ve got you covered. Sign up for email notifications and we’ll let you know when your rate is available. We’ll also update you on all of the latest currency news.
</Typography>

                        </Box>
      <Box display='flex' flexDirection='column' gap={16}>
        <Box display='flex' flexDirection='column' gap={8}>
          <Typography variant='textXl' weight='semibold' color={theme.palette.primary.navy}>
           Email me when
          </Typography>
          <Box display='flex' gap={24} alignItems={isMobile ? '' : 'center'} flexDirection={isMobile ? 'column' : 'row'}>
            <div className={classes['rb-flag-container-1']}>
                <div className={classes['rb-input']}>
                    <input type='text' value={1}></input>
                </div>
                <div className={classes['rb-flag']}>
                    <Flag code={'CA'} />
                    <Typography variant='textXl' weight='medium'>CAD</Typography>
                </div>
            </div>
            <Typography variant='textMd' weight='medium' color={theme.palette.primary.navy}>
                is above
            </Typography>
            <div className={classes['rb-flag-container-2']}>
                <div className={classes['rb-input-target']}>
                    <input type='text' value={rate} onChange={(e) => setRate(e.target.value)}></input>
                </div>
                <div className={classes['rb-flag']}>
                    <Flag code={'US'} />
                    <Typography variant='textXl' weight='medium'>USD</Typography>
                </div>
            </div>
          </Box>
        </Box>
        <Box display='flex' gap={5}>
            <Box mt={0.5} style={{ width: 'fit-content'}} justifyContent='center' display='flex'><CustomCheckbox
               isChecked={checked}
                onChange={() => setChecked(!checked)}
                /></Box>
            <Box display='flex' flexGrow={1}><Typography variant='textMd' weight='medium' color={theme.palette.primary.navy}>Send me daily updates</Typography>    </Box>
        </Box>
      </Box>
      <Box>
            <Divider />
        </Box>
        <Box>
            <Input
                type='email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder='Enter your email address'
                label='Email address'
                fullWidth
            />
        </Box>
        <Box display='flex' justifyContent='center'>
            <Button
                className={classes['rb-button']}
                id='subscribe'
                variant='filled'
                fullWidth
                disabled={!email || !rate}
                onClick={handleSubmit}
            >
                Subscribe to rate alerts
            </Button>
        </Box>
    </>
  )
}

export default RateEmail