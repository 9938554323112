import React, { useEffect, useRef, useState } from 'react'
import classes from '../DashboardTopBar.module.scss'
import Box from '../../../../components/box/Box'
import BellIcon from '../../../../icons/BellIcon'
import MenuDropdown from '../../../../components/menu/Menu'
import DropDownItem from '../../../../components/dropDown/DropDownItem'
import NotificationsSplashIcon from '../../../../../../public/img/notifications-splash-v2.svg'
import Typography from '../../../../components/typography/Typography'
import theme from '../../../../theme/Theme'
import { hasUnreadNotificationsV2, broadcastNotificationsInfo as getBroadcastNotifications, notificationHistoryPaginated as getNotificationHistoryPaginated } from '../../../../../redux/notifications/actions'
import dayjs from 'dayjs'
import calendar from 'dayjs/plugin/calendar'
import Dialog from '../../../../components/dialogs/Dialog'
import TopBar from '../../../../components/topBar/TopBar'
import BroadcastNotificationDialog from '../../../../../components/toolbar/BroadcastNotificationDialog'
import { useRouter } from 'next/router'
import { snackbar } from '../../../snackbar/SnackBar'
import { useSelector } from 'react-redux';
import Chip from '../../../chip/Chip';

dayjs.extend(calendar);

const notificationActionMap = {
    "OPEN_VIEW_INBOX": "/inbox",
    "OPEN_VIEW_TRANSACTION_HISTORY": "/transactions",
    "OPEN_VIEW_WALLET": "/balance",
    "OPEN_VIEW_REWARDS": "/rewards",
    "OPEN_VIEW_VERIFICATION": "/verification",
}

const getNotificationActionURL = (actionObj: any) => {
  if (!actionObj) return;
  const { action, escalation_id, tran_id, operation_request_type, level } = actionObj;

  let path = notificationActionMap[action];
  if (!path) return;

  if (action === 'OPEN_VIEW_TRANSACTION_HISTORY' && tran_id && operation_request_type)
    path += `/${tran_id}?type=${operation_request_type}`;
  else if (action === 'OPEN_VIEW_INBOX' && escalation_id)
    path += `/escalation/${escalation_id}`;
  else if (action === 'OPEN_VIEW_VERIFICATION' && level)
    path += `/level-${level}`;

  return path;
}

const Notifications = ({ isMobile }) => {
    const [notificationsOpen, setNotificationsOpen] = useState(false);
    const [hasNewNotifications, setHasNewNotifications] = useState(false);
    const [pageInfo, setPageInfo] = useState<any>({});
    const [hasMoreRecords, setHasMoreRecords] = useState(true)
    const [notificationsPaginated, setNotificationsPaginated] = useState([]);
    const [broadcastNotifications, setBroadcastNotifications] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isShowBroadcastDialog, setIsShowBroadcastDialog] = useState(false);
    const parentRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef(null);
    const router = useRouter();
    const business = useSelector((state:any) => state?.business);
    const [isAllNotifications, setIsAllNotifications] = useState(true);
    const [isUnreadNotifications, setIsUnreadNotifications] = useState(null);
    const [notificationData, setNotificationData] = useState([]);

    const handleMenu = () => {
        if(notificationsOpen) {
            setHasNewNotifications(false);
        }
        setNotificationsOpen(!notificationsOpen);
    }
    const fetchBroadcastNotification = async () => {
        const { success, data } = await getBroadcastNotifications();
        if (success && data) {
          
            const { broadcastNotifications } = data;
            if (broadcastNotifications && broadcastNotifications?.notifications) {
                setBroadcastNotifications(data?.broadcastNotifications.notifications)
                setIsShowBroadcastDialog(true);
            }
        }
    }

    const fetchHasUnreadNotifications = async () => {
      const business_unique_id = business?.account_type === 'business' ? business.business_unique_id : null;
        const { success, data } = await hasUnreadNotificationsV2({ business_unique_id });
        if (success && data && data?.hasUnreadNotificationsV2) {
            const { hasUnreadGeneralNotifications, hasUnreadBroadcastNotifications } = data.hasUnreadNotificationsV2;
            if (hasUnreadGeneralNotifications) setHasNewNotifications(hasUnreadGeneralNotifications);
            if (hasUnreadBroadcastNotifications) fetchBroadcastNotification();
        }
        return (data || {})?.success;
    }

    const fetchMoreRecords = async () => {
      try {
        if (loading && !notificationsOpen) return;
        setLoading(true);

    if (pageInfo && pageInfo?.currentPage >= pageInfo?.totalPages) {
            setHasMoreRecords(false)
            return;
        }
       const business_unique_id = business?.account_type === 'business' ? business?.business_unique_id : null;
        const result = await getNotificationHistoryPaginated({
            business_unique_id,
            pagination: {
                rowsPerPage:  10,
                currentPage: pageInfo?.currentPage!=null ? pageInfo?.currentPage + 1 : 0,
            }
        })

        if (result && result?.data && result?.data?.notificationHistoryPaginated) {
            const newRecords = result?.data?.notificationHistoryPaginated?.notifications;
            if (newRecords?.length == 0) {
                setHasMoreRecords(false);
                setLoading(false);
                return;
            }

            // If is initial load
            if(notificationsPaginated?.length == 0 && newRecords?.length) {
                setNotificationsPaginated(newRecords)
                setPageInfo({
                    currentPage: 4
                })
                
            } 
            else {
                setNotificationsPaginated(prev => [...prev, ...newRecords])
                setPageInfo(result?.data?.notificationHistoryPaginated?.pageInfo)

            }
        }
        setLoading(false);
        return true;
      } catch (error) {
        console.log(error)
      }
  
    }

    useEffect(() => {
        const fetchNotificationsFunction = async () => {
          await fetchHasUnreadNotifications();
          // Check for new notifications every 1 minute
          const interval = setInterval(async () => {
            const response = await fetchHasUnreadNotifications();
            if (!response) clearInterval(interval);
          }, 60000);
          return () => clearInterval(interval);
        };
        fetchNotificationsFunction();
        fetchMoreRecords();
      }, [notificationsOpen]);

      const handleScroll = (event: any) => {
        if(loading) return;
        const { scrollTop, clientHeight, scrollHeight } = event.target;
        const scrollPosition = scrollTop + clientHeight;
        const halfwayPoint = scrollHeight / 2;
        if (scrollPosition >= halfwayPoint) {
            fetchMoreRecords();
        }
      };


  const renderSplashScreen = () => {
    return <>
       <Box className={classes['rb-splash-container']}>
        <Box className={classes['rb-splash-image-container']}>
            <img src={NotificationsSplashIcon} alt="notifications-splash" />
        </Box>
        <Box className={classes['rb-splash-text-container']}>
            <Typography variant='h4' weight='semibold' color={theme.palette.primary.navy}>{"No notifications yet, but stay tuned"}</Typography>
            <Typography variant='body1' weight='regular'>{"We will let you know when any updates come through!"}</Typography>
        </Box>
       </Box>
    </>
  }

  const isValidJSONString = (str: string) => {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  }
  useEffect(() => {
    if(isAllNotifications) {
      setNotificationData(notificationsPaginated);
    } else {
      setNotificationData(notificationsPaginated.filter((el:any) => !el?.date_read));
    }
  }, [isAllNotifications, isUnreadNotifications, notificationsPaginated])

  const renderNotificationsList = (notifications: any) => {
    const list = notifications?.filter((el:any) => el?.message_title || el?.message_text).map((notification:any) => {
       if(!notification) return null;
       const dateSent = dayjs(notification?.date_sent)?.isSame(dayjs(), 'day') ? dayjs(notification?.date_sent)?.format('h:mm A') : dayjs(notification?.date_sent)?.calendar();
       const itemRead = !!notification?.date_read;
       let itemActionObj = null, actionUrl = '';
       try {
        if (notification?.message_action) {
            itemActionObj = isValidJSONString(notification?.message_action) ? JSON.parse(notification?.message_action) : null;
            actionUrl = getNotificationActionURL(itemActionObj);
        }
      } catch (e) {
        snackbar.error(e, 3000)
      }

    const handleNavigation = (url:string) => {
      if(!url) return;

      setNotificationsOpen(false);
      router.push(url);
    }

    return (
        <Box
          key={`notification-history-item-${notification?.id}`}
          id={`notification-history-item-${notification?.id}`}
          onClick={() => handleNavigation(actionUrl)}
          >
           <DropDownItem key={`notification-history-item-${notification?.id}`} onClick={() => {}} listStyle={{ padding: 0 }}>
              <Box className={classes['rb-notifications-list-container']} style={{ backgroundColor: !itemRead ? theme.palette.background.lightBlue : 'transparent' }}>
                <div className={classes['rb-notification-content']}>
                  <Box className={classes['rb-frame-1']}>
                    <div className={classes['rb-notification-type']}>
                        <BellIcon width={16} height={16} color={theme.palette.primary.navy} />
                    </div>
                    <Box className={classes['rb-frame-1-text']}>
                       <Typography variant='body1' weight='semibold' color={theme.palette.primary.navy}>{notification?.message_title}</Typography>
                       <Typography variant='body2' weight='medium'>{notification?.message_text}</Typography>
                    </Box>
                  </Box>
                  <Box className={classes['rb-frame-2']}>
                    <Typography variant='body3' weight='medium' >{dateSent}</Typography>
                    {!itemRead && <div className={classes['rb-frame-2-dot']}></div>}
                  </Box>
                </div>
              </Box>
           </DropDownItem> 
          </Box>
    )
    })

    return list
  } 

  const listOptions = (
    <>
      <div ref={containerRef} className={classes['rb-notifications-wrapper']} onScroll={handleScroll}>
        <Box className={classes['rb-splash-header']}>
          <Box className={classes['header']}>
            <Typography variant={isMobile ? 'h1' : 'h2'} weight='semibold' color={theme.palette.primary.navy}>{"Notifications"}</Typography>
            <Box className={classes['rb-notifications-chip']}>
            <Chip variant="chip" value='All' selected={isAllNotifications} onClick={() =>{
              setIsAllNotifications(true);
              setIsUnreadNotifications(false);
            }}/>
            <Chip variant="chip" value='Unread' selected={isUnreadNotifications} onClick={()=>{
              setIsAllNotifications(false);
              setIsUnreadNotifications(true);
            }} />
            </Box>
          </Box>
        </Box>
        {!loading && !notificationData?.length ? renderSplashScreen() :
          <div onScroll={handleScroll} className={classes['rb-notification-container']}
          >
            {notificationData?.length > 0 ? renderNotificationsList(notificationData) : null}
          </div>}
      </div>
    </>
  ) 

  const handleDialogClose = () => {
    setBroadcastNotifications(null);
    setIsShowBroadcastDialog(false);
}
  return (
    <div ref={parentRef} style={{ position: 'relative'}} >
      <Box onClick={handleMenu} className={classes['rb-dashboard-notification']}>
          <BellIcon width={24} height={24} color={!notificationsOpen ? "#626680" : '#1160F7'} />
          {hasNewNotifications && <span className={classes['rb-notification-redDot']}>•</span>}
      </Box>

      {isMobile && 
        <Dialog
          id="notifications-dialog"
          hideClose
          open={notificationsOpen}
          fullScreen
          headerClass={classes['notification-dialog-header']}
          dialogClass={classes['notification-dialog-body']}
          header={<TopBar onBackAction={() => setNotificationsOpen(false)}/>}
         >
            {listOptions}
        </Dialog>
      }

      {!isMobile && <MenuDropdown
        id="notifications-dropdown"
        parentRef={parentRef}
        open={notificationsOpen}
        onClose={() => {
          if((isUnreadNotifications && isAllNotifications)){
            setNotificationsOpen(false)
          }
        }}
        setOpen={setNotificationsOpen}
        options={listOptions}
        position={{ left: '-100px'}}
        style={{ borderRadius: '20px'}}
      />}

      {
        isAllNotifications && broadcastNotifications && broadcastNotifications.length > 0 && broadcastNotifications.map((notification:any) => {
          return (
           <>
            <Dialog
              key={notification.id}
              id={`broadcast-notification-${notification.id}`}
              open={isShowBroadcastDialog}
              onClose={handleDialogClose}
              header={notification?.message_title}
              text={notification?.message_text}
              customIcon={'/design-system/dashboard/time.svg'}
              primaryActionText='Got it'
              primaryAction={handleDialogClose}
           ></Dialog>
           </>)
        })
      }
    </div>
  )
}

export default Notifications