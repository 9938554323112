export const MOCK_DATA = {
    "conversion": {
      "transfer_amount": "1,000.00",
      "receiving_amount": "67,700.00",
      "rate": 67.7,
      "payment_types": [
          {
              "label": "Debit",
              "payment_type": "DEBIT",
              "funding_time": "Instant",
              "settlement_time": "Jan 10th",
              "timeline": {
                  "funding_timeline": {
                      "predicted_minutes": 0,
                      "predicted_date_time": "2025-01-10 00:30:13",
                      "predicted_date": "Jan 10th",
                      "holiday_dates": null
                  },
                  "settlement_timeline": {
                      "predicted_minutes": 1440,
                      "predicted_date_time": "2025-01-11 00:30:13",
                      "predicted_date": "Jan 11th",
                      "holiday_dates": [
                          "2025-01-10"
                      ]
                  }
              },
              "fees": "0.00"
          },
          {
              "label": "E-Transfer",
              "payment_type": "INTERAC_E_TRANSFER",
              "funding_time": "Within 15 mins",
              "settlement_time": "Jan 10th",
              "timeline": {
                  "funding_timeline": {
                      "predicted_minutes": 15,
                      "predicted_date_time": "2025-01-10 00:45:14",
                      "predicted_date": "Jan 10th",
                      "holiday_dates": null
                  },
                  "settlement_timeline": {
                      "predicted_minutes": 1440,
                      "predicted_date_time": "2025-01-11 00:45:14",
                      "predicted_date": "Jan 11th",
                      "holiday_dates": [
                          "2025-01-10"
                      ]
                  }
              },
              "fees": "0.00"
          },
          {
              "label": "Bill Payment",
              "payment_type": "BILL_PAYMENT",
              "funding_time": "1 business day",
              "settlement_time": "Jan 11th",
              "timeline": {
                  "funding_timeline": {
                      "predicted_minutes": 6300,
                      "predicted_date_time": "2025-01-14 09:30:14",
                      "predicted_date": "Jan 14th",
                      "holiday_dates": [
                          "2025-01-10"
                      ]
                  },
                  "settlement_timeline": {
                      "predicted_minutes": 0,
                      "predicted_date_time": "2025-01-14 09:30:14",
                      "predicted_date": "Jan 14th",
                      "holiday_dates": null
                  }
              },
              "fees": "0.00"
          },
          {
              "label": "Direct Withdrawal (EFT)",
              "payment_type": "EFT",
              "funding_time": "1-2 business day",
              "settlement_time": "Jan 12th",
              "timeline": {
                  "funding_timeline": {
                      "predicted_minutes": 7740,
                      "predicted_date_time": "2025-01-15 09:30:14",
                      "predicted_date": "Jan 15th",
                      "holiday_dates": [
                          "2025-01-10"
                      ]
                  },
                  "settlement_timeline": {
                      "predicted_minutes": 0,
                      "predicted_date_time": "2025-01-15 09:30:14",
                      "predicted_date": "Jan 15th",
                      "holiday_dates": null
                  }
              },
              "fees": "0.00"
          }
      ],
      "payment_additional_info": {
          "first_transfer_free": true,
          "all_transfers_free": false
      },
      "cumulative_rate": 67.7,
      "currency_code": "INR",
      "currency_name": "Indian Rupee",
      "country_id": 103,
      "country_to": "India",
      "special_rate": null,
      "special_rate_adjustment": null,
      "special_rate_transfer_amount_limit": null,
      "special_rate_type": null,
      "spot_rate": 61.6445
  },
    "rates": [
          {
              "rate_id": 102,
              "country_id": 2,
              "country_to": "Aland Islands",
              "iso2": "AX",
              "iso3": "ALA",
              "currency_name": "Euro",
              "currency_code": "EUR",
              "rate": "0.6818",
              "service_type": "REGULAR",
              "favorite": "",
              "special_rate": null,
              "special_rate_adjustment": null,
              "special_rate_transfer_amount_limit": null,
              "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/AX.svg"
          },
          {
              "rate_id": 57,
              "country_id": 6,
              "country_to": "Andorra",
              "iso2": "AD",
              "iso3": "AND",
              "currency_name": "Andorra Euro",
              "currency_code": "EUR",
              "rate": "0.6768",
              "service_type": "REGULAR",
              "favorite": "0",
              "special_rate": "0.8768",
              "special_rate_adjustment": "0.2",
              "special_rate_transfer_amount_limit": "500.00",
              "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/AD.svg"
          },
          {
              "rate_id": 23,
              "country_id": 11,
              "country_to": "Argentina",
              "iso2": "AR",
              "iso3": "ARG",
              "currency_name": "Argentine Peso",
              "currency_code": "ARS",
              "rate": "180.00",
              "service_type": null,
              "favorite": "",
              "special_rate": null,
              "special_rate_adjustment": null,
              "special_rate_transfer_amount_limit": null,
              "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/AR.svg"
          },
          {
              "rate_id": 10,
              "country_id": 14,
              "country_to": "Australia",
              "iso2": "AU",
              "iso3": "AUS",
              "currency_name": "Australian Dollar",
              "currency_code": "AUD",
              "rate": "0.9922",
              "service_type": "REGULAR",
              "favorite": "0",
              "special_rate": null,
              "special_rate_adjustment": null,
              "special_rate_transfer_amount_limit": null,
              "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/AU.svg"
          },
          {
              "rate_id": 10,
              "country_id": 14,
              "country_to": "Australia",
              "iso2": "AU",
              "iso3": "AUS",
              "currency_name": "United States Dollar",
              "currency_code": "USD",
              "rate": "0.7322",
              "service_type": "REGULAR",
              "favorite": "0",
              "special_rate": null,
              "special_rate_adjustment": null,
              "special_rate_transfer_amount_limit": null,
              "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/AU.svg"
          },
          {
              "rate_id": 59,
              "country_id": 15,
              "country_to": "Austria",
              "iso2": "AT",
              "iso3": "AUT",
              "currency_name": "Austrian Euro",
              "currency_code": "EUR",
              "rate": "0.6818",
              "service_type": "REGULAR",
              "favorite": "0",
              "special_rate": null,
              "special_rate_adjustment": null,
              "special_rate_transfer_amount_limit": null,
              "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/AT.svg"
          },
          {
              "rate_id": 81,
              "country_id": 19,
              "country_to": "Bangladesh",
              "iso2": "BD",
              "iso3": "BGD",
              "currency_name": "Bangladeshi Taka",
              "currency_code": "BDT",
              "rate": "82.4332",
              "service_type": "REGULAR",
              "favorite": "",
              "special_rate": "86.4332",
              "special_rate_adjustment": "4",
              "special_rate_transfer_amount_limit": "500.00",
              "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/BD.svg"
          },
          {
              "rate_id": 58,
              "country_id": 22,
              "country_to": "Belgium",
              "iso2": "BE",
              "iso3": "BEL",
              "currency_name": "Belgian Euro",
              "currency_code": "EUR",
              "rate": "0.6818",
              "service_type": "REGULAR",
              "favorite": "0",
              "special_rate": null,
              "special_rate_adjustment": null,
              "special_rate_transfer_amount_limit": null,
              "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/BE.svg"
          },
          {
              "rate_id": 80,
              "country_id": 24,
              "country_to": "Benin",
              "iso2": "BJ",
              "iso3": "BEN",
              "currency_name": "Benin CFA Franc",
              "currency_code": "XOF",
              "rate": "447.2111",
              "service_type": "REGULAR",
              "favorite": "",
              "special_rate": null,
              "special_rate_adjustment": null,
              "special_rate_transfer_amount_limit": null,
              "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/BJ.svg"
          },
          {
              "rate_id": 24,
              "country_id": 27,
              "country_to": "Bolivia",
              "iso2": "BO",
              "iso3": "BOL",
              "currency_name": "Boliviano",
              "currency_code": "BOB",
              "rate": "5.1299",
              "service_type": null,
              "favorite": "",
              "special_rate": null,
              "special_rate_adjustment": null,
              "special_rate_transfer_amount_limit": null,
              "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/BO.svg"
          },
          {
              "rate_id": 24,
              "country_id": 27,
              "country_to": "Bolivia",
              "iso2": "BO",
              "iso3": "BOL",
              "currency_name": "United States Dollar",
              "currency_code": "USD",
              "rate": "0.7322",
              "service_type": null,
              "favorite": "",
              "special_rate": null,
              "special_rate_adjustment": null,
              "special_rate_transfer_amount_limit": null,
              "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/BO.svg"
          },
          {
              "rate_id": 135,
              "country_id": 32,
              "country_to": "Brazil",
              "iso2": "BR",
              "iso3": "BRA",
              "currency_name": "Brazilian Real",
              "currency_code": "BRL",
              "rate": "3.6593",
              "service_type": "REGULAR",
              "favorite": "",
              "special_rate": "4.1593",
              "special_rate_adjustment": "0.5",
              "special_rate_transfer_amount_limit": "500.00",
              "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/BR.svg"
          },
          {
              "rate_id": 113,
              "country_id": 35,
              "country_to": "Bulgaria",
              "iso2": "BG",
              "iso3": "BGR",
              "currency_name": "Euro",
              "currency_code": "EUR",
              "rate": "0.6818",
              "service_type": null,
              "favorite": "",
              "special_rate": null,
              "special_rate_adjustment": null,
              "special_rate_transfer_amount_limit": null,
              "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/BG.svg"
          },
          {
              "rate_id": 129,
              "country_id": 36,
              "country_to": "Burkina Faso",
              "iso2": "BF",
              "iso3": "BFA",
              "currency_name": "Burkina Faso CFA Franc",
              "currency_code": "XOF",
              "rate": "447.2111",
              "service_type": "REGULAR",
              "favorite": "",
              "special_rate": null,
              "special_rate_adjustment": null,
              "special_rate_transfer_amount_limit": null,
              "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/BF.svg"
          },
          {
              "rate_id": 85,
              "country_id": 38,
              "country_to": "Cambodia",
              "iso2": "KH",
              "iso3": "KHM",
              "currency_name": "United States Dollar",
              "currency_code": "USD",
              "rate": "0.7322",
              "service_type": "REGULAR",
              "favorite": "0",
              "special_rate": null,
              "special_rate_adjustment": null,
              "special_rate_transfer_amount_limit": null,
              "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/KH.svg"
          },
          {
              "rate_id": 131,
              "country_id": 39,
              "country_to": "Cameroon",
              "iso2": "CM",
              "iso3": "CMR",
              "currency_name": "Cameroon CFA franc",
              "currency_code": "XAF",
              "rate": "447.2111",
              "service_type": "REGULAR",
              "favorite": "",
              "special_rate": null,
              "special_rate_adjustment": null,
              "special_rate_transfer_amount_limit": null,
              "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/CM.svg"
          },
          {
              "rate_id": 96,
              "country_id": 43,
              "country_to": "Central African Republic",
              "iso2": "CF",
              "iso3": "CAF",
              "currency_name": "CFA Franc BEAC",
              "currency_code": "XAF",
              "rate": "447.2111",
              "service_type": "REGULAR",
              "favorite": "",
              "special_rate": null,
              "special_rate_adjustment": null,
              "special_rate_transfer_amount_limit": null,
              "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/CF.svg"
          },
          {
              "rate_id": 25,
              "country_id": 45,
              "country_to": "Chile",
              "iso2": "CL",
              "iso3": "CHL",
              "currency_name": "Chilean Peso",
              "currency_code": "CLP",
              "rate": "678.4847",
              "service_type": null,
              "favorite": "",
              "special_rate": null,
              "special_rate_adjustment": null,
              "special_rate_transfer_amount_limit": null,
              "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/CL.svg"
          },
          {
              "rate_id": 25,
              "country_id": 45,
              "country_to": "Chile",
              "iso2": "CL",
              "iso3": "CHL",
              "currency_name": "United States Dollar",
              "currency_code": "USD",
              "rate": "0.7322",
              "service_type": null,
              "favorite": "",
              "special_rate": null,
              "special_rate_adjustment": null,
              "special_rate_transfer_amount_limit": null,
              "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/CL.svg"
          },
          {
              "rate_id": 16,
              "country_id": 46,
              "country_to": "China",
              "iso2": "CN",
              "iso3": "CHN",
              "currency_name": "Yuan Renminbi",
              "currency_code": "CNY",
              "rate": "5.2262",
              "service_type": "PRIORITY",
              "favorite": "0",
              "special_rate": null,
              "special_rate_adjustment": null,
              "special_rate_transfer_amount_limit": null,
              "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/CN.svg"
          },
          {
              "rate_id": 16,
              "country_id": 46,
              "country_to": "China",
              "iso2": "CN",
              "iso3": "CHN",
              "currency_name": "United States Dollar",
              "currency_code": "USD",
              "rate": "0.7322",
              "service_type": "PRIORITY",
              "favorite": "0",
              "special_rate": null,
              "special_rate_adjustment": null,
              "special_rate_transfer_amount_limit": null,
              "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/CN.svg"
          },
          {
              "rate_id": 27,
              "country_id": 49,
              "country_to": "Colombia",
              "iso2": "CO",
              "iso3": "COL",
              "currency_name": "Colombian Peso",
              "currency_code": "COP",
              "rate": "2908.3078",
              "service_type": null,
              "favorite": "",
              "special_rate": null,
              "special_rate_adjustment": null,
              "special_rate_transfer_amount_limit": null,
              "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/CO.svg"
          },
          {
              "rate_id": 74,
              "country_id": 51,
              "country_to": "Congo",
              "iso2": "CG",
              "iso3": "COG",
              "currency_name": "CFA Franc BEAC",
              "currency_code": "XAF",
              "rate": "447.2111",
              "service_type": null,
              "favorite": "",
              "special_rate": null,
              "special_rate_adjustment": null,
              "special_rate_transfer_amount_limit": null,
              "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/CG.svg"
          },
          {
              "rate_id": 28,
              "country_id": 54,
              "country_to": "Costa Rica",
              "iso2": "CR",
              "iso3": "CRI",
              "currency_name": "Costa Rican Colon",
              "currency_code": "CRC",
              "rate": "384.4472",
              "service_type": null,
              "favorite": "",
              "special_rate": null,
              "special_rate_adjustment": null,
              "special_rate_transfer_amount_limit": null,
              "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/CR.svg"
          },
          {
              "rate_id": 28,
              "country_id": 54,
              "country_to": "Costa Rica",
              "iso2": "CR",
              "iso3": "CRI",
              "currency_name": "United States Dollar",
              "currency_code": "USD",
              "rate": "0.7322",
              "service_type": null,
              "favorite": "",
              "special_rate": null,
              "special_rate_adjustment": null,
              "special_rate_transfer_amount_limit": null,
              "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/CR.svg"
          },
          {
              "rate_id": 133,
              "country_id": 60,
              "country_to": "Cote d Ivoire",
              "iso2": "CI",
              "iso3": "CIV",
              "currency_name": "Cote d Ivoire CFA Franc",
              "currency_code": "XOF",
              "rate": "447.2111",
              "service_type": null,
              "favorite": "",
              "special_rate": null,
              "special_rate_adjustment": null,
              "special_rate_transfer_amount_limit": null,
              "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/CI.svg"
          },
          {
              "rate_id": 114,
              "country_id": 55,
              "country_to": "Croatia",
              "iso2": "HR",
              "iso3": "HRV",
              "currency_name": "Euro",
              "currency_code": "EUR",
              "rate": "0.6818",
              "service_type": null,
              "favorite": "",
              "special_rate": null,
              "special_rate_adjustment": null,
              "special_rate_transfer_amount_limit": null,
              "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/HR.svg"
          },
          {
              "rate_id": 60,
              "country_id": 58,
              "country_to": "Cyprus",
              "iso2": "CY",
              "iso3": "CYP",
              "currency_name": "Cypriot Euro",
              "currency_code": "EUR",
              "rate": "0.6818",
              "service_type": "REGULAR",
              "favorite": "0",
              "special_rate": null,
              "special_rate_adjustment": null,
              "special_rate_transfer_amount_limit": null,
              "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/CY.svg"
          },
          {
              "rate_id": 44,
              "country_id": 59,
              "country_to": "Czech Republic",
              "iso2": "CZ",
              "iso3": "CZE",
              "currency_name": "Czech Koruna",
              "currency_code": "CZK",
              "rate": "16.8813",
              "service_type": "REGULAR",
              "favorite": "0",
              "special_rate": null,
              "special_rate_adjustment": null,
              "special_rate_transfer_amount_limit": null,
              "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/CZ.svg"
          },
          {
              "rate_id": 44,
              "country_id": 59,
              "country_to": "Czech Republic",
              "iso2": "CZ",
              "iso3": "CZE",
              "currency_name": "United States Dollar",
              "currency_code": "USD",
              "rate": "0.7322",
              "service_type": "REGULAR",
              "favorite": "0",
              "special_rate": null,
              "special_rate_adjustment": null,
              "special_rate_transfer_amount_limit": null,
              "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/CZ.svg"
          },
          {
              "rate_id": 86,
              "country_id": 52,
              "country_to": "Democratic Republic of the Congo",
              "iso2": "CD",
              "iso3": "COD",
              "currency_name": "United States Dollar",
              "currency_code": "USD",
              "rate": "0.7322",
              "service_type": "REGULAR",
              "favorite": "0",
              "special_rate": null,
              "special_rate_adjustment": null,
              "special_rate_transfer_amount_limit": null,
              "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/CD.svg"
          },
          {
              "rate_id": 20,
              "country_id": 61,
              "country_to": "Denmark",
              "iso2": "DK",
              "iso3": "DNK",
              "currency_name": "Danish Krone",
              "currency_code": "DKK",
              "rate": "5.0846",
              "service_type": "REGULAR",
              "favorite": "",
              "special_rate": null,
              "special_rate_adjustment": null,
              "special_rate_transfer_amount_limit": null,
              "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/DK.svg"
          },
          {
              "rate_id": 29,
              "country_id": 64,
              "country_to": "Dominican Republic",
              "iso2": "DO",
              "iso3": "DOM",
              "currency_name": "Dominican Peso",
              "currency_code": "DOP",
              "rate": "40.6598",
              "service_type": null,
              "favorite": "",
              "special_rate": null,
              "special_rate_adjustment": null,
              "special_rate_transfer_amount_limit": null,
              "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/DO.svg"
          },
          {
              "rate_id": 29,
              "country_id": 64,
              "country_to": "Dominican Republic",
              "iso2": "DO",
              "iso3": "DOM",
              "currency_name": "United States Dollar",
              "currency_code": "USD",
              "rate": "0.7322",
              "service_type": null,
              "favorite": "",
              "special_rate": null,
              "special_rate_adjustment": null,
              "special_rate_transfer_amount_limit": null,
              "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/DO.svg"
          },
          {
              "rate_id": 30,
              "country_id": 65,
              "country_to": "Ecuador",
              "iso2": "EC",
              "iso3": "ECU",
              "currency_name": "United States Dollar",
              "currency_code": "USD",
              "rate": "0.7322",
              "service_type": null,
              "favorite": "",
              "special_rate": null,
              "special_rate_adjustment": null,
              "special_rate_transfer_amount_limit": null,
              "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/EC.svg"
          },
          {
              "rate_id": 89,
              "country_id": 66,
              "country_to": "Egypt",
              "iso2": "EG",
              "iso3": "EGY",
              "currency_name": "Egyptian Pound",
              "currency_code": "EGP",
              "rate": "22.9379",
              "service_type": "REGULAR",
              "favorite": "",
              "special_rate": null,
              "special_rate_adjustment": null,
              "special_rate_transfer_amount_limit": null,
              "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/EG.svg"
          },
          {
              "rate_id": 31,
              "country_id": 67,
              "country_to": "El Salvador",
              "iso2": "SV",
              "iso3": "SLV",
              "currency_name": "United States Dollar",
              "currency_code": "USD",
              "rate": "0.7322",
              "service_type": null,
              "favorite": "",
              "special_rate": null,
              "special_rate_adjustment": null,
              "special_rate_transfer_amount_limit": null,
              "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/SV.svg"
          },
          {
            "rate_id": 1,
            "country_id": 103,
            "country_to": "India",
            "iso2": "IN",
            "iso3": "IND",
            "currency_name": "Indian Rupee",
            "currency_code": "INR",
            "rate": "67.70",
            "service_type": null,
            "favorite": "1",
            "special_rate": "70.70",
            "special_rate_adjustment": "3",
            "special_rate_transfer_amount_limit": "250.00",
            "country_flag_icon": "https://mtescommon.blob.core.windows.net/country-flags/IN.svg"
        },
      ],
      "payment_methods": [
          {
              "type": "Remitbee Wallet",
              "fees": {
                  "0-500": "2.99 CAD",
                  ">500": "0.00 CAD"
              }
          },
          {
              "type": "Interac",
              "fees": {
                  ">0": "2.99 CAD"
              }
          },
          {
              "type": "Debit",
              "fees": {
                  "0.01-100": "2.99 CAD",
                  "100.01-300": "4.99 CAD",
                  "300.01-500": "6.99 CAD",
                  "500.01-1000": "8.99 CAD",
                  "1000.01-1499.99": "14.99 CAD",
                  "1500.00-1999.99": "19.99 CAD",
                  "2000.00-2499.99": "24.99 CAD",
                  ">2500.00": "29.99 CAD"
              }
          }
      ]
  }