import { DocumentNode } from "graphql";
import client from "./client";
import { graphqlError } from "../../../../shared/utility";
import { store } from '../../../../redux/store';
import * as Sentry from '@sentry/nextjs';
import { BrowserLogger } from "../../../browser-logger-v2";

export interface MutationResult {
    success: boolean;
    errorMessage: string | null;
    data: any;
}

const QUERY_MUTATION_URI = `${process.env.REACT_APP_API}/v2/customers/sessions/rl/mutations`;

export const apiSessionMutation = (
    mutation: DocumentNode,
    variables: Record<string, any> = {}
): Promise<MutationResult> => {
    return new Promise(resolve => {
        let objToBeReturned = { success: true, errorMessage: null, data: null };
        client
            .mutate({
                mutation,
                variables,
                context: { uri: QUERY_MUTATION_URI }
            })
            .then(({ data }) => {
                objToBeReturned.data = data;
                resolve(objToBeReturned);
            })
            .catch(err => {
                const { customer } = store.getState();
                BrowserLogger.error(err, {
                    action_from: 'graphqlSessionMutation',
                    cus_unique_id: customer?.cus_unique_id,
                    mutation: mutation?.definitions?.[0]?.['name']?.value,
                });
                objToBeReturned = {
                    ...objToBeReturned,
                    success: false,
                    errorMessage: graphqlError(err),
                };
                Sentry.captureException(err, (scope) => {
                    scope.clear();
                    scope.setContext("additionalData", {
                      cus_unique_id: customer?.cus_unique_id,
                      mutation: mutation && mutation?.definitions && mutation?.definitions?.length > 0 && mutation?.definitions[0]['name'] && mutation?.definitions[0]['name']?.value,
                    });
                    return scope;
                });
                objToBeReturned = { ...objToBeReturned, success: false, errorMessage: graphqlError(err) };
                resolve(objToBeReturned);
            });
    });
}