import react from 'react';
import Box from '../../components/box/Box';
import Typography from '../../components/typography/Typography';
import theme from '../../theme/Theme';
import Container from '../../components/container/Container';
import classes from './ContactSection.module.scss';
import Spacing from '../../styles/spacing/Spacing';
import Markdown from 'markdown-to-jsx';
import Image from '../../components/image/Image';
import { getImageAlt } from '../../../shared/utility';

type HalfWidthContactSectionProps = {
  tag?: string;
  tagVariant?: string;
  tagWeight?: string;
  tagColor?: string;
  title?: string | React.ReactNode;
  titleVariant?: string;
  titleWeight?: string;
  titleColor?: string;
  text?: string | React.ReactNode;
  textVariant?: string;
  textWeight?: string;
  textColor?: string;
  image?: any;
  background?: string;
  padding?: string;
  reverse?: boolean;
  reverseMobile?: boolean;
  isMobile?: boolean;
};

const HalfWidthContactSection: React.FC<HalfWidthContactSectionProps> = ({
  tag,
  tagVariant,
  tagColor,
  tagWeight,
  title,
  titleVariant,
  titleColor,
  titleWeight,
  text,
  textVariant,
  textColor,
  textWeight,
  image,
  background,
  padding,
  isMobile,
  reverse,
  reverseMobile,
}) => {
  const getContent = () => {
    let ImagePropsObject;
    if (image)
      return (
        <Image
          url={image}
          alt={getImageAlt(image) || 'imageAlt'}
          className={classes['rb-image']}
          //width={480}
          //height={contentType === 'userReview' ? 500 : 380}
          {...ImagePropsObject}
        />
      );
  };
  return (
    <div
      style={{
        background: background || null,
      }}
    >
      <Container
        type="large"
        style={{ padding: padding && padding }}
        className={classes['rb-root']}
      >
        <Box
          display="flex"
          className={classes['rb-main-grid']}
          gap={!isMobile && 64}
          flexDirection={
            isMobile
              ? reverseMobile
                ? 'column-reverse'
                : 'column'
              : reverse
              ? 'row-reverse'
              : 'row'
          }
        >
          <Box className={classes['rb-info-grid']}>
            <Box display="flex" flexDirection={'column'} gap={8}>
              {tag && (
                <Typography
                  variant={tagVariant || 'textMd'}
                  weight={tagWeight || 'bold'}
                  color={tagColor || theme.palette.primary.blue}
                  align={isMobile ? 'center' : 'left'}
                >
                  {tag}
                </Typography>
              )}
              {title && (
                <Typography
                  variant={titleVariant || 'displayMd'}
                  weight={titleWeight || 'bold'}
                  color={titleColor || theme.palette.primary.navy}
                  align={isMobile ? 'center' : 'left'}
                  style={{ display: isMobile && 'block', textWrap: !isMobile ? 'nowrap' : 'wrap' }}
                >
                  {title}
                </Typography>
              )}
            </Box>
            <Box>
              {text && (
                <>
                  <Spacing variant="betweenHeaderToSubheader" />
                  <Typography
                    variant={textVariant || 'textMd'}
                    weight={textWeight || 'regular'}
                    color={textColor || theme.palette.input.body}
                    align={isMobile ? 'center' : 'left'}
                  >
                    {typeof text === 'string' ? (
                      <Box display="flex" justifyContent={isMobile ? 'center' : 'left'}>
                        {' '}
                        <Markdown children={text} />{' '}
                      </Box>
                    ) : (
                      text
                    )}
                  </Typography>
                </>
              )}
            </Box>
          </Box>
          <Box className={classes['rb-content-grid']}>{getContent()}</Box>
        </Box>
      </Container>
    </div>
  );
};

export default HalfWidthContactSection;
