import { datadogLogs, Logger } from "@datadog/browser-logs";

type BrowserLoggerProperties = Record<string, any> & {
    action_from: string;            // component name from where the action is triggered
    cus_email?: string;             // required login, signup, forgot password events
    operation?: string;             // type of action. ex: LOGIN, SIGNUP, FORGOT_PASSWORD, LOGOUT, etc
    function_name?: string;         // function name from where the action is triggered
    transaction_unique_id?: string;
    cus_unique_id?: string;
}

type BrowserLoggerUser = {
    id?: string;
    email?: string;
    cus_unique_id?: string;
    cus_email?: string;
    business_unique_id?: string;
}

export class BrowserLogger {
    private static logger: Logger;

    private static init() {
        if (!datadogLogs.getInitConfiguration()) {
            datadogLogs.init({
                clientToken: process.env.DATADOG_CLIENT_TOKEN,
                forwardErrorsToLogs: true,
                site: 'datadoghq.com',
                sessionSampleRate: 100,
                forwardConsoleLogs: 'all',
                service: 'remitbee-cp',
                env: process.env.REACT_APP_ENV,
                version: '2.0',
                storeContextsAcrossPages: true,
                beforeSend: (event) => {
                    const excludedOrigins = ['console', 'network'];
                    if (excludedOrigins.includes(event?.origin)) return false;
                    if (event?.message === 'Network Error') return false;

                    if (process.env.REACT_APP_ENV !== 'prod') {
                        console.log(event);
                        return false;
                    }

                    return true;
                },
            });
        }
        this.logger ??= datadogLogs.logger;
    }

    static setUser(user?: BrowserLoggerUser) {
        this.init();

        if (user) datadogLogs.setUser({
            id: user.id ?? user.cus_unique_id,
            email: user.email ?? user.cus_email,
            business_unique_id: user.business_unique_id,
        });
        else datadogLogs.clearUser();
    }

    static setBusinessUniqueId(uniqueId?: string) {
        this.init();

        if (uniqueId) datadogLogs.setUserProperty('business_unique_id', uniqueId);
        else datadogLogs.setUserProperty('business_unique_id', null);
    }

    static debug(message: string, properties: BrowserLoggerProperties) {
        this.init();

        const props = this.loggerBuilder(properties);
        if (this.logger) this.logger.debug(message, props);
        else console.log(message, props);
    }

    static log(message: string, properties: BrowserLoggerProperties) {
        this.init();

        const props = this.loggerBuilder(properties);
        if (this.logger) this.logger.info(message, props);
        else console.log(message, props);
    }

    static error(errorOrMsg: Error | string, properties: BrowserLoggerProperties) {
        this.init();

        const error = errorOrMsg instanceof Error ? errorOrMsg : new Error(errorOrMsg);
        const props = this.loggerBuilder(properties);

        if (this.logger) this.logger.error(error?.message, props, error);
        else console.log(error.message, props);
    }

    private static loggerBuilder(properties: BrowserLoggerProperties) {
        const { cus_unique_id, cus_email, operation, action_from, function_name, ...rest } = properties;
        // set user properties if available
        if (cus_unique_id) datadogLogs.setUserProperty('id', cus_unique_id);
        if (cus_email) datadogLogs.setUserProperty('email', cus_email);

        return {
            meta: {
                operation,
                ...rest
            },
            attributes: {
                action_from,
                function_name,
            },
        }
    }
}

export enum BrowserLoggerOperation {
    LOGIN = 'LOGIN',
    LOGIN_VERIFICARION = 'LOGIN_VERIFICARION',
    SIGNUP_EMAIL = 'SIGNUP_EMAIL',
    SIGNUP_PHONE = 'SIGNUP_PHONE',
    FORGOT_PASSWORD = 'FORGOT_PASSWORD',
    SEND_MONEY = 'SEND_MONEY',
    CURRENCY_EXCHANGE = 'CURRENCY_EXCHANGE',
    ITB = 'ITB',
    SWITCH_ACCOUNT_TYPE = 'SWITCH_ACCOUNT_TYPE',
}