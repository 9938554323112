import React, { useEffect, useState } from 'react';
import Typography from '../../typography/Typography';
import Box from '../../box/Box';
import Flag from '../../flag/Flag';
import Input from '../Input';
import Dropdown from '../../dropDown/DropDown';
import classes from './../Input.module.scss';
import DropDownItem from '../../dropDown/DropDownItem';

const CANADA = {
    countryCode: 'CA',
    callingCode: 1,
    countryId: 40
}

const PhoneNumberInput: React.FC<{
    list?: any[];
    id?: string;
    countryCode?: string;
    phoneNumber?: string;
    codeList?: any[];
    onCountryCodeChange?: (value: string) => void | any;
    onPhoneNumberChange?: any;
    label?: string;
    required?: boolean;
    placeholder?: string;
    error?: string;
    /**
     * Include Canada to the code list 
     */
    includeDefault?: boolean;
    disabled? : boolean;
    defaultPhoneCode?: number | string;
    selectable?: boolean;
    withOutDropdown?: boolean;
    codeWidth?: number;
}> = ({
    id,
    // countryCode,
    phoneNumber,
    codeList,
    onCountryCodeChange,
    onPhoneNumberChange,
    label,
    required,
    placeholder,
    includeDefault = true,
    defaultPhoneCode,
    error,
    disabled,
    selectable,
    codeWidth,
    withOutDropdown
}) => {
    const [list, setList] = useState([]);
    const [selectedCode, setSelectedCode] = useState(defaultPhoneCode);

    useEffect(() => {
        setSelectedCode(defaultPhoneCode);
    }, [defaultPhoneCode]);

    const renderList = () => {
        let itemsFromList = codeList;

        if (codeList && codeList.length) {
            if (includeDefault && !codeList.find(c => c.countryCode === CANADA.countryCode))
                itemsFromList.push(CANADA);
        }

        setList(itemsFromList);
    }

    const listItem = (item: any) => {
        if (!item) return
        return (
            <DropDownItem key={item.countryId} onClick={() => handleCodeClick(item)}>
                <Box display='flex'>
                    <Flag code={item.countryCode} className={classes['rb-input-phonenumber-flag']} />
                    <div id={item.callingCode}>{`+${item.callingCode}`} </div>
                </Box>
            </DropDownItem>
        )
    }

    useEffect(() => {
        renderList();
    }, [selectedCode, codeList.length]);

    const handleCodeClick = (code: any) => {
        if (code && code.countryId && onCountryCodeChange) onCountryCodeChange(code.countryId);
    }

    const handlePhoneNumberChange = (event: any) => {
        onPhoneNumberChange(event);
    };

    return (
        <>
            {label && <Typography variant='body2' className={classes['rb-input-label']}>
                <Box display='flex'>
                    <Box flexGrow={1}>
                        {label}
                        {required && <span className={classes['rb-input-required']} />}
                    </Box>
                </Box>
            </Typography>}

            <Box display='flex' className={classes['rb-input-phonenumber-container']}>
                <Box
                    className={withOutDropdown ? classes['rb-input-phonenumber-selector-without-dropdown'] : classes['rb-input-phonenumber-selector']}
                    style={codeWidth ? { width: `${codeWidth}px` } : null}>
                    <Dropdown
                        withOutDropdown={withOutDropdown}
                        disabled={disabled}
                        list={list && list.length ? list.map(item => listItem(item)) : []}
                        selectedItem={list && list.length ? listItem(list.find(l => l.countryId === selectedCode)) : null}
                        selectable={selectable}
                        error={error}
                        displayErrorMessage={false}
                        dropdownPosition='static'
                    />
                </Box>
                <Input
                    fullWidth
                    id={id}
                    disabled={disabled}
                    placeholder={placeholder}
                    onChange={handlePhoneNumberChange}
                    value={phoneNumber}
                    className={error ? `${classes['rb-input-error']}
                     ${classes['rb-input-phonenumber']}` : classes['rb-input-phonenumber']}
                    type = 'tel'
                    format='###############' 
                />
            </Box>
            {error && <Typography id='error-text' variant='body3' className={classes['rb-input-errorText']}>{error}</Typography>}
        </>
    );
};

export default PhoneNumberInput;
