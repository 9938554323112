import { NextSeo } from 'next-seo';
import React, { useEffect, useState } from 'react';
import { TEMPLATES_QUERY } from "../src/components/landingPage/apollo/queries/templates/template";
import { strapiQuery } from '../src/settings/apollo/strapi/query';
import DynamicContent from '../src/components/landingPage/components/templates/DynamicContent';
import { setClientCookieInfo } from '../src/shared/cookie-handler';
import ErrorPage from './_error';
import { fetchSystemCountries } from '../src/settings/cms-data';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { publicPage } from '../src/shared/auth';
import { parseArrFromString } from '../src/shared/utility';
import { useDispatch } from 'react-redux';
import { setCountries } from '../src/redux/system/actions';

const CANADA_CURRENCY_NAME = "Canadian Dollar";

const DynamicPage = ({ query, data, slug, lang, error, isMobileServer, statusCode }) => {
  const dispatch = useDispatch();
  const [countryList, setCountryList] = useState([]);

  useEffect(() => {
    const fecthCountries = async () => {
      const systemCountries = await fetchSystemCountries();
      if (systemCountries) {
        dispatch(setCountries(systemCountries.rates));
        setCountryList((systemCountries.rates));
      }
    }

    fecthCountries();
  }, [query]);

  if (error) {
    const pageNotFoundSeo = {
      title: "Page Not Found | Remitbee",
      description: "We are sorry - this page doesn't exist"
    }
    return (
      <>
        <NextSeo {...pageNotFoundSeo} />
        <ErrorPage isMobileServer={isMobileServer} statusCode={statusCode} />
      </>
    );
  }

  if (!data) return null;
  const { path, content } = data;
  // const countriesData = useSelector(state => state.system);
  // let countries = countriesData?.countries || null;
  let countries = countryList;

  if (typeof window !== 'undefined' && query) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const [utm_source, utm_campaign] = [urlParams.get('utm_source'), urlParams.get('utm_campaign')];
    utm_source && setClientCookieInfo('utm_source', utm_source, 7)
    utm_campaign && setClientCookieInfo('utm_campaign', utm_campaign, 7)
  }

  const dynamicContent = content
  const seo = content.includes("Seo") ? content.match("<Seo(.*)>")[0] : null;

  const start_one = dynamicContent.indexOf('languages=');
  const substr = dynamicContent.substr(start_one);

  const end_one = substr.indexOf('/');
  const part_one = substr.substring(0, end_one);

  const start_two = part_one.indexOf('"');
  const substr_two = part_one.substring(start_two + 1);

  const end_two = substr_two.indexOf('"');
  const final = substr_two.substring(0, end_two);

  const enabled_lang = parseArrFromString(final).map(lang => lang.language);
  const defaultSeo = getDefaultSeo({ path, lang, enabled_lang, countries, query, seo });
  return (
    <>
      <NextSeo {...defaultSeo} />
      <DynamicContent data={{ ...data, lang, slug, query, countries }} isMobileServer={isMobileServer} />
    </>
  )
}

function getDefaultSeo(props) {
  const { path, lang, countries, query, seo } = props;
  const basePath = 'https://www.remitbee.com';
  const selectedLang = lang !== 'en' ? `/${lang}` : '';
  let canonical = path !== 'index' ? `${basePath}${selectedLang}/${path}` : `${basePath}${selectedLang}`;
  let title = seo ? seo.match(/title=\"(.*?)\"/)[1] : null;
  let description = seo ? seo.match(/description=\"(.*?)\"/)[1] : null;
  if (query && query.amount && countries && countries.length) {
    if (path !== "currency-converter" && path.includes("currency-converter") && !path.includes("currency-converter-")) {
      const fromCurrencyCode = path.substring(27, 30).toUpperCase();
      const toCurrencyCode = path.substring(34, 37).toUpperCase();
      const fromCountryCurrency = fromCurrencyCode === 'CAD' ? CANADA_CURRENCY_NAME : countries.find((value) => value.currency_code === fromCurrencyCode)?.currency_name;
      const toCountryCurrency = toCurrencyCode === 'CAD' ? CANADA_CURRENCY_NAME : countries.find((value) => value.currency_code === toCurrencyCode)?.currency_name;
      title = `${query.amount ? query.amount + ' ' : ''} ${fromCountryCurrency} to ${toCountryCurrency} Exchange Rate`;
      description = `Convert ${query.amount} CAD to ${toCurrencyCode} instantly with our Remitbee Currency Converter. Track historical exchange rates, see live CAD/${toCurrencyCode} charts, and get free international money transfers for over 100 currencies`;
      canonical = `${basePath}/${path}?amount=${query.amount}`
    } else if (path !== "currency-exchange" && path.includes("currency-exchange") && !path.includes("currency-exchange-") && !path.includes("-currency-exchange")) {
      const pathSplit = path.split('-');
      const fromCurrencyCode = pathSplit[2] && pathSplit[2] ? pathSplit[2].toUpperCase() : '';
      const toCurrencyCode = pathSplit[4] && pathSplit[4] ? pathSplit[4].toUpperCase() : '';
      const exchange = path.includes('cad-to-usd') ? 'Canadian Dollar to Us Dollar' : 'US Dollar to Canadian Dollar';
      title = `Exchange ${query.amount ? query.amount + ' ' : ''}${fromCurrencyCode} to ${toCurrencyCode} | ${exchange} exchange rate | Remitbee`;
      description = `Exchange ${query.amount ? query.amount + ' ' : ''}with Remitbee currency exchange. We offer best exchange rate with zero fees. Much cheaper than banks and any other currency exchange stores in Canada.`;
      canonical = `${basePath}/${path}?amount=${query.amount}`
    }
  }
  let defaultSeo = {
    canonical,
    title,
    description,
    openGraph: {
      type: 'website',
      locale: 'en_CA',
      url: canonical,
      site_name: 'Remitbee',
      images: [
        {
          url: `${basePath}/new/facebook-banner-white.png`,
          width: 1200,
          height: 630,
          alt: 'Remitbee Banner',
        },
      ]
    },
    twitter: {
      handle: '@remitbee',
      site: '@remitbee',
      cardType: 'summary_large_image',
    },
    additionalMetaTags: [
      {
        property: 'twitter:account_id',
        content: '3066049227'
      },
    ]
  };

  return defaultSeo;
}

export async function getServerSideProps(ctx) {
  const { req, params, query, locale, res, resolvedUrl } = ctx || {};

  const userAgent = req.headers['user-agent'];
  const isMobileServer = /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile/.test(userAgent);

  res.setHeader(
    'Cache-Control',
    'public, max-age=86400, stale-while-revalidate=60'
  )

  let { slug } = params || {};
  if (!slug) slug = "index";

  let path = '';
  let lang = locale || 'en';

  if (Array.isArray(slug)) {
    if (slug[0] === lang) {
      slug.shift();
    }
    path = slug.join("/");
  } else {
    path = slug;
  }

  const reqParams = { path, lang, published: !resolvedUrl?.startsWith('/test/') };

  const response = await strapiQuery(TEMPLATES_QUERY, reqParams);

  if (!response || !response.data || !response.data.pageByPath) {
    return {
      props: {
        error: 'Page not found',
        statusCode: 404,
        ...(await serverSideTranslations(lang, ['error', 'landing']))
      }
    };
  }

  const data = {
    ...response.data.pageByPath,
    path
  }

  // Check fisrt if the content has currencies to be replaced, like {{CUR}}
  let currencyMatch = data?.content?.match(/({{[A-Za-z]{3}}})/g);
  if (currencyMatch && currencyMatch.length) {
    // If yes, download server side info
    const systemCountries = await fetchSystemCountries();
    if (systemCountries?.rates && systemCountries?.rates.length) {
      currencyMatch?.forEach(currencyData => {
        const currency = currencyData.match(/[A-Za-z]{3}/)[0].toUpperCase();
        const transferRate = systemCountries?.rates.find(c => c.currency_code === currency);
        data.content = data.content.replace(/({{[A-Za-z]{3}}})/, transferRate?.rate);
        currencyMatch = data.content.match(/({{[A-Za-z]{3}}})/);
      })
    }
  }

  // Replace current date if variable is available
  if (data?.content?.match(/\{\{today_date\}\}/)) {
    const date = new Date();
    const todayDate = `${(date.getDate() < 10 ? '0' : '') + date.getDate()}/${(date.getMonth() + 1 < 10 ? '0' : '') + (date.getMonth() + 1)}/${date.getFullYear()}`;
    
    data.content = data.content.replace(/\{\{today_date\}\}/, todayDate);
  }

  return {
    props: {
      query: query || {},
      data,
      slug,
      lang,
      isMobileServer,
      ...(await serverSideTranslations(lang, ['landing'])),
    }
  }
}

export default publicPage(DynamicPage);