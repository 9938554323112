import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import theme from '../../remitbee/theme/Theme';
import classes from './ErrorSplash.module.scss';
import { useTranslation } from 'next-i18next';
import Typography from '../../remitbee/components/typography/Typography';
import Button from '../../remitbee/components/button/Button';
import ArrowLeftLined from '../../remitbee/icons/ArrowLeftLined';

const ErrorSplash = ({ statusCode }) => {
  const router = useRouter();
  const { t } = useTranslation('error');
  // Dont use i18n in default useState (incase i18n is not working, a default message should work)
  const [message, setMessage] = useState('Ooops! Something wrong with our server');
  const [submessage, setSubMessage] = useState('Sorry for the inconvenience. We will be back live shortly. In the meantime, you can download and use Remitbee mobile app on your phone.');

  const handleNavigation = () => {
    const isDashboard = router.asPath.includes('/dashboard');
    if(!isDashboard) router.push('/dashboard');
    setTimeout(() => {
      isDashboard && router.reload()
    }, 1500);
  }

  useEffect(() => {
    if (statusCode){
      setMessage(t(`error:${statusCode}_error_message`))
      setSubMessage(t(`error:${statusCode}_error_submessage`))
    }
  },[statusCode]);

  const googleFonts = () => (
    <div className={classes['hidden']}>
      {/* Google Fonts. We need it only for this page */}
      <link rel="preconnect" href="https://fonts.googleapis.com"/>
      <link rel="preconnect" href="https://fonts.gstatic.com"/>
      <link href="https://fonts.googleapis.com/css2?family=Sora:wght@100..800&display=swap" rel="stylesheet"></link>
    </div>
  )

  return (
    <>
      {googleFonts()}
      <div className={classes['rb-error-root']}>
        <div className={classes['rb-error-container']}>
          <h1 className={classes['rb-error-code']}>{statusCode}</h1>
          <div className={classes['rb-error-content']}>
            <div className={classes['rb-error-content-text']}>
              <Typography variant='displayLg' align='center' weight='bold' color={theme.palette.primary.navy}>
                {message}
              </Typography>
              <Typography variant='textLg' align='center'>
                {submessage}
              </Typography>
            </div>
            <div className={classes['rb-error-content-buttons']}>
              <Button id='error-back-button' onClick={()=> router.back()} variant='outlined' startIcon={<ArrowLeftLined width={20} height={20} color={theme.palette.primary.blue} />}>
                {t('error:go_back')}
              </Button>
              <Button id='error-remitbee-button' onClick={handleNavigation}>
                {t('error:go_remitbee')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ErrorSplash;