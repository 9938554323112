import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import Typography from '../../../../remitbee/components/typography/Typography';
import classnames from 'classnames';
import { useRouter } from 'next/router';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect } from 'react';
import { getImageAlt, parseArrFromString } from '../../../../shared/utility';
import { body1, container, mediumTitle } from '../../globalStyles';
import dynamic from 'next/dynamic'
import Tabs from '../../components/Tabs/Tabs';
import UserReviews from './UserReviews';
import ReviewBoxes from './ReviewBoxes';
import UserReviewsTabs from './UserReviewsTabs';
import PromoCodes from '../../sections/new/PromoCodes';
import Featured from '../../sections/new/Featured';
import HorizontalImageScroll from '../../sections/new/HorizontalImageScroll';
import MultiLists from '../../sections/new/MultiLists';
import ComparisonTable from '../../components/ComparisonTable/ComparisonTable';
import TeamMembers from '../../components/TeamMembers/TeamMembers';
import UserReviewsCards from './UserReviewsCards';
import UserSingleReview from './UserSingleReview';
import ReviewSlider from './ReviewSlider';
import VideoPopUp from '../../../common/VideoPopUp';
import DToneOperators from '../../components/GiftCardPublic/DToneOperators';
import { setRedirectSignupCookie } from '../../../../shared/cookie-handler';
import AppStore from '../../../../../public/design-system/landing/DownloadAppSection/AppStore.svg';
import PlayStore from '../../../../../public/design-system/landing/DownloadAppSection/PlayStore.svg';
import Button from '../../../../remitbee/components/button/Button';

import Reviews from '../../sections/new/Reviews';
import CurrencyExchangeTable from '../../sections/new/CurrencyExchangeTable';
import CustomGraph from '../../sections/new/CustomGraph';
import Quote from '../../sections/new/Quote';
import TagCards from '../../sections/new/TagCards';
import NumberedCards from '../../sections/new/NumberedCards';
import CheckCards from '../../sections/new/CheckCards';
import DynamicTable from '../../sections/new/DynamicTable';
import NumberedSteps from '../../sections/new/NumberedSteps';
import JobPositions from '../../sections/new/JobPositions';
import MainTable from '../MainTable';
import ColomnSlider from '../../sections/new/ColomnSlider';
import IconGrid from '../../sections/new/IconGrid';
import GSTCalculator from './FullWidthSection/GSTCalculator';

const styles = theme => ({
  container,
  mediumTitle,
  body1,
  bigTitle: {
    fontFamily: 'Inter',
    fontWeight: 600,
    lineHeight: '60px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '60px',
      lineHeight: '72px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '36px',
      lineHeight: '45px',
    },
  },
  root: {
    display: 'flex',
    padding: '80px 40px',
    [theme.breakpoints.down('sm')]: {
      padding: '60px 0',
      clipPath: 'none',
    },
  },
  root2: {
    display: 'flex',
    padding: '0 40px 80px 40px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 0 60px 0',
      clipPath: 'none',
    },
  },
  root3: {
    display: 'flex',
    padding: '80px 40px 0 40px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 0 60px 0',
      clipPath: 'none',
    },
  },
  noPadding: {
    padding: 0
  },
  stacked: {
    display: 'flex',
    paddingTop: '40px',
    paddingBottom: '50px',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '30px',
      paddingBottom: '20px',
      clipPath: 'none',
    },
  },
  textCenter: {
    textAlign: 'center',
  },
  title_h2: {
    fontSize: '30px !important'
  },
  title_h1: {
    fontSize: '40px !important',
    "@media (max-width: 959px)": {
      fontSize: '25px !important'
    },
  },
  image: {
    maxWidth: '100%',
    marginTop: theme.spacing(5)
  },
  widthset: {
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
  },
  spacing: {
    marginTop: '32px'
  },
  text: {
    fontFamily: 'Inter !important',
    paddingTop: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      fontSize: '18px',
      lineHeight: '34px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      lineHeight: '26px',
    },
    color: '#626680'
  },
  grey: {
    color: '#31517A !important'
  },
  white: {
    color: '#FFFFFF !important'
  },
  video: {
    width: '100%',
    height: '480px',
    marginTop: theme.spacing(5),
    borderRadius: '20px',
    borderStyle: 'solid',
    borderWidth: '0px',
  },
  videoDiv: {
    minWidth: '100%',
  },
  title : {
    paddingLeft: '24px',
    paddingRight: '24px',
  },
  topLogo: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    width: '100%',
    maxWidth: '360px',
  },
  iframe: {
    width: '100%',
    height: '480px',
    marginTop: theme.spacing(5),
    borderRadius: '20px',
    borderStyle: 'solid',
    borderWidth: '0px',
    [theme.breakpoints.down('md')]: {
      width: '90%',
      marginRight: 'auto',
      marginLeft: 'auto',
    },
    [theme.breakpoints.down('xs')]: {
      width: '40%',
      marginRight: 'auto',
      marginLeft: 'auto',
    },
  },
  iframeDiv: {
    // minWidth: '100%',
    display: 'flex',
    width: '1000px',
    [theme.breakpoints.down('sm')]: {
      width: '700px'
    },
  },
  videoZoom: {
    maxWidth: '100%',
    width: 'auto',
    marginTop: theme.spacing(5)
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  downLoad: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop:'32px',
  },
  marginTop: {
    marginTop: '10vh'
  },
  tabs: {
    width: '100%',
    marginTop: '12px'
  },
  textContentCentered: {
    margin: '0px 280px'
  }
});

function FullColumnSection(props) {
  const {
    classes,
    children,
    noPadding,
    title,
    titleVariant,
    titleColor,
    titleId,
    text,
    image,
    mobileimage,
    video,
    zoom,
    VideoProps,
    list,
    background,
    actionText,
    actionUrl,
    actionScroll,
    actionType,
    columns,
    color,
    backgroundColor,
    contentType,
    contentProps,
    alignLeft,
    imageAlt,
    data,
    promotion,
    stack,
    showVideo,
    tabs,
    partners,
    hideDesktop,
    leftList,
    rightList,
    listTitles,
    topLogo,
    actionWidth,
    showContentTop,
    competitionDate,
    secondaryMobileView,
    services,
    multiListsWithoutIcon,
    withOutTopMargin,
    withOutBottomMargin,
    reviewVideo,
    download, 
    secondaryActionText,
    secondaryActionUrl,
    secondaryActionScroll,
    secondaryActionType,
    withOutPadding = false,
    buttonTop=false
  } = props;
  const alt_tag = imageAlt || getImageAlt(image);
  const tabsContent = tabs ? parseArrFromString(tabs) : null;
  const partnersContent = partners ? parseArrFromString(partners) : null
  const content = contentProps ? parseArrFromString(contentProps)[0] : null;
  const tableColumns = columns ? parseArrFromString(columns) : null;
  const videoProps = VideoProps ? parseArrFromString(VideoProps)[0] : null;
  const listItems = list ? parseArrFromString(list) : null;
  const [height, setHeight] = useState('100%'); // eslint-disable-line no-unused-vars
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const hideDesktopData = !isMobile && hideDesktop ? hideDesktop : false;
  const hidePadding =  noPadding ? !isMobile : false;
  const videoRef = useRef();
  const containerRef = useRef();
  const router = useRouter();

  function getButtonStyle(input) {
    const styles = {
        green: "filledPrimary",
        white: "filledWhite"
    };
    return styles[input] || "filled";
  }
  const [buttonActionType, setButtonActionType] = React.useState(getButtonStyle(actionType));
  const [buttonSecondaryActionType, setButtonSecondaryActionType] = React.useState(getButtonStyle(secondaryActionType));
  React.useEffect(() => {
    setButtonActionType(getButtonStyle(actionType));
  },[actionType]);
  React.useEffect(() => {
    setButtonSecondaryActionType(getButtonStyle(secondaryActionType));
  },[secondaryActionType]);

  if (videoProps && videoProps.loop && typeof videoProps.loop === 'string') videoProps.loop = Boolean(videoProps.loop)

  useEffect(() => {
    if (zoom && videoRef && videoRef.current) {
      if (videoProps && videoProps.speed) videoRef.current.playbackRate = videoProps.speed;
      const value = containerRef.current.getBoundingClientRect().width * 65 / 96 * zoom;
      setHeight(`${value}px`);
    }
  }, [videoRef])

  const getContent = (type, content) => {
    switch (type) {
      case 'Reviews': return (<Reviews />)
      case 'Tabs': return (<Tabs data={listItems} fullWidth />)
      case 'CurrencyExchangeTable': return (<CurrencyExchangeTable {...content} />)
      case 'DynamicTable': return (<DynamicTable {...content} columns={tableColumns} />)
      case 'CustomGraph': return (<CustomGraph {...content} list={listItems} />)
      case 'Quote': return (<Quote {...content} />)
      case 'TagCards': return (<TagCards list={listItems} paddingTop={title} />)
      case 'NumberedCards': return (<NumberedCards list={listItems} />)
      case 'PerksStep': return (<NumberedCards contentType={contentType} list={listItems} />)
      case 'CheckCards': return (<CheckCards list={listItems} />)
      case 'NumberedSteps': return (<NumberedSteps list={listItems} />)
      case 'JobPositions': return (<JobPositions list={listItems} manualAddJobItems={listItems} />)
      case 'UserReviews': return (<UserReviews {...content} reviews={listItems} />)
      case 'UserReviewsTabs': return (<UserReviewsTabs reviews={listItems} />)
      case 'UserReviewsCards': return (<UserReviewsCards reviews={listItems} />)
      case 'UserSingleReview': return (<UserSingleReview {...content} video={reviewVideo} videoProps={videoProps} showVideo={showVideo} zoom={zoom} videoRef={videoRef} />)
      case 'ReviewSlider': return (<ReviewSlider list={listItems} />)
      case 'ReviewBoxes': return (<ReviewBoxes {...content} />)
      case 'PromoCodes': return (<PromoCodes list={listItems} />)
      case 'Featured': return (<Featured list={listItems} secondaryMobileView={secondaryMobileView} />)
      case 'HorizontalImageScroll': return (<HorizontalImageScroll list={listItems} />)
      case 'ReferralCompetition': return (<MainTable competition competitionDate={competitionDate} paddingTop {...content} />)
      case 'ComparisonTable': return (<ComparisonTable data={listItems} {...content} />)
      case 'TeamMembers': return (<TeamMembers list={listItems} />)
      case 'ColomnSlider': return (<ColomnSlider list={listItems} />)
      case 'VideoPopUp': return (<VideoPopUp video={video} />)
      case 'IconGrid': return (<IconGrid list={listItems} />)
      case 'Calculator': return (<GSTCalculator provinces={listItems} />)
      case 'MultiLists': return (<MultiLists withOutTopMargin={withOutTopMargin} withOutBottomMargin={withOutBottomMargin} leftList={leftList} rightList={rightList} listTitles={listTitles} multiListsWithoutIcon={multiListsWithoutIcon} />)
      case 'DToneOperators': return (<DToneOperators services={services} />)
    }
  }

  const goToUrl = async () => {
    let query = null;
    if (typeof window === 'object' && actionScroll) {
      const section = document.querySelector(`#${actionScroll}`);
      if (section && typeof section === 'object') section?.scrollIntoView({ behavior: 'smooth' });
      return
    }
    if (actionUrl && actionUrl.includes('signup')) {
      await setRedirectSignupCookie(router.asPath)
    }
    if (actionUrl && actionUrl.includes('signup?')) {
      router.push(actionUrl);
      return
    }
    if (promotion) {
      query = addReferralLink()
    }
    router.push({ pathname: actionUrl, query });
  }

  const addReferralLink = () => {
    let _args = {}
    _args = { promo: promotion };
    return _args;
  }

  const goToUrl2 = async () => {
    let query = null;
    if (typeof window === 'object' && secondaryActionScroll) {
      const section = document.querySelector(`#${secondaryActionScroll}`);
      if (section && typeof section === 'object') section?.scrollIntoView({ behavior: 'smooth' });
      return
    }
    if (secondaryActionUrl && secondaryActionUrl.includes('signup')) {
      await setRedirectSignupCookie(router.asPath)
    }
    if (secondaryActionUrl && secondaryActionUrl.includes('signup?')) {
      router.push(secondaryActionUrl);
      return
    }
    if (promotion) {
      query = addReferralLink()
    }
    router.push({ pathname: secondaryActionUrl, query });
  }

  return (
    <section id={titleId || null} className={classnames((stack) ? classes.stacked : withOutTopMargin ? classes.root2 : withOutBottomMargin ? classes.root3 : classes.root, !alignLeft && classes.textCenter, noPadding && classes.noPadding, contentType === 'Calculator' ? classes.marginTop : '')}
      style={{ background: background ? `url('${background}') center no-repeat` : backgroundColor, backgroundSize: background ? 'cover' : null }}>
      {topLogo && <img src={topLogo} alt={topLogo || 'logo'} className={classes.topLogo} />}
      {contentType && isMobile && showContentTop && getContent(contentType, content, data)}
      <div className={withOutPadding ? classes.title : !hidePadding && classes.container}>
        <Grid container justifyContent={alignLeft ? 'left' : 'center'} className={stack && classes.widthset}>
           <Grid item xs={12}>
           {!hideDesktopData && title &&<Typography component="div" align='center' variant={titleVariant ? titleVariant : 'h1'} style={{color:titleColor&& titleColor}} className={classes.bigTitle}>
              {title}
            </Typography>}
            {!hideDesktopData && text && <Typography component="div" align='center' variant="body1" marked="center" className={classnames(classes.text, classes[color])}>
              {text}
            </Typography>}
            <div style={{display: !isMobile ? 'flex' :"", justifyContent:'center'}}>
            {actionText && buttonTop &&
            <Grid item xs={12} md={4} className={classes.spacing}>
              <Button type={buttonActionType} onClick={goToUrl} style={{ width: actionWidth, height: '48px' }}>{actionText}</Button>
            </Grid>}
            {secondaryActionText && buttonTop &&
            <Grid item xs={12} md={4} className={classes.spacing}>
              <Button type={buttonSecondaryActionType} onClick={goToUrl2} style={{ width: actionWidth, height: '48px' }}>{secondaryActionText}</Button>
            </Grid>}
            </div>
          </Grid>
          {tabsContent &&
            <div className={classes.tabs}>
              <Tabs data={tabsContent} partners={partnersContent} fullWidth contentType="cards" />
            </div>
          }
          {
            download && <div className={classes.downLoad}>
              <a target="_blanc" href="https://apps.apple.com/ca/app/remitbee-money-transfer/id983795500">
                <img
                  src={AppStore}
                  alt="App store icon"
                  width={'100%'}
                  height={'100%'}
                  loading="lazy"
                />
              </a>
              <a target="_blanc" href="https://play.google.com/store/apps/details?id=com.remitbee.app.app&hl=en_CA">
                <img
                  src={PlayStore}
                  alt="Play store icon"
                  width={'100%'}
                  height={'100%'}
                  loading="lazy"
                />
              </a>
            </div>
          }
          {(image || mobileimage)&& <Grid item xs={12} className={classes.featuresBox}>
            <div className={classes.item}>
              <img
                className={classes.image}
                src={(isMobile && mobileimage) ? mobileimage : image}
                alt={alt_tag || 'image'}
                loading="lazy"
              />
            </div>
          </Grid>}
          {video && showVideo &&
            <Grid item className={null} ref={containerRef}>
              {video.includes('youtube.com/embed/') ?
                <div className={classes.iframeDiv}>
                  <iframe {...videoProps} src={video} loading="eager" className={classes.iframe} allowFullScreen />
                </div>
                :
                <div className={zoom ? classes.wrapper : classes.videoDiv}>
                  {/* <video {...videoProps} style={zoom ? { transform: `scale(${zoom})` } : null} src={video} preload="auto" className={zoom ? classes.videoZoom : classes.video} muted ref={videoRef} /> ÷ */}
                  <video muted {...videoProps} style={zoom ? { transform: `scale(${zoom})` } : null} src={video} preload="auto" controlsList='nodownload' className={zoom ? classes.videoZoom : classes.video} controls ref={videoRef} />
                </div>
              }
            </Grid>
          }
          {contentType && (!isMobile || !showContentTop) && getContent(contentType, content, data)}
          {actionText && !buttonTop &&
            <Grid item xs={12} md={4} className={classes.spacing}>
              <Button type={buttonActionType} onClick={goToUrl} style={{ width: actionWidth, height: '48px' }}>{actionText}</Button>
            </Grid>}
          {children}
        </Grid>
      </div>
    </section>
  );
}

FullColumnSection.propTypes = {
  actionText: PropTypes.string,
  actionUrl: PropTypes.string,
  actionType: PropTypes.string,
  title: PropTypes.any,
  image: PropTypes.string,
  typography: PropTypes.string,
  text: PropTypes.any,
  background: PropTypes.string,
  color: PropTypes.string,
  content: PropTypes.any,
  backgroundColor: PropTypes.string,
  contentType: PropTypes.string,
  stack: PropTypes.bool
};

export default withStyles(styles)(FullColumnSection);
