import { Box } from '@mui/material';
import { Grid } from '@mui/material';
import { Typography } from '@mui/material';
import React from 'react';
import { withStyles } from '@mui/styles';
import Markdown from 'markdown-to-jsx';

const styles = theme => ({
  title: {
    width: '100%',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
    fontWeight: 500,
    textAlign: 'center',
    fontFamily: 'Poppins',
    [theme.breakpoints.up('sm')]: {
      fontSize: '22px',
      lineHeight: '32px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
      lineHeight: '26px',
    }
  },
  color: {
    width: '30px',
    height: '30px',
    borderRadius: '16px',
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1)
  },
  text: {
    color: '#626680',
    fontWeight: 400,
    textAlign: 'left',
    [theme.breakpoints.up('sm')]: {
      fontSize: '18px',
      lineHeight: '34px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      lineHeight: '26px',
    }
  },
  imageContainer: {
    marginTop: theme.spacing(5)
  },
  image: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%'
    }
  }
});

const CustomGraph = ({ title, list, image, imageAlt, classes }) => {
  return (
    <>
      <Typography variant='h3' className={classes.title}>{title}</Typography>
      <Grid container spacing={3}>
        {list && list.map((item, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Box display='flex'>
              <Box>
                <div className={classes.color} style={{ backgroundColor: item.color }}></div>
              </Box>
              <Box flexGrow={1}>
                <Typography className={classes.text}><Markdown children={item.text} /></Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
      {image && <div className={classes.imageContainer}>
        <img src={image} alt={imageAlt || 'imageALt'} className={classes.image} loading="lazy" />
      </div>}
    </>
  )
}

export default withStyles(styles)(CustomGraph);
