import {
    arrayOf,
    node, oneOfType, string
} from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserLogger } from '../../../settings/browser-logger-v2';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    let cus_unique_id = null;
    const { customer } = this.props;

    if (customer) ({ cus_unique_id } = customer);
    BrowserLogger.error(error, { action_from: 'ErrorBoundary.componentDidCatch', info, cus_unique_id });
  }

  render() {
    const { children } = this.props;

    // if (hasError) {
    //   return <Error status={500} />;
    // }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: oneOfType([
    arrayOf(node),
    node,
    string,
  ]),
};

ErrorBoundary.defaultProps = {
  children: null,
};

const mapStateToProps = (state) => {
  const { customer } = state;
  return ({ customer })
}

export default connect(mapStateToProps)(ErrorBoundary);
