import React from 'react'
import Container from '../../../../../remitbee/components/container/Container'
import { useMediaQuery } from '@mui/material'
import theme from '../../../../../remitbee/theme/Theme'
import classes from './ColumnSection.module.scss'
import Box from '../../../../../remitbee/components/box/Box'
import Typography from '../../../../../remitbee/components/typography/Typography'
import Markdown from 'markdown-to-jsx'
import Spacing from '../../../../../remitbee/styles/spacing/Spacing'
import Button from '../../../../../remitbee/components/button/Button'
import { useRouter } from 'next/router'
import TopCurrencyPairs from '../FullWidthSection/TopCurrencyPairs'
import CustomBoxList from './CustomBoxList'
import ImageList from './ImageList'
import TransferLimitsCard from '../FullWidthSection/TransferLimitsCard'
import Rating from './Rating'
import SectionCard from './SectionCard'
import { parseArrFromString } from '../../../../../shared/utility'

interface CardListProp {
  cardTitle?: string
  cardText?: string
  cardIconPosition?: string
  cardBackGroundColor?: string
  cardBorderColor?: string
  cardBadge?: string
  cardButtonAction?: string
  cardButtonText?: string
  cardIcon?: string
}

interface ColumnSectionProps {
  backgroundColor?: string
  mobileRootPadding?: string
  rootPadding?: string
  title?: string
  titleVariant?: string
  titleWeight?: string
  titleColor?: string
  titleAlign?: string
  subTitle?: string
  subTitleVariant?: string
  subTitleWeight?: string
  subTitleColor?: string
  subTitleAlign?: string
  contentType?: 'cards' | 'countryBoxList' | 'customBoxList' | 'logos' | 'currencyPairs' | 'verificationLevels' | 'rating'
  primaryButtonAction?: string;
  primaryButtonText?: string;
  primaryButtonVariant?: string;
  secondaryButtonAction?: string;
  secondaryButtonText?: string;
  secondaryButtonVariant?: string;
  currencies?: any;
  storyBookMockData?: any
  countries?: any
  logos?: any
  logosPerRow?: string
  customBoxList?: any
  cardType?: 'Regular' | 'BankLogo' | 'Outline' | 'GrayBG' | 'CircleIcon' | 'PlainIcon' | 'BulletPoints' | 'Unboxed01' | 'Unboxed02' | 'PromoCard'
  cardGrid?: string
  cardList?: CardListProp[]
  cardGridGap?: string
}

const ColumnSection:React.FC<ColumnSectionProps> = ({
  backgroundColor,
  mobileRootPadding,
  rootPadding,
  title,
  titleVariant = 'displayXl',
  titleWeight,
  titleColor,
  titleAlign,
  subTitle,
  subTitleVariant = 'textLg',
  subTitleWeight = 'regular',
  subTitleColor = theme.palette.input.body,
  subTitleAlign,
  contentType,
  primaryButtonAction,
  primaryButtonText,
  primaryButtonVariant,
  secondaryButtonAction,
  secondaryButtonText,
  secondaryButtonVariant,
  currencies,
  countries,
  logos,
  logosPerRow,
  customBoxList,
  storyBookMockData,
  cardType, 
  cardGrid, 
  cardList,
  cardGridGap
}) => {
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.lg}px)`);
  const router = useRouter();
  const formattedCardList = typeof cardList === 'string' ? parseArrFromString(cardList) : cardList;
  const formattedCustomBoxList = typeof customBoxList === 'string' ? parseArrFromString(customBoxList) : customBoxList;
  const formattedCurrencies = typeof currencies === 'string' ? parseArrFromString(currencies) : currencies;
  const formattedLogos = typeof logos === 'string' ? parseArrFromString(logos) : logos;
  const containerPadding = { padding: isMobile && mobileRootPadding || rootPadding || '' }

  const cardProps = { cardType, cardGrid, cardGridGap, cardList: formattedCardList }

  const getComponents = () => {
    switch (contentType) {
      case 'cards':
        return <SectionCard {...cardProps}/>
      case 'customBoxList':
        return <CustomBoxList list={formattedCustomBoxList} type={contentType} actionsAvailable={primaryButtonAction || secondaryButtonAction} /> 
      case 'countryBoxList':
        return <CustomBoxList list={countries} type={contentType} actionsAvailable={primaryButtonAction || secondaryButtonAction} />
      case 'logos':
        return <ImageList logosPerRow={logosPerRow} logos={formattedLogos} />
      case 'currencyPairs':
        return <TopCurrencyPairs currencyPairsList={formattedCurrencies} storyBookMockData={storyBookMockData}  component={'column'} />
      case 'verificationLevels':
        return <TransferLimitsCard />
      case 'rating':
        return <Rating />  
    }
  }

  return (
    <div style={{
      backgroundColor: backgroundColor || null,
    }}
    >
      <Container
         type="large"
         style={containerPadding}
         className={classes['rb-root']}
      >
        <Box display='flex' flexDirection='column' gap={isMobile ? 40 : 48}>

          {(title || subTitle) && <Box className={classes['rb-subTitle-container']}>
            {title && <Typography variant={titleVariant} weight={titleWeight || 'bold'} color={titleColor || theme.palette.primary.navy} align={titleAlign || 'center'}>
               {typeof title !== 'string' ? title : <Markdown>{title}</Markdown>}
            </Typography>}
            {subTitle && <Spacing variant='titleToDivider' />}
            {subTitle && <Typography variant={subTitleVariant} weight={subTitleWeight || 'bold'} color={subTitleColor || theme.palette.primary.navy} align={subTitleAlign || 'center'}>
               {typeof subTitle !== 'string' ? subTitle : <Markdown>{subTitle}</Markdown>}
            </Typography>}
          </Box>}

          
          <Box>
           {contentType && getComponents()}
          </Box>


          {(primaryButtonText || secondaryButtonText) && <Box><Box display='flex' justifyContent='center' gap={16}>
            {primaryButtonText && <Button
             fullWidth={isMobile}
             id='columnBtnPrimary'
             variant={primaryButtonVariant || 'filled'}
             onClick={() => router.push(primaryButtonAction)}
             className={!secondaryButtonAction && !isMobile ? classes['rb-button-regular'] : ''}
            >
              {primaryButtonText}
            </Button>}
            {secondaryButtonText && <Button
             fullWidth={isMobile}
             id='columnBtnSecondary'
             variant={secondaryButtonVariant || 'outlined'}
             onClick={() => router.push(secondaryButtonAction)}
             className={!primaryButtonAction && !isMobile ? classes['rb-button-regular'] : ''}
            >
              {secondaryButtonText}
            </Button>}
          </Box>
          </Box>}


        </Box>
      </Container>
    </div>
  )
}

export default ColumnSection