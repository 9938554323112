import React from 'react'
import Box from '../../../../../remitbee/components/box/Box'
import classes from './ColumnSection.module.scss'
import Typography from '../../../../../remitbee/components/typography/Typography'
import theme from '../../../../../remitbee/theme/Theme'
import Image from 'next/image'
import Google from '../../../../../../public/new/landing/google-logo.svg'
import Trustpilot from '../../../../../../public/new/landing/trustpilot-logo.svg'
import Facebook from '../../../../../../public/new/landing/facebook-logo.svg'
import Stars from '../../../../../../public/new/landing/stars-4-half.svg'


const ratings = [
   {
    src: Google,
    alt: "Google logo Remitbee reviews",
    actionUrl: "https://www.google.com/search?q=remitbee&rlz=1C1CHBF_enCA850CA850&oq=remitbee&aqs=chrome..69i57j69i59j69i60l4.4486j0j7&sourceid=chrome&ie=UTF-8#lrd=0x882b46fd01475905:0x546334d0f0b5f73b,1,,,",
    title: "4.6 Rating on Google",
    ratingImage: Stars
   },
   {
    title: "4.7 Rating on Trustpilot",
    src: Trustpilot,
    alt: "Trustpilot logo Remitbee reviews",
    actionUrl: "https://www.trustpilot.com/review/remitbee.com",
    ratingImage: Stars
   },
   {
    title: "4.9 Rating on Facebook",
    src: Facebook,
    alt: "Facebook logo Remitbee reviews",
    actionUrl: "https://www.facebook.com/pg/Remitbee/reviews/?ref=page_internal",
    ratingImage: Stars
   }
]

const Rating = () => {
  return (
    <Box display='flex' alignItems='center' gap={16} flexWrap='wrap'>
      {ratings.map((rating, index) => {
        return (
            <Box onClick={rating?.actionUrl ? () => window.open(rating?.actionUrl) : null} className={classes['rb-rating-card']}>
              <Box display='flex' justifyContent='center'>
                <Typography align='center' variant='textXl' weight='bold' color={theme.palette.primary.navy}>
                {rating.title}
                </Typography>
              </Box>
              <Box display='flex' justifyContent='center' alignItems='center'>  
              <Image
                src={rating.src}
                alt={rating.alt || 'logo'}
                width={160}
                height={56}
              />
                </Box>
            <Box display='flex' justifyContent='center' alignItems='center'>
              <Image
                src={rating.ratingImage}
                alt={'stars'}
                width={100}
                height={18}
              />
              </Box>
            </Box>  
        )
      })}
    </Box>
  )
}

export default Rating