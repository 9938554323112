import React from 'react'

const ArrowRightLined = ({ width = 20, height = 20, color = '#1160F7' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Icons">
    <path id="Icon" d="M4.66666 10.0003H16.3333M16.3333 10.0003L10.5 4.16699M16.3333 10.0003L10.5 15.8337" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    </svg>
    
  )
}

export default ArrowRightLined;