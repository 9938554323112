import { useState, useEffect, useRef, useMemo } from 'react';
import NextImage from 'next/image';

interface Props {
    url: string;
    alt?: string;
    width?: number;
    height?: number;
    className?: string;
    onLoad?: () => void;
    priority?: boolean;
}

const allowedDomains = [
    'prodmtes.blob.core.windows.net',
    'devmtes.blob.core.windows.net',
    'prodmtes-cf.remitbee.com',
    'devmtes-cf.wisecapitals.com',
];

const Image: React.FC<Props> = ({ url, alt, width, height, className, onLoad, priority = false }) => {
    const containerRef = useRef<HTMLDivElement>(null);

    // Memoize the domain check to avoid rechecking on every render
    const isAllowed = useMemo(() => {
        const isAllowedDomain = (src: string) => {
            return typeof src === 'string' ? allowedDomains.some((domain) => src?.includes(domain) || src?.startsWith('/')) : false;
        };
        return isAllowedDomain(url);
    }, [url]);

    const [aspectRatio, setAspectRatio] = useState<number | null>(width && height ? height / width : 0);
    // const [containerWidth, setContainerWidth] = useState<number | null>(0);

    // Update aspect ratio when width and height change
    useEffect(() => {
        if (width && height) {
            setAspectRatio(height / width);
        }
    }, [width, height]);

    // // Resize handler, memoized with useCallback to prevent unnecessary re-creations
    // const handleResize = useCallback(() => {
    //     if (containerRef.current) {
    //         setContainerWidth(containerRef.current.offsetWidth);
    //     }
    // }, []);

    // // Setup resize event listener only once
    // useEffect(() => {
    //     if (containerRef.current) {
    //         setContainerWidth(containerRef.current.offsetWidth);
    //     }

    //     window.addEventListener('resize', handleResize);
    //     return () => window.removeEventListener('resize', handleResize);
    // }, [containerRef]);

    const handleLoadingComplete = (naturalWidth: number, naturalHeight: number) => {
        if (!width || !height) {
            const calculatedAspectRatio = naturalHeight / naturalWidth;
            setAspectRatio((prev) => (!prev ? calculatedAspectRatio : prev));
        }
    };

    if (!isAllowed) {
        return (
            <div
                style={{
                    width: '100%',
                    maxWidth: '100%',
                    aspectRatio: aspectRatio ? `1 / ${aspectRatio}` : undefined,
                    position: 'relative',
                }}
            >
                <img
                    src={url}
                    alt={alt || 'imageAlt'}
                    className={className}
                    loading="lazy"
                    style={{ margin: '0 auto', width: '100%' }}
                    onLoad={() => {
                        onLoad?.();
                    }}
                />
            </div>
        );
    }

    return (
        <div
            style={{
                width: '100%',
                maxWidth: '100%',
                aspectRatio: aspectRatio ? `1 / ${aspectRatio}` : undefined,
                position: 'relative',
            }}
            ref={containerRef}
        >
            <NextImage
                src={url}
                alt={alt || 'imageAlt'}
                onLoad={({ target }) => {
                    const { naturalWidth, naturalHeight } = target as HTMLImageElement;
                    if (!aspectRatio) handleLoadingComplete(naturalWidth, naturalHeight);
                    if (onLoad) onLoad();
                }}
                className={className}
                priority={priority}
                quality={100}
                // sizes={containerWidth ? `${containerWidth}px` : `100vw`}
                fill
                style={{
                    margin: '0 auto',
                    objectFit: 'contain',
                }}
            />
        </div>
    );
};

export default Image;
