import React from 'react'
import Box from '../../../../../remitbee/components/box/Box'
import Typography from '../../../../../remitbee/components/typography/Typography'
import NextImage from 'next/image';
import TrustPilot from '../../../../../../public/design-system/landing/Trust_Pilot.svg';
import Google from '../../../../../../public/design-system/landing/Google.svg';
import Stars from '../../../../../../public/new/landing/stars-4-half.svg'
import classes from './HeroSection.module.scss'
import theme from '../../../../../remitbee/theme/Theme';

const SocialProof = () => {
  return (
    <Box mt={10} display="flex" justifyContent='center'>
        <Box display='flex' gap={48} justifyContent='center'>
        <Box className={classes['rb-rating-border']}>
            <Typography
                variant="textMd"
                weight="medium"
                color={theme.palette.primary.navy}
            >{`4.7 rating on Trustpilot`}</Typography>
            <Box display="flex" justifyContent="center">
                <NextImage
                    alt="trustpilot"
                    width={125}
                    height={30}
                    src={TrustPilot}
                />
            </Box>
            <Box display="flex" justifyContent="center">
                <NextImage
                    alt="stars"
                    width={100}
                    height={18}
                    src={Stars}
                />
            </Box>
        </Box>
        <div className={classes['rb-custom-divider']}></div>
        <Box className={classes['rb-rating-border']}>
            <Typography
                variant="textMd"
                weight="medium"
                color={theme.palette.primary.navy}
            >{`4.6 rating on Trustpilot`}</Typography>
            <Box display="flex" justifyContent="center">
                <NextImage
                    alt="trustpilot"
                    width={125}
                    height={30}
                    src={Google}
                />
            </Box>
            <Box display="flex" justifyContent="center">
                <NextImage
                    alt="stars"
                    width={100}
                    height={18}
                    src={Stars}
                />
            </Box>
        </Box>
        </Box>
    </Box>
)
}

export default SocialProof