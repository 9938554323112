
import react from 'react';
import FullWidthContactSection from './FullWidthContactSection';
import HalfWidthContactSection from './HalfWidthContactSection';

type ContactSectionProps = {
    tag?: string;
    tagVariant?: string;
    tagWeight?: string;
    tagColor?: string;
    title?: string | React.ReactNode;
    titleVariant?: string;
    titleWeight?: string;
    titleColor?: string;
    text?: string | React.ReactNode;
    textVariant?: string;
    textWeight?: string;
    textColor?: string;
    image?: any;
    background?: string;
    padding?: string;
    reverse?: boolean;
    reverseMobile?: boolean;
    isMobile?: boolean;
    type?: string;
  };

const ContactSection: React.FC<ContactSectionProps> = ({
    tag,
    tagVariant,
    tagColor,
    tagWeight,
    title,
    titleVariant,
    titleColor,
    titleWeight,
    text,
    textVariant,
    textColor,
    textWeight,
    image,
    background,
    padding,
    isMobile,
    reverse,
    reverseMobile,
    type, 
    }) => {

        return (
            <>
            {
                type === 'full' ? 
                <FullWidthContactSection 
                    tag={tag}
                    tagVariant={tagVariant}
                    tagColor={tagColor}
                    tagWeight={tagWeight}
                    title={title}
                    titleVariant={titleVariant}
                    titleColor={titleColor}
                    titleWeight={titleWeight}
                    text={text}
                    textVariant={textVariant}
                    textColor={textColor}
                    textWeight={textWeight}
                    background={background}
                    isMobile={isMobile} /> :
                <HalfWidthContactSection
                    tag={tag}
                    tagVariant={tagVariant}
                    tagColor={tagColor}
                    tagWeight={tagWeight}
                    title={title}
                    titleVariant={titleVariant}
                    titleColor={titleColor}
                    titleWeight={titleWeight}
                    text={text}
                    textVariant={textVariant}
                    textColor={textColor}
                    textWeight={textWeight}
                    image={image}
                    background={background}
                    padding={padding}
                    isMobile={isMobile}
                    reverse={reverse}
                    reverseMobile={reverseMobile} />
            }
            </>
        )
    };


    export default ContactSection;