import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Markdown from 'markdown-to-jsx';
import PropTypes from 'prop-types';
import React from 'react';
import CustomButton from '../components/CustomButtonsWebsite/CustomButton';
import { body1, container, mediumTitle, whiteColor } from '../globalStyles';


const styles = (theme) => ({
  container,
  mediumTitle,
  body1,
  root: {
    display: 'flex',
    backgroundColor: whiteColor,
    textAlign: 'left',
    paddingTop: '120px',
    paddingBottom: '100px',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      paddingTop: '80px',
      paddingBottom: '60px',
      clipPath: 'none',
    },
  },
  offerBox: {
    display: 'flex',
    position: 'relative',
    flex: '1 1 100%',
    flexDirection: 'column',
    boxOrient: 'vertical',
    padding: '40px 40px 40px 120px',
    borderRadius: '10px',
    boxShadow: '0 3px 10px rgb(11, 65, 153, 0.2)',
    textAlign: 'left',
    margin: '15px',
    flexBasis: '1%',
    [theme.breakpoints.down('sm')]: {
      //marginBottom: '30px',
    },
    '@media (max-width: 500px)': {
      padding: '20px',
      textAlign: 'center',
      alignItems: 'center',
      flexBasis: '100%',
    },
  },
  title: {
    fontSize: '18px',
    fontWeight: '400',
  },
  image: {
    position: 'absolute',
    paddingLeft: '40px',
    left: '-1px',
    '@media (max-width: 500px)': {
      position: 'inherit',
      padding: '0 0 10px 0',
    },
  },
  buttonAlignment: {
    padding: '20px 0 0 0',
    textAlign: 'right',
    color: '#1160F7',
    fontSize: '16px',
    fontWeight: '500',
    maxWidth: '200px',
    alignSelf: 'flex-end',
    '&:after': {
      opacity: '.2',
      position: 'absolute',
      bottom: '2px',
      left: '0',
      content: "''",
      display: 'block',
      width: '100%',
      height: '2px',
      background: '#1160F7',
      transition: 'opacity .2s ease-in-out',
    },
    '&:hover': {
      background: 'transparent',
      '&:after': {
        opacity: '1',
      },
    },
    '@media (max-width: 500px)': {
      alignSelf: 'center',
      paddingTop: '10px',
    },
  },
  //blueCurve: {
  // height: '100px',
  // width: '100%',
  // background: 'linear-gradient(166deg, rgba(13,39,254,1) 0%, rgba(17,96,247,1) 49%, rgba(11,84,255,1) 100%)',
  //}
});

function TwoBoxOffers(props) {
  const { classes, content } = props;
  return (
    <Grid container className={classes.container}>
      {content.map((item) => {
        return (
          <Grid item xs={12} md={6} className={classes.offerBox}>
            <img src={item.image} className={classes.image} alt={item.imageAlt || 'imageAlt'} />
            <Typography variant="h6" className={classes.title}>
              {item.title}
            </Typography>
            {item.text && (
              <Typography
                marked="center"
                className={classes.body1 + ' ' + classes.bodyTextAlignment}
              >
                <Markdown children={item.text} />
              </Typography>
            )}
            <CustomButton
              type="linkBlueBtn"
              href={item.actionLink}
              className={classes.buttonAlignment}
            >
              {item.actionText}
            </CustomButton>
          </Grid>
        );
      })}
    </Grid>
  );
}

TwoBoxOffers.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TwoBoxOffers);
