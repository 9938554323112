import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Box from '../../../../../remitbee/components/box/Box';
import Spacing from '../../../../../remitbee/styles/spacing/Spacing';
import Button from '../../../../../remitbee/components/button/Button';
import { snackbar } from '../../../../../remitbee/components/snackbar/SnackBar';
import theme from '../../../../../remitbee/theme/Theme';
import { stringToFloat } from '../../../../../shared/math';
import MoneyTransferBox from '../../../../../remitbee/components/moneyTransferBox/MoneyTransferBox';
import classes from './MultiConverter.module.scss';
import RequestDialog from '../../../../sendMoneyV2/RecipientDetails/RecipientForm/FormFields/RequestDialog';
import PayWithInput from './PayWithInput';
import { calculatePublicConversion, fetchCountriesMulticurrencyRest } from '../../../../../redux/system/actions';
import Typography from '../../../../../remitbee/components/typography/Typography';
import { Tooltip, useMediaQuery } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { calcMoneyTransferAmountOnlineSession } from '../../../../../redux/rates/session/actions';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'next-i18next';
import InfoIcon from '../../../../../../public/img/business/question-mark.svg';
import { MOCK_DATA } from './StoryBookMockData';

interface Props {
  country?: string;
  currency?: string;
  countryId?: string | number;
  storybookMock?: any
}



const DEFAULT_COUNTRY = 'IN';
const DEFAULT_CURRENCY = 'INR';

const useStyles = makeStyles(() => ({  // SCSS File classes are not working for Tooltip
  tooltip: {
      background: '#FFF',
      color: theme.palette.primary.navy,
      fontSize: '12px',
      lineHeight: '19px',
      boxShadow: '0px 4px 10px rgba(157, 156, 156, 0.20)',
      maxWidth: '200px',
      padding: '12px',
      borderRadius: '8px',
      border: `1px solid ${theme.palette.border.light}`
  },
  tooltipArrow: {
      color: '#FFF',
  },
  imageClass: {
      maxWidth: '16px'
  }
}))

const MoneyTransferRateDetails: React.FC<Props> = ({ country, currency, countryId, storybookMock }) => {
  const { t } = useTranslation('landing');
  const router = useRouter();
  const { tooltip, tooltipArrow } = useStyles();
  const [countryList, setCountryList] = useState([])
  const [openRequestDialog, setOpenRequestDialog] = useState(false);
  const [filteredCountryList, setFilteredCountryList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [countryIdSelected, setCountryIdSelected] = useState(country || DEFAULT_COUNTRY);
  const [currencyIdSelected, setCurrencyIdSelected] = useState(currency || DEFAULT_CURRENCY);
  const [sendingAmount, setSendingAmount] = useState('1000');
  const [sendingAmountLoading, setSendingAmountLoading] = useState(false);
  const [receivingAmount, setReceivingAmount] = useState('');
  const [receivingAmountLoading, setReceivingAmountLoading] = useState(false);
  const [lastFieldChanged, setLastFieldChanged] = useState('')
  const [min10CADError, setMin10CADError] = useState(null)
  const [paymentTypes, setPaymentTypes] = useState(false);
  const [paymentAdditionalInfo, setPaymentAdditionalInfo] = useState(false);
  const [deliveryETA, setDeliveryETA] = useState(null);
  const [fundingHolidayDates, setFundingHolidayDates] = useState();
  const [settlementHolidayDates, setSettlementHolidayDates] = useState();
  const [selectedCountryName, setSelectedCountryName] = useState(null);
  const [loading, setLoading] = useState(false);
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md}px)`);
  const isNotMobile = useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`);
  const isTablet = useMediaQuery(`(max-width: ${theme.breakpoints.lg}px)`);
  const isHolidayTooltip = fundingHolidayDates || settlementHolidayDates;

  useEffect(() => {
    const initialLoadInfo = async () => {
      await loadTransferRatesRest();
    }

    initialLoadInfo();
  }, []);

  useEffect(() => {
    if (selectedCountry) calculateTransferAmount();
  }, [selectedCountry])

  useEffect(() => {
    setFilteredCountryList(countryList);
    if (countryList && countryList.length && selectedCountry) {
      countryList.some(({ country_id, country_name }) => {
        if (+country_id === +selectedCountry) return setSelectedCountryName(country_name);
      });
    }
  }, [countryList])

  useEffect(() => {
    (sendingAmount && stringToFloat(sendingAmount) < 10) ? setMin10CADError(t('transfer_details.min_sending_amount')) : setMin10CADError(null)
    if (sendingAmount && lastFieldChanged === 'send') {
      calculateTransferAmount(sendingAmount)
    }
  }, [sendingAmount])

  useEffect(() => {
    if (receivingAmount && lastFieldChanged === 'receive') {
      calculateTransferAmount(null, receivingAmount)
    }
  }, [receivingAmount])

  useEffect(() => {
    calculateTransferAmount(sendingAmount)
  }, [selectedCountry]);

  useEffect(() => {
    if (sendingAmount || receivingAmount) lastFieldChanged === 'send' ? setReceivingAmountLoading(true) : setSendingAmountLoading(true);
  }, [lastFieldChanged]);


  const loadTransferRatesRest = async () => {
    try {
      if (!loading && !storybookMock) setLoading(true);
      const data = storybookMock ? MOCK_DATA.rates : await fetchCountriesMulticurrencyRest();
      if (!data) return;
      setCountryList(data);

      let selectedCountryRate = {};

      if (countryId) {
        selectedCountryRate = data.find((r: any) => {
          if (r?.country_id?.toString() === countryId.toString())
            return r;
        });
      } else {
        selectedCountryRate = data.find((r: any) => {
          if (currency) {
            if (r?.currency_code === currency && r?.iso2 === country) {
              return r;
            }
          } else {
            if (r?.iso2 === countryIdSelected) {
              return r;
            }
          }
        });
      }
      if (!selectedCountryRate) {
        selectedCountryRate = data.find((r: any) => {
          if (r?.iso2 === country) {
            return r;
          }
        });
      };

      if (!selectedCountryRate) return;
      setSelectedCountry(selectedCountryRate)
      setCountryIdSelected(selectedCountryRate['iso2'])
      setCurrencyIdSelected(selectedCountryRate['currency_code'])
    }
    catch (error) {
      snackbar.error(error.message, 3000)
    }
    finally {
      setLoading(false);
    }
  }
  const calculateTransferAmount = async (transferAmount?: string, endReceivingAmount?: string) => {
    try {
      if (sendingAmount || receivingAmount) lastFieldChanged === 'send' ? setReceivingAmountLoading(true) : setSendingAmountLoading(true);

      if (!selectedCountry) return;

      const _args: any = {};
      if (transferAmount) _args.transfer_amount = transferAmount;
      if (endReceivingAmount) _args.receiving_amount = endReceivingAmount;
      if (!transferAmount && !endReceivingAmount) return
      _args.country_id = selectedCountry?.country_id;
      _args.currency_code = selectedCountry?.currency_code;
      _args.include_timeline = true;
      _args.is_special_rate = false;

      const data = storybookMock ? MOCK_DATA.conversion : await calculatePublicConversion(_args); //await calcMoneyTransferAmountOnlineSession(_args);
      if (!data) throw new Error('Error while calculating the rate.');
      else if (data) {
        // const response = data.CalcMoneyTransferAmountOnlineV2;
        setPaymentTypes(data.payment_types);
        setPaymentAdditionalInfo(data.payment_additional_info);
        if (transferAmount) {
          setReceivingAmount(data.receiving_amount);
        }
        if (endReceivingAmount) {
          setSendingAmount(data.transfer_amount);
        }
      }
    }
    catch (error) {
      snackbar.error(error.message, 3000)
      setSendingAmount('');
      setReceivingAmount('');
    } finally {
      setReceivingAmountLoading(false);
      setSendingAmountLoading(false);
    }
  }

  const handleSendAmountChange = (value: string) => {
    if (!value) return setReceivingAmount('');
    setLastFieldChanged("send")
    setSendingAmount(value)
  }

  const handleReceiveAmountChange = (value: string) => {
    if (!value) return setSendingAmount('');
    setLastFieldChanged("receive")
    setReceivingAmount(value)
  }

  const handleReceivingCountrySearch = (typed: string, receiveSuggestions: any) => {
    if (typed && typed !== '') {
      const filteredList = countryList.filter((c: any) => c.country_to.toUpperCase().includes(typed.toUpperCase())
        || c.currency_code.toUpperCase().includes(typed.toUpperCase()));
      filteredList.length > 0 ? setFilteredCountryList(filteredList) : setFilteredCountryList(receiveSuggestions);
    } else setFilteredCountryList(countryList);
  }

  return (
    <>
      <Spacing variant='betweenHeaderToTab' />
      <Box className={`${classes[`rb-exchange-box`]}`}>
        <MoneyTransferBox
          id="moneyTransferRateDetails"
          sendingFlagCode='CA'
          sendingCurrency='CAD'
          sendFieldErrorMessage={min10CADError}
          receivingFlagCode={countryIdSelected.toString()}
          receivingCurrency={currencyIdSelected}
          sendAmountLoading={sendingAmountLoading || loading}
          receivingAmountLoading={receivingAmountLoading || loading}
          sendAmountValue={sendingAmount}
          receivingAmountValue={receivingAmount}
          receivingCurrencyValue={selectedCountry?.rate || ''}
          onSendValueHandleChange={handleSendAmountChange}
          onReceiveValueHandleChange={handleReceiveAmountChange}
          countriesList={filteredCountryList}
          showPopularCurrencies={countryList && countryList?.length === filteredCountryList?.length}
          receivingCountrySearch={handleReceivingCountrySearch}
          receivingCountrySelectable={true}
          showCountryName={true}
          receivingCountrySuggestionsLabel={'country'}
          receivingCountrySuggestionsRequestDialog={setOpenRequestDialog}
          onReceivingCountryChange={(country: any) => {
            setSelectedCountry(country)
            setCountryIdSelected(country.iso2)
            setCurrencyIdSelected(country.currency_code)
          }}
        />
        <Spacing variant='betweenSummaryCards' />
        <Box display='flex' flexDirection={isMobile ? 'column' : 'row'} gap={isMobile ? 16 : 4} className={classes['rb-estimate-box']}>
          <Box className={classes['rb-payWithInput-box']}>
            <PayWithInput sendingAmount={sendingAmount} t={t} availablePaymentTypes={paymentTypes} additionalInfo={paymentAdditionalInfo} onChangeSelect={setDeliveryETA} setFundingHolidayDates={(e) => setFundingHolidayDates(e)} setSettlementHolidayDates={(e) => setSettlementHolidayDates(e)} />
          </Box>
          <Box>
            {deliveryETA ?
              (<Box display='flex' flexDirection={!isTablet ? "column" : "row"} gap={4}>
                <Box>
                  <Typography variant={"textMd"} className={classes['rb-paymentFees-text']} align={isTablet ? 'left' : 'center'}>
                    {t('estimated_delivery')}
                  </Typography>
                </Box>
                <Box display='flex' className={classes['rb-fee-box']} justifyContent={isTablet ? 'flex-end' : 'center'} alignItems={isTablet ? 'right' : 'center'} gap={4}>
                  <Typography variant={"textMd"} className={classes['rb-paymentFees-text']} weight='semibold' align='center' color={theme.palette.primary.navy}>
                    {deliveryETA}
                  </Typography>
                  {isHolidayTooltip ? <Tooltip
                    arrow
                    leaveDelay={100}
                    title={fundingHolidayDates && settlementHolidayDates
                      ? t('landing:holiday_message_info.combined_message', { recipient_country: selectedCountryName || `Recipient's country`, funding_formatted_dates: fundingHolidayDates, settlement_formatted_dates: settlementHolidayDates })
                      : fundingHolidayDates ? t('landing:holiday_message_info.funding_message', { formatted_dates: fundingHolidayDates })
                        : settlementHolidayDates ? t('landing:holiday_message_info.settlement_message', { recipient_country: selectedCountryName || `Recipient's country`, formatted_dates: settlementHolidayDates })
                          : ''}
                    placement='top'
                    classes={{ tooltip: tooltip, arrow: tooltipArrow }}>
                    <img src={InfoIcon} width={isMobile ? '16px' : '20px'} height={isMobile ? '16px' : '20px'} />
                  </Tooltip> : <></>}
                </Box>
              </Box>) :
              <Box display='flex' justifyContent='center' flexDirection={isMobile ? "row" : "column"} gap={4}>
                <Box display='flex' justifyContent={isTablet ? isMobile ? 'flex-start' : 'flex-end' : 'center'}>
                  <Skeleton  width={160} height={21} style={{ borderRadius: '4px' }} />
                </Box>
                <Box display='flex' justifyContent={isTablet ? 'flex-end' : 'center'}>
                  <Skeleton  width={100} height={21} style={{ borderRadius: '4px' }} />
                </Box>
              </Box>
            }
          </Box>
        </Box>
        <Box>
          <Spacing variant='betweenSectionToInputFields' />
          <Button id='send-money' fullWidth variant='filledPrimary' onClick={() => router.push('/send-money')}>
            {t('send_money')}
          </Button>
        </Box>
      </Box>
      <RequestDialog request_type={'country'} open={openRequestDialog} handleClose={setOpenRequestDialog} isPublic />
    </>
  )
}

export default MoneyTransferRateDetails;