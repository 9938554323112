import React from 'react';

const SwapIcon = ({ color = '#1160F7', height, width }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1" y="1" width="32" height="32" rx="16" fill="white" />
            <rect x="1" y="1" width="32" height="32" rx="16" stroke="#1160F7" stroke-width="1.5" />
            <path d="M11.667 20.3333H22.3337M22.3337 20.3333L19.667 17.6667M22.3337 20.3333L19.667 23M22.3337 13.6667H11.667M11.667 13.6667L14.3337 11M11.667 13.6667L14.3337 16.3333" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};

export default SwapIcon;
