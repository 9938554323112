import React, { useState } from 'react';
import classes from './Checkbox.module.scss';

function CustomCheckbox({ isChecked, onChange }) {
  const [checked, setChecked] = useState(isChecked);

  return (
    <label className={classes["custom-checkbox"]}>
      <input
        type="checkbox"
        checked={isChecked}
        onChange={onChange}
      />
      <span className={classes["checkmark"]}></span>
    </label>
  );
}

export default CustomCheckbox;
