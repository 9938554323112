import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import React from 'react';
import { parseArrFromString } from '../../../shared/utility';
import { bigTitle } from '../globalStyles';

const useStyles = makeStyles(theme =>({
    root: {
        textAlign: 'center', 
        marginTop: '80px',
        backgroundColor: '#ffffff'
    },
    bigTitle: {
        ...bigTitle,
        marginBottom: '60px !important',
    },
    stepText: {
        color: '#6B708F',
        fontSize: '16px', 
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left',
        },
    }, 
    stepNumber: {
        position: 'absolute', 
        zIndex: '99', 
        fontSize: '83px',
        top: '0', 
        left: '0',  
        right: '0', 
        bottom: '0',
        color: '#1160F7',
        opacity: '0.2',
        overflow: 'hidden'
    }, 
    stepTitle: {
        zIndex: '100', 
        color: '#31517A', 
        fontSize: '20px', 
        fontWeight: 'Bold', 
        bottom: '0',
        left: '0',
        position: 'absolute',
        width: '100%',
        display: 'inline'
    },
    stepHeader: {
        position: 'relative', 
        height: '90px', 
        textAlign: 'center', 
        marginBottom: '30px'
    }, 
    stepSection: {
        marginBottom: '80px'
    }, 
    stepImage: {
        width: '100%',
        marginTop: '20px'
    }
}))


const HowItWorks = ({title, steps}) => {
    const classes = useStyles(); 
    const items = parseArrFromString(steps);

    const renderStepItem = (item, index) => { 
        let { stepTitle, text, image } = item;
        let number = index + 1;     
        let formattedNumber = ("0" + number).slice(-2);
        return (
            <div className={classes.stepSection}>
                <div className={classes.stepHeader}>
                    <div className={classes.stepNumber}>{formattedNumber}</div>
                    <div className={classes.stepTitle}>{stepTitle}</div>
                </div>
                <Typography className={classes.stepText}>{text}</Typography>
                <img className={classes.stepImage} src={image || 'imageAlt'} alt={`Step ${number} image`}/>
            </div>
        )
    }

    return (
        <div className={classes.root}>
            <Typography className={classes.bigTitle}>{title}</Typography>
            {items.map(renderStepItem)}
        </div>
    )
}

export default HowItWorks;