import axios from 'axios';
import actionTypes from './actionTypes';
import { apiMainQuery } from '../../settings/apollo/api/main/query';
import { apiMainMutation } from '../../settings/apollo/api/main/mutation';
import { getRewardsAmount } from '../company/actions';
import {
  signInEmailMutation,
  signUpEmailMutation,
  logoutMutation,
  isSessionAliveQuery,
  verifyTwoFactorCodeMutation,
  sendCustomerResetPasswordLinkMutation,
  resetCustomerPasswordMutation,
  isCustomerResetPasswordTokenValidQuery,
  isCutomerHasPermissionToTestFeatureQuery,
  verifyTwoFactorCodeV2Mutation,
  saveCustomerRequestMutation,
  generateTwoFactorCodeV2Mutation
} from './graphql.graphql';

import { fetchCountriesFromRates } from '../system/actions';
import { relativePathRegex } from '../../shared/regex';
import { Analytics, ACTIONS } from '../../settings/analytics';
import { getReferralAmount } from '../../shared/utility';
import { setClientHasAccountCookie } from '../../shared/cookie-handler';
import { updateBrazeUser } from '../../settings/braze/braze-service';
import { BrowserLogger } from '../../settings/browser-logger-v2';
const { rc_rewards_amount } = getReferralAmount();

export const signInEmail = (cus_email, cus_password, recaptcha, saveDevice) => (dispatch) => {
  return apiMainMutation(signInEmailMutation, {
    auth: {
      cus_email,
      cus_password,
      saveDevice,
      deviceUUID: localStorage.getItem(actionTypes.DEVICE_UUID),
    },
    recaptcha,
  }).then(async (result) => {
    const { success, data } = result;
    // This action is also been handled in the customer reducer
    if (success) {
      const { signInEmail } = data;
      updateBrazeUser(signInEmail?.personalInformation?.cus_unique_id);
      if (signInEmail.businessInformation) dispatch({ type: actionTypes.SET_BUSINESS_INFO, payload: signInEmail.businessInformation })
      if (signInEmail.personalInformation.cus_2fa_preference && signInEmail.isDeviceRegistered == false) {
        return result;
      }
      if (!signInEmail.personalInformation.cus_phone1) {
        signInEmail.hasPhoneNumber = false;
      }
      
      if (signInEmail.personalInformation.cus_phone_validation === '1') {
        signInEmail.isPhoneValidated = true;
      }
      
      if (signInEmail.is_2fa_active === '1') {
        signInEmail.is2FAActive = true;
      }

      if (!rc_rewards_amount) {
        await getRewardsAmount(dispatch);
      }

      await setClientHasAccountCookie();
      dispatch({ type: actionTypes.LOGIN_SUCCESS, payload: signInEmail })
      dispatch(await fetchCountriesFromRates());
    }
    return result;
  });
}

export const verifyTwoFactorCode = (params) => (dispatch) => {
  return apiMainMutation(verifyTwoFactorCodeMutation, params).then(async (result) => {
    const { success, data } = result;
    // This action is also been handled in the customer reducer
    if (success) {
      dispatch({ type: actionTypes.LOGIN_SUCCESS, payload: data.verifyTwoFactorCode });
      dispatch(await fetchCountriesFromRates());
    }
    return result;
  });
};

export const verifyTwoFactorCodeV2 = (params) => async (dispatch) => {
  const { success, data, errorMessage } = await apiMainMutation(verifyTwoFactorCodeV2Mutation, params);
    // This action is also been handled in the customer reducer
    if (success) dispatch({ type: actionTypes.LOGIN_SUCCESS, payload: data.verifyTwoFactorCodeV2 });
    dispatch(await fetchCountriesFromRates());
    return { success, errorMessage, data }
};

export const generateTwoFactorCodeV2 = async (params) => await apiMainMutation(
  generateTwoFactorCodeV2Mutation,
  params,
);

export const signUpEmail = (
  cus_email,
  cus_password,
  referral_code,
  business_account = false,
  recaptcha,
  utm_source,
  utm_signup_page,
  utm_campaign,
  pap_referral_id
) => (dispatch) => {
  return apiMainMutation(signUpEmailMutation, {
    customer: {
      cus_email,
      cus_password,
      referral_code,
      business_account,
      utm_source,
      utm_signup_page,
      utm_campaign,
      pap_referral_id
    },
    recaptcha,
  }).then(async(result) => {
    const { success, data } = result;
    // This action is also been handled in the customer reducer
    if (success) {
      Analytics.track(ACTIONS.SIGNUP_INITIATED, { email: cus_email, is_business: !!business_account });
      if (referral_code !== '') {
        Analytics.track(ACTIONS.SIGNUP_REFERRAL_CODE, {email: cus_email, referral_code: referral_code, is_business: !!business_account });
      }

      if(!rc_rewards_amount) {
        await getRewardsAmount(dispatch);
      }
      await setClientHasAccountCookie();
      updateBrazeUser(data.signUpEmail?.personalInformation?.cus_unique_id);
      dispatch({ type: actionTypes.SIGN_UP_SUCCESS, payload: data.signUpEmail })
      dispatch(await fetchCountriesFromRates());
    }
    return result;
  });
};

export const saveCustomerRequest = (email, data, request_type) =>
  apiMainMutation(saveCustomerRequestMutation, {
    email,
    data,
    request_type,
  });

export const isSessionAlive = () => apiMainQuery(isSessionAliveQuery);

export const isCustomerResetPasswordTokenValid = async (params) => await apiMainQuery(isCustomerResetPasswordTokenValidQuery, params);

export const resetCustomerPassword = async (params) => await apiMainMutation(
  resetCustomerPasswordMutation,
  params,
);

export const sendCustomerResetPasswordLink = async (params) => await apiMainMutation(
  sendCustomerResetPasswordLinkMutation,
  params,
);

/**
 * Update the CSRF cookie
 */
export const setCsrfToken = async () => async (dispatch) => {
  try {
    const { data } = await axios.get(`${process.env.REACT_APP_API}/v2/customers/csrf`, { withCredentials: true });
    dispatch({ type: actionTypes.CSRF_TOKEN, payload: data });
    return true;
  } catch (error) {
    BrowserLogger.error(error, { action_from: 'auth.actions', function_name: 'setCsrfToken' });
    return false;
  }
};

export const setToken = async () =>  {
  try {
    const { data } = await axios.get(`${process.env.REACT_APP_API}/v2/customers/csrf`, { withCredentials: true });
    return data;
  } catch (error) {
    BrowserLogger.error(error, { action_from: 'auth.actions', function_name: 'setToken' });
    return null;
  }
};

export const setReferralCode = (referralCode) => ({
  type: actionTypes.SET_REFERRAL_CODE,
  payload: referralCode,
});

/**
 * Log the user out
 * @param isFromMiddleware Will come as true if it's a logout request from the Apollo middleware.
 * The reason is because the tokens are invalid, so there is not point to try to request a
 * private service
 */

export const logout = (shouldCallMutation = true, shouldRedirectToLogin = false, redirectTo) => async(dispatch, getState) => {
  const { email, saveDevice } = localStorage;
  const defaultLangauge = localStorage.getItem('i18nextLng');

  Analytics.track(ACTIONS.LOGOUT, { redirectTo, type: shouldCallMutation ? 'MANUAL' : 'AUTO' });

  // Getting the last known deviceUUID from redux state or localStorage
  const deviceUUID =
    (getState() && getState().auth && getState().auth.deviceUUID) ||
    localStorage.getItem(actionTypes.DEVICE_UUID);

  localStorage.clear();
  localStorage.removeItem('persist:auth');
  if (saveDevice && saveDevice === 'true') {
    if (email) localStorage.setItem('email', email);
    localStorage.setItem('saveDevice', saveDevice);
  }
  if (defaultLangauge)
    localStorage.setItem('i18nextLng', defaultLangauge);
  Analytics.reset()
  if (shouldCallMutation){
    await apiMainMutation(logoutMutation)
  }
  if (shouldRedirectToLogin) {
    let redirectPath = redirectTo ? '/login' : '/logout';
    if(redirectTo && redirectTo.match(relativePathRegex)) redirectPath += `?redirectTo=${redirectTo}`
    window.location.assign(encodeURI(redirectPath));
  }

  // making sure that all pages are logged out
  dispatch({ type: actionTypes.CLEAR_STATE });

  window.localStorage.setItem('logout', Date.now());

  // Persisting the last known deviceUUID in localStorage after clearing it
  if (deviceUUID) localStorage.setItem(actionTypes.DEVICE_UUID, deviceUUID);
  else localStorage.removeItem(actionTypes.DEVICE_UUID);
};

export const setHasPhoneNumber = hasPhoneNumber => (dispatch) => {
  dispatch({ type: actionTypes.SET_HAS_PHONE_NUMBER, payload: { hasPhoneNumber } });
}

export const setPhoneValidated = isPhoneValidated => (dispatch) => {
  dispatch({ type: actionTypes.SET_PHONE_VALIDATED, payload: isPhoneValidated });
}

export const set2FAPrompt = status => (dispatch) => {
  dispatch({ type: actionTypes.SET_2FA_PROMPT, payload: status });
}

export const isCutomerHasPermissionToTestFeature = async ({cus_unique_id}) => await apiMainQuery(
  isCutomerHasPermissionToTestFeatureQuery,
  { cus_unique_id },
);

export const subscribeSignUpPromo = async data => {
  var config = {
    method: 'post',
    url: `${process.env.REACT_APP_API}/public-services/utils/promo-sign-up`,
    headers: { 
      'Content-Type': 'application/json',
      'Cookie': 'next-i18next=en'
    },
    data
  };
  const { error } = await axios(config);
  if (error) return null;
}

export const requestCountry = async data => {
  var config = {
    method: 'post',
    url: `${process.env.REACT_APP_API}/public-services/utils/request-country`,
    headers: { 
      'Content-Type': 'application/json',
      'Cookie': 'next-i18next=en'
    },
    data
  };
  const { error } = await axios(config);
  if (error) return null;
  else return true;
}

export const subscribeRateAlert = data => {
  var config = {
    method: 'post',
    url: `${process.env.REACT_APP_API}/public-services/online-rates-alert-subscribe`,
    headers: { 
      'Content-Type': 'application/json',
      'Cookie': 'next-i18next=en'
    },
    data
  };

  return new Promise((resolve, reject) => {
    axios(config).then(function (response) {
      resolve(JSON.stringify(response.data));
    })
    .catch(function (error) {
      console.log(error);
      reject();
    });
  })
}
