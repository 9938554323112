import React from 'react';
import classes from './DownloadAppSection.module.scss';
import { useMediaQuery } from '@mui/material';
import theme from '../../../../../remitbee/theme/Theme';
import Box from '../../../../../remitbee/components/box/Box';
import Typography from '../../../../../remitbee/components/typography/Typography';
import AppStore from '../../../../../../public/design-system/landing/DownloadAppSection/AppStore.svg';
import PlayStore from '../../../../../../public/design-system/landing/DownloadAppSection/PlayStore.svg';
import QRCode from '../../../../../../public/design-system/landing/DownloadAppSection/QRCode.svg';
import Image from '../../../../../remitbee/components/image/Image';

interface Props {
    title?: string;
    text?: string;
    showQRCode?: boolean;
    imageUrl?: string;
}

const DownloadAppSection: React.FC<Props> = ({ title, text,showQRCode = true, imageUrl }) => {
    const isNotTablet = useMediaQuery(`(min-width: ${theme.breakpoints.lg}px)`);
    const isNotMobile = useMediaQuery(`(min-width: ${theme.breakpoints.sm}px)`);

    return (
        <section>
            <Box className={classes['rb-root']} flexDirection={isNotMobile ? 'row' : 'column'}>
                <Box display='flex' alignItems='center' flexDirection='column' gap={32} className={classes['rb-content']}>
                    <Box display='flex' flexDirection='column' gap={16}>
                        <Box >
                            <Typography variant='displayLg' className={classes['rb-title']} weight='bold' color={theme.palette.background.white} >
                                {title ? title : "Send on the go from any device"}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography paragraph={true} variant='textMd' color={theme.palette.background.white} className={classes["rb-text"]} >
                                {text ? text : "Created for our customers on the go, the Remitbee app has all the functionality you expect from the website compacted into your iPhone or Android device!"}
                            </Typography>
                        </Box>
                    </Box>
                    <Box display={isNotMobile && 'flex' }flexDirection={isNotMobile && 'row'} justifyContent={isNotMobile && 'center'}>
                        <div className={classes['rb-bottom-items-box']}>
                            <div className={classes['rb-app-box']}>
                                <a target="_blanc" href="https://apps.apple.com/ca/app/remitbee-money-transfer/id983795500">
                                    <img
                                        src={AppStore}
                                        alt="App store icon"
                                        width={'140px'}
                                        height={'42px'}
                                        loading="lazy"
                                    />
                                </a>
                            </div>
                            <div className={classes['rb-app-box']}>
                                <a target="_blanc" href="https://play.google.com/store/apps/details?id=com.remitbee.app.app&hl=en_CA">
                                    <img
                                        src={PlayStore}
                                        alt="Play store icon"
                                        width={'140px'}
                                        height={'42px'}
                                        loading="lazy"
                                    />
                                </a>
                            </div>
                        </div>
                        {showQRCode && isNotMobile && isNotTablet && <div className={classes['rb-qr']}>
                            <Box display='flex' justifyContent='center'>
                                <Typography variant='textXs' color={theme.palette.background.white}>
                                    |
                                </Typography>
                            </Box>
                            <Box display='flex' justifyContent='center'>
                                <Typography variant='textXs' color={theme.palette.background.white}>
                                    or
                                </Typography>
                            </Box>
                            <Box display='flex' justifyContent='center'>
                                <Typography variant='textXs' color={theme.palette.background.white}>
                                    |
                                </Typography>
                            </Box>
                        </div>}
                        {showQRCode && isNotMobile  && isNotTablet && <div className={classes['rb-rq-code']}>
                            <div className={classes['rb-qr']} >
                                <img src={QRCode} />
                            </div>
                            <Box>
                                <Typography variant='textSm' color={theme.palette.background.white}>
                                    Scan QR with your phone to get the app
                                </Typography>
                            </Box>
                        </div>}
                    </Box>
                </Box>
                <div className={classes['rb-devices']}>
                    <Image
                        url={imageUrl ? imageUrl : '/design-system/landing/DownloadAppSection/Devices.webp'}
                        alt='remitbee app download banner'
                        className={classes['rb-devices-image']}
                    />
                </div>
            </Box>
        </section>
    )
}

export default DownloadAppSection;
