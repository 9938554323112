import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/styles';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import classnames from 'classnames';
import Markdown from 'markdown-to-jsx';
import React from 'react';
import { useRouter } from 'next/router';
import { getImageAlt, parseArrFromString } from '../../../shared/utility';
import CustomButton from '../components/CustomButtonsWebsite/CustomButton';
import CustomUnderlineBigHeading from '../components/CustomUnderlinesWebsite/CustomUnderlineBigHeading';
import { bigTitle, body1, container, subTitle } from '../globalStyles';
import { setRedirectSignupCookie } from '../../../shared/cookie-handler';
import TabsSection from '../components/Tabs/Tabs';
import Divider from '@mui/material/Divider';

const useStyles = makeStyles(theme => ({
  container,
  bigTitle: {
    ...bigTitle,
    marginBottom: '0 !important'
  },
  leftAlignTitle: {
    ...bigTitle,
    textAlign: 'left',
  },
  subTitle,
  body1,
  root: {
    display: 'flex',
    paddingTop: '100px',
    paddingBottom: '100px',
    background: '#ffffff',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '60px',
      paddingBottom: '60px',
    },
  },
  subText: {
    fontSize: '18px',
  },
  image: {
    paddingBottom: '16px',
    maxWidth: '100%',
  },
  imageAlign:{
    display: 'flex !important',
    justifyContent: 'center !important' ,
  },
  sectionWrapper: {
    display: 'flex',
    marginTop: '-106px',
    overflow: 'hidden',
    paddingTop: '200px',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '60px',
      paddingBottom: '60px',
    },
  },
  stepsTitle: {
    padding: '20px',
  },
  howToSubheading: {
    fontSize: '18px',
    fontWeight: '500',
    paddingBottom: '10px',
    fontFamily: 'Inter',
  },
  content: {
    textAlign: 'center',
    fontSize: '1rem',
  },
  leftContent: {
    textAlign: 'left',
    fontSize: '1rem',
  },
  curvedBorder: (props) => ({
    borderTopLeftRadius: props.borderRadius ? props.borderRadius : 0,
    borderTopRightRadius: props.borderRadius ? props.borderRadius : 0,
  }),
  boxBody: {
    padding: '0 20px 10px',
  },
  titleIcon: {},
  featuresBox: {
    marginBottom: "30px",
    transition: "ease-in-out 0.3s",
    width: "100%",
    borderRadius: "10px",
    "&:hover,&:focus": {
      boxShadow: "12px 15px 20px 6px rgba(11,65,153,0.1)"
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: "0",
    },
  },
  spacing: {
    marginTop: '32px'
  },
  btnStyle: {
    fontFamily: 'Inter',
    [theme.breakpoints.down('md')]: {
      width: '400px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '324px',
    },
  },
  textCenter: {
    textAlign: 'center',
  },
  textLeft: {
    textAlign: 'left',
  },
  white: {
    color: '#FFFFFF !important'
  },
  grey: {
    color: '#31517A !important'
  },
  lightGrey: {
    color: '#626680'
  },
  stepsContainer: {
    marginTop: '20px',
  },
  bgBlue: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    background: "#1160F7"
  },
  divider: {
    border: '0.75px solid #E9E9E9',
    opacity: '1',
    width: '100%',
    margin: '40px 0 0 0 !important',
    padding: '0 15px',
    [theme.breakpoints.down('sm')]: {
      margin: '0 !important',
    },
  },
  bgWhite: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    background: "#FFFFFF"
  },
  dividerImage: {
    display: 'flex',
    position: 'inherit',
    margin: 'auto',
  },
  smallTitle: {
    fontSize: '30px',
    fontWeight: '500',
    textAlign: 'center',
    marginBottom: '0 !important',
    color: '#31517A',
    "@media (max-width: 959px)": {
      lineHeight: '1.2'
    },
  },
  underline: {
    marginTop: '10px'
  }
}));

const Steps = ({
  title,
  text,
  hiddenUnderline,
  colorInverted,
  stepItems,
  curvedTop,
  itemsCentered,
  backgroundColor,
  itemBackgroundColor,
  alt,
  width,
  height,
  actionText,
  actionUrl,
  actionType,
  border,
  borderRadius,
  noHover,
  minBoxHeight,
  padding,
  showSlider,
  itemsSize,
  alignLeft,
  showPrimaryArrows,
  smallTitle,
  borderRight,
  maxWidth,
  table,
  divider,
}) => {
  const classes = useStyles({borderRadius});
  const items = parseArrFromString(stepItems);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const rootStyle = {};
  const showMobileItems = isMobile && items && items.length
  const router = useRouter()
  const goToUrl = async () => {
    let query = null;
    if (actionUrl && actionUrl.includes('signup')) {
      await setRedirectSignupCookie(router.asPath)
    }
    if (actionUrl && actionUrl.includes('signup?')) {
      router.push(actionUrl);
      return
    }
    router.push({ pathname: actionUrl, query });
  }

  if (backgroundColor) {
    rootStyle.background = backgroundColor;
  }

  const renderSectionItem = (item, index) => {
    const { title, text, image, titleIcon } = item;
    const alt_tag = getImageAlt(image);
    const featureStyle = {};
    let widthIcon = width ? width.toString() : 55;
    let heightIcon = height && !isMobile ? height.toString() : null;
    featureStyle.padding = padding ? padding : '20px';
    if (minBoxHeight && !isMobile) {
      featureStyle.minHeight = minBoxHeight;
    }
    if (itemBackgroundColor) {
      featureStyle.backgroundColor = itemBackgroundColor;
    }
    if (border) {
      featureStyle.border = `solid 1px ${border}`
      featureStyle.borderRadius = borderRadius ? borderRadius : '5px';
    }
if(borderRight){
  if(index !== items.length - 1)featureStyle.borderRight = `solid 1px ${borderRight}`
  featureStyle.padding= '0px 40px'
}
if(maxWidth){
  featureStyle.maxWidth = isMobile ? '100%' : maxWidth;
}
    return (
      <>
      <Grid key={title} item xs={12} sm={6} md={itemsSize ? parseInt(itemsSize) : 4} className={noHover ? null : classes.featuresBox} >
        <div style={featureStyle} >
          {image &&
          <div className={table && classes.imageAlign}>
            <img
              width={widthIcon}
              height={heightIcon}
              src={image}
              className={classnames(classes.image, borderRadius && classes.curvedBorder)}
              alt={alt_tag || 'imageAlt'}
              loading="lazy"
            />
            </div>
          }
          <div className={table && classes.imageAlign}>
          <div className={parseInt(padding) === 0 ? classes.boxBody : classes.fullBody}>
          {title && <Grid container={titleIcon} direction='row' spacing={1}>
            {titleIcon && <Grid item><img src={titleIcon} className={classes.titleIcon} alt='imageAlt' /></Grid>}
            <Grid item><Typography variant="h3" className={classnames(classes.subTitle, classes.howToSubheading, colorInverted ? classes.white : classes.grey)}>
              <Markdown
                children={title}
              />
            </Typography></Grid>
          </Grid>}
          {text && <Typography variant="body1" marked="center" className={classnames(classes.body1, classes.subText, colorInverted ? classes.white : classes.lightGrey)}>
            <Markdown
              children={text}
            />
          </Typography>}
          </div>
          </div>
        </div>
      </Grid>
      </>
    )
  }

  const renderAltItem = (item) => {
    let { title, text, image } = item;
    let alt_tag = getImageAlt(image);
    return (
      <Grid key={title} item container xs={12} sm={6} className={classes.featuresBox}>
        <Grid item xs={4}>
          {image && !isMobile &&
            <img
              src={image}
              alt={alt_tag || 'imageAlt'}
              loading="lazy"
            />
          }
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography variant="h3" className={classnames(classes.subTitle, classes.howToSubheading, colorInverted ? classes.white : classes.grey, classes.textLeft)}>
            {title}
          </Typography>
          {text && <Typography variant="body1" marked="center" className={classnames(classes.body1, colorInverted ? classes.white : classes.grey, classes.textLeft)} >
            <Markdown
              children={text}
            />
          </Typography>}
        </Grid>
      </Grid>
    )
  }

  return <>
   <div className={classnames(classes.root, colorInverted ? classes.bgBlue : classes.bgWhite, itemsCentered ? classes.textCenter : classes.textLeft, curvedTop && classes.sectionWrapper)} style={rootStyle}>
     <Grid container className={classes.container}>
       <Grid item xs={12} className={alignLeft ? null : classes.stepsTitle}>
         <Typography variant="h2" className={classnames(smallTitle ? classes.smallTitle : alignLeft ? classes.leftAlignTitle : classes.bigTitle, colorInverted ? classes.white : classes.grey)}>
           {title}
         </Typography>
         {!hiddenUnderline && <div className={classes.underline}><CustomUnderlineBigHeading color={colorInverted ? '#ffffff' : '#1160F7'} /></div>}
       </Grid>
       {text && <Grid item xs={12}>
         <Typography variant="body1" className={classnames(alignLeft ? classes.leftContent : classes.content, colorInverted ? classes.white : classes.grey)}>
           {text}
         </Typography>
       </Grid>}
       {showMobileItems ? (
         showSlider ? (
           <TabsSection
             slider
             variant={showPrimaryArrows ? "primary" : "secondary"}
             data={
               items &&
               items.length &&
               items.map((item, index) => alt ? renderAltItem(item, index) : renderSectionItem(item, index))
             }
           />
         ) : (
           <Grid
             container
             spacing={3}
             className={classes.stepsContainer}
             justifyContent={itemsCentered ? 'center' : 'auto'}
           >
             {items.map(alt ? renderAltItem : renderSectionItem)}
           </Grid>
         )
       ) : (
         <Grid
           container
           spacing={3}
           className={classes.stepsContainer}
           justifyContent={itemsCentered ? 'center' : 'auto'}
         >
           {items && items.map(alt ? renderAltItem : renderSectionItem)}
         </Grid>
       )}
     </Grid>
     {actionText &&
       <Grid item xs={12} md={4} className={classes.spacing}>
         <CustomButton type={actionType} onClick={goToUrl} className={classes.btnStyle}>{actionText}</CustomButton>
       </Grid>}
   </div>
   { (table || divider )&& <Grid container className={classes.container}>
         <Divider
           variant="middle"
           orientation="horizontal"
           className={classes.divider}
           sx={{
             opacity: "0.6"
           }} />
     </Grid> }
  </>;
}

Steps.propTypes = {
};

export default Steps;
